import AxiosService from "./AxiosService";
import {ApiEndPointService} from "./ApiEndPointService";
import {SalaryFeedback} from "../types/SalaryFeedback";
import {SocialShareLink} from "../utils/CommonConstants";


const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const appBaseUrl = process.env.REACT_APP_BASE_URL;

export async function getChartDataByComparisonId(id:any){
    return AxiosService.get(apiBaseUrl+ApiEndPointService.GET_YEARS_EXPERIENCE_CHART+'/'+id,[],'secure');
}

export  function  shareToSocialMedia(site:string) {
    //let url = appBaseUrl ? appBaseUrl : window.location.protocol+"//"+window.location.host+".";
    let url = SocialShareLink;
    let title = 'Remote Devs'
    let linkedIntext = "Find out your true worth with RemoteDevs.Com. Get salary insights today! #RemoteDevs.Com #KnowYourWorth";
    let twitterText = "Find out your true worth with "+url+". Get salary insights today! #RemoteDevs.Com #KnowYourWorth";
    let mailSubject = "See what salary you should be with RemoteDevs.Com"
    let mailBody = "Hi,\n" +
        "\n" +
        "I just tried "+url+" to see how my salary compares to the market and it is an amazing tool! \n" +
        "Check it out, I think you will like it!"

    switch(site){
        case 'facebook':
            window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url)+'&quote=your+text+goes+here');
            break;

        case 'linkedin':
            window.open('http://www.linkedin.com/shareArticle?mini=true&url='+encodeURI(url)+'&title='+encodeURIComponent(title)+'&summary='+encodeURIComponent(linkedIntext)+'&source='+url)
            break;

        case 'twitter':
            window.open('https://twitter.com/intent/tweet?text='+encodeURIComponent(twitterText));
            break;

        case 'email':
            window.open('mailto:?subject='+mailSubject+'&body='+encodeURIComponent( mailBody))
            break;


        default:
            break;

    }

    return false
}

export function prepareChart(data: any[]) {
    let processedSalaryPoint:any = [];
    let processedExpericenPoint:any = [];
    if(data != null){
        data.forEach((element, index) => {
            processedSalaryPoint[index] = Math.round(element.salary);
            processedExpericenPoint[index] = Math.round(element.experience);
        });
    }
    return {processedSalaryPoint, processedExpericenPoint};
}

export function getUserPositionChart(processedExperiencePoint:any,processedSalaryPoint: any, userSalary:number): any {

    let result:any[] = [];
    if(processedSalaryPoint>0){
        try{
            let index:any = Object.keys(processedExperiencePoint).find(key => processedExperiencePoint[key] == processedSalaryPoint);
            result[index] = userSalary;
        }catch (e){
            result[processedSalaryPoint-1] = userSalary;
        }
    }else{
        result[processedSalaryPoint] = userSalary;
    }
    return result;
}

export async function storeSalaryFeedback(salaryFeedback:SalaryFeedback){
    return AxiosService.post(apiBaseUrl+ApiEndPointService.SALARY_FEEBACK_STORE ,salaryFeedback, "");
}

export async function updateSalaryFeedback(salaryFeedback:SalaryFeedback){
    return AxiosService.post(apiBaseUrl+ApiEndPointService.SALARY_FEEBACK_UPDATE, salaryFeedback, "");
}