import React from "react";

const SalaryReportGenerationLoader = (props:any) =>{
    return(
        <div className={'d-block'}>
            <div
                className="position-absolute start-0 end-0 top-0 bottom-0 bg-white d-flex justify-content-center align-items-center rounded-4"
                style={{zIndex: 99}}
            >
                <div className="loader d-inline-block"></div>
            </div>
            <div
                className="position-absolute start-0 end-0 text-center"
                style={{bottom: 32, zIndex: 999}}
            >
                <p className="text-dark small">
                    {props.calculation}/3 Generating salary comparison chart
                    ...
                </p>
                <button
                    type="button"
                    className="btn btn-primary"
                    disabled
                    data-bs-toggle="button"
                    style={{background: '#e9d7fe', border: '#e9d7fe'}}
                >
                    Generating report ..
                </button>
            </div>
        </div>
    );
}

export default SalaryReportGenerationLoader;