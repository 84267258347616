import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

const ProgressUploadBar = (props: any) => {
  const now = props.completed;

  return (
      <div className="pt-2">
        <div className="row align-items-center ">
        <div className="col-9">
          <ProgressBar now={now} />
        </div>
        <div className="col-3 ps-0 text-center">
          <span className="small">{now}% </span>
        </div>
      </div>
      </div>
  );
};

export default ProgressUploadBar;
