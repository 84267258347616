import { ExperienceList } from "../types/ExperienceList";
import { Skill } from "../types/Skill";
import { ApiEndPointService } from "./ApiEndPointService";
import AxiosService from "./AxiosService";
import {AppStorageService} from "./AppStorageService";


const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export async function getAllSkills(){
   return AxiosService.get(apiBaseUrl+ApiEndPointService.GET_ALL_SKILLS,[]);

}

export function getExperiences():ExperienceList[]{
   let experiences:ExperienceList[] = [];

   let exp1 = new ExperienceList();
   exp1.value = "1";
   exp1.label = "1";
   experiences.push(exp1);

   let exp2 = new ExperienceList();
   exp2.value = "2";
   exp2.label = "2";
   experiences.push(exp2);

   let exp3 = new ExperienceList();
   exp3.value = "3";
   exp3.label = "3";
   experiences.push(exp3);

   let exp4 = new ExperienceList();
   exp4.value = "4";
   exp4.label = "4";
   experiences.push(exp4);

   let exp5 = new ExperienceList();
   exp5.value = "5";
   exp5.label = "5";
   experiences.push(exp5);

   let exp6 = new ExperienceList();
   exp6.value = "6";
   exp6.label = "6";
   experiences.push(exp6);

   let exp7 = new ExperienceList();
   exp7.value = "7";
   exp7.label = "7";
   experiences.push(exp7);

   let exp8 = new ExperienceList();
   exp8.value = "8";
   exp8.label = "8";
   experiences.push(exp8);

   let exp9 = new ExperienceList();
   exp9.value = "9";
   exp9.label = "9";
   experiences.push(exp9);

   let exp10 = new ExperienceList();
   exp10.value = "10";
   exp10.label = "10";
   experiences.push(exp10);

   return experiences;
 }

export function getExperiencesBySkillId(id:any):ExperienceList[]{
   let storage = new AppStorageService();
   let skillAndMaxExperienceMapping = storage.getSkillToMaxYearMapping();
   if(skillAndMaxExperienceMapping.length<=0){
      return getExperiences();
   }
   let maxExperience = skillAndMaxExperienceMapping[id];
   let experiences:ExperienceList[] = [];
   let i =0;
   for(i;i<maxExperience;i++){
      let tempExp = new ExperienceList();
      tempExp.value = ""+(i+1);
      tempExp.label = ""+(i+1);
      experiences.push(tempExp)
   }
   let tempExp = new ExperienceList();
   tempExp.value = ""+(parseInt(maxExperience)+1);
   tempExp.label = maxExperience + "+";
   experiences.push(tempExp);
   return experiences;
}

export function checkForValidExperience(selectedExperience:ExperienceList,experiences:any):boolean{
   let res = false;
   let len = experiences.length;
   let counter = 1;
   experiences.map((item:any)=>{
      if(item.value==selectedExperience.value){
         if(len!=counter && selectedExperience.label.includes("+")){
            res = false;
         }else{
            res = true;
         }
         return;
      }
      counter++;
   })
   return res;
}

 export function getSpecificExperience(skillId:any,value:any):any{
   let data = getExperiencesBySkillId(skillId);
   let res = {};
  data.map((item:any)=>{
      if(item.value==value){
         res = item;
         return;
      }
   })
   return res;

 }

export async function getExtraSkill(search:string){
   return AxiosService.get(apiBaseUrl+ApiEndPointService.SEARCH_EXTRA_SKILL,{search:search});
}
export async function getIndustries(search:string){
   return AxiosService.get(apiBaseUrl+ApiEndPointService.SEARCH_INDUSTRIES, {search:search});
}

export async function getExclusionOfInterest(search:string){
   return AxiosService.get(apiBaseUrl+ApiEndPointService.GET_EXCLUSION_OF_INTREST,  {search:search});
}

export async function getJobTypes(search:string){
   return AxiosService.get(apiBaseUrl+ApiEndPointService.SEARCH_JOB_TYPES,{search:search});
}


export function disableSelectedSkills(skillsOptions:Skill[],selectedSkills:Skill[]){

   //let selectedSkill:any = localStorage.getItem(SELECTED_SKILLS);
   let selectedSkillArray:any = [];
   if(selectedSkills && selectedSkills.length>0){
      selectedSkills.forEach((element:any, i:any) => {
         if(element!=null){
            selectedSkillArray.push(element.value);
         }
      });
   }
   for (let index = 0; index < skillsOptions.length; index++) {
      let tmp = skillsOptions[index];
      if(selectedSkillArray.includes(skillsOptions[index].value)){
         tmp.isDisabled = true;
         skillsOptions[index] = tmp;
      }else{
         tmp.isDisabled = false;
         skillsOptions[index] = tmp;
      }
   }
   return [...skillsOptions];
}
