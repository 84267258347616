import React, { useEffect, useState } from "react";
import announcement from "../../../assets/images/icons/announcement.svg";
import WhiteButton from "../../../components/buttons/WhiteButton";
import {
  setCookie,
  getCookie,
} from "../../../services/RecruiterAuthenticationService";
import {RecruiterProfileAnnouncementCookieKey} from "../../../utils/CommonConstants";
import {RecruiterNewProfileAnnouncementCookieKey} from "../../../utils/CommonConstants";
import AnnouncementForPaymentInfo from "./AnnouncementForPaymentInfo";
import AnnouncementForNewProfile from "./AnnouncementForNewProfile";
import {fetchClassForProfilePart} from "../../../services/RecruiterProfileService";
import {useRecruiter} from "../../../context/useRecruiter";


const RecruiterProfileAnnouncements = (props: any) => {
  const [showCommonAnnouncement, setShowCommonAnnouncement] = useState(false);
  const showNewRecruiterAnnouncement = useRecruiter((state:any) => state.showNewRecruiterAnnouncement);
  const setShowNewRecruiterAnnouncement = useRecruiter((state:any) => state.setShowNewRecruiterAnnouncement);
  let status = props.status;


  useEffect(() => {


    if (getCookie(RecruiterProfileAnnouncementCookieKey)) {
      setShowCommonAnnouncement(false);
    } else {
      setShowCommonAnnouncement(true);
    }

    if (getCookie(RecruiterNewProfileAnnouncementCookieKey)) {
      setShowNewRecruiterAnnouncement(false);
    } else {
      if(status!='new') {
        handleClickForNewAnnouncement();
      }else{
        setShowNewRecruiterAnnouncement(true);
      }

    }



    props.setClassForAnnouncement(fetchClassForProfilePart());
  }, []);

  useEffect(()=>{
    props.setClassForAnnouncement(fetchClassForProfilePart());
  },[showCommonAnnouncement,showNewRecruiterAnnouncement])


  const handleClick = () => {
    setCookie(RecruiterProfileAnnouncementCookieKey, "set", 365);
    setShowCommonAnnouncement(false);
  };

  const handleClickForNewAnnouncement = () => {
    setCookie(RecruiterNewProfileAnnouncementCookieKey, "set", 1365);
    setShowNewRecruiterAnnouncement(false);
  };
  return (
    <>
        <div className="col-12 col-md-10 offset-0 offset-md-2 ">
        <div className="px-md-5 position-relative">
         <div className="toaster">
           <AnnouncementForPaymentInfo show={showCommonAnnouncement} onClick={handleClick}/>
           <AnnouncementForNewProfile show={showNewRecruiterAnnouncement} onClick={handleClickForNewAnnouncement}/>
         </div>
        </div>
      </div>
      
    </>
  );
};

export default RecruiterProfileAnnouncements;
