import announcement from "../../../assets/images/icons/announcement.svg";
import WhiteButton from "../../buttons/WhiteButton";
import React from "react";

const AnnouncementForNewProfile = (props:any) => {
  let show = props.show;
  let handleClick = props.onClick;
  return (
      <>
        {
          show ? (
              <div className="bg-white p-3 rounded-3 border mb-6">
                <div className="row">
                  <div className="col-12 col-lg-10 ">
                    <div className="d-block d-md-flex">
                      <img src={announcement} alt="" className="img-fluid pe-4" />
                      <p className="mb-0 py-3 py-lg-0">
                          To be able to reach out to candidates please fill in your profile details and submit your profile for verification. We aim to confirm profiles in no less than 48 hours
                      </p>
                    </div>
                  </div>

                  <div className="col-12 col-lg-2 px-lg-0 pe-lg-2">
                    <WhiteButton
                        onclick={handleClick}
                        text="I understand"
                        type="submit"
                        className="w-100"
                    />
                  </div>
                </div>
              </div>
          ):(
              ""
          )
        }
      </>
  );
}

export default AnnouncementForNewProfile;