 import OverlayTrigger from "react-bootstrap/OverlayTrigger";
 import Tooltip from "react-bootstrap/Tooltip";
const CustomTooltip = (props:any) => {
  let btnText = props.btnText;
  let tooltipText = props.tooltipText;
  let className = props.className;
  let placement = props.placement
  return (
    <OverlayTrigger
            delay={{ show: 250, hide: 400 }}
            placement={placement}
            overlay={
              <Tooltip className="text-start">
                {tooltipText}
              </Tooltip>
            }
          >
            
              <span className={className}>
                 {btnText} 
              </span>
          </OverlayTrigger>
  )
}

export default CustomTooltip

