import { ApiEndPointService } from "../../../services/ApiEndPointService";
import axios from "axios";
import {AppStorageService} from "../../../services/AppStorageService";
import {useState} from "react";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const useProfile = () => {
    const [uploadCompletePercent,setUploadCompletePercent] = useState(0);
    const [uploading,setUploading] = useState(false);

    const getHeaders = () => {
        let storage = new AppStorageService();
        return   {
                "content-type": 'multipart/form-data',
                'Access-Control-Allow-Origin':'*',
                'Cache-Control':'no-store,no-cache,must-revalidate',
                'Authorization':'Bearer '+storage.getToken()
        };
    }

    async function uploadDocument  (file:any) {

        try {
            return await axios.post(apiBaseUrl+ApiEndPointService.UPLOAD_USER_CV, file, {
                    headers: getHeaders(),
                    onUploadProgress: (progressEvent:any) => {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent?.total
                        );
                        setUploadCompletePercent(percentCompleted);
                    },
                }).then((response:any)=>{
                    return (response.status, response.data)
                }).catch((error:any)=>{
                    setUploading(false);
                    // @ts-ignore
                    return ('failed', {
                    })
                });
        } catch (e) {
            setUploading(false);
            // @ts-ignore
            return ('failed', {
            })
        }
    };

    return [uploadDocument,uploadCompletePercent,setUploadCompletePercent,uploading,setUploading] as const;
}

export default useProfile;