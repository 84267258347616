import { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import {reportCandidate} from "../../../../services/RecruiterService";
import {CommonErrorMsg, RequiredFieldCommonErrorMsg} from "../../../../utils/CommonConstants";
import InputFieldErrorMessage from "../../../../components/errors/InputFieldErrorMessage";
import {useParams} from "react-router-dom";
import {prepareAllErrorMsg} from "../../../../services/RecruiterAuthenticationService";
const CandidateReport = (props:any) => {
  let detailsFunctionalities = props.detailsFunctionalities;
  let isProfileLoading = detailsFunctionalities.isLoading;
  const [modalShowForReport, setModalShowForReport] = useState(false);
  const [reportReason, setReportReason] = useState("");
  const [reportReasonError, setReportReasonError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const textAreaRef: any = useRef(null);
  const { user } = useParams();

  const resizeTextArea = () => {
   
    if(textAreaRef && textAreaRef.current){
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
    }
  };

  useEffect(resizeTextArea, [reportReason]);

  const onChange = (e: any) => {
    setReportReasonError('');
    setReportReason(e.target.value);
  };
  const handelClick = (e:any) => {
    setModalShowForReport(true);
  }



  const handleSubmit = async () => {
    setReportReasonError("");
    if(!reportReason || reportReason==''){
      setReportReasonError(RequiredFieldCommonErrorMsg);
      return false;
    }
    setIsLoading(true);
    let requestObject = {
      user:user,
      reporting_reason: reportReason
    }

    let response = await reportCandidate(requestObject);

    if(response && response.success) {
      setIsLoading(false);
      setModalShowForReport(false);
      detailsFunctionalities.handleShowReportModal();
      detailsFunctionalities.setShowReportButton();
    }else{
      setIsLoading(false);
      setReportReasonError(prepareAllErrorMsg??CommonErrorMsg);
    }

  }

  return (
    <>
      {
        !isProfileLoading ?
            (
                detailsFunctionalities.showReportButton ?
          <button className="btn whiteBtn w-100" onClick={(e:any) => handelClick(e)}>Report this profile </button>
      :<button className="btn whiteBtn w-100" disabled={true}>You reported this profile </button>
            )
            :''
      }

      <Modal
        show={modalShowForReport}
        onHide={() => setModalShowForReport(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="px-6 pt-6 pb-0">
          <Modal.Title id="contained-modal-title-vcenter">
            <p
              className="mb-0"
              style={{ fontWeight: "600", fontSize: "20px", color: "#101828" }}
            >
              Found this profile inappropriate?
            </p>
            <p
              className="mb-0"
              style={{ fontWeight: "400", fontSize: "14px", color: "#475467" }}
            >
              Please report if you think it’s not real, insulting, spam, etc
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-5 px-6 pb-6">
          <form>
            <div className="row">
              <div className="col-12">
                <textarea
                  ref={textAreaRef}
                  value={reportReason}
                  onChange={onChange}
                  rows={12}
                  style={{ resize: "none" }}
                  className="w-100 p-3 candidate-profile-report form-control"
                  placeholder="Enter your text here"
                />
                <InputFieldErrorMessage error={reportReasonError}/>
              </div>
              <div className="col-12">
                <div className="d-flex justify-content-end gap-3 pt-6">
                  <button

                      type="button"
                    onClick={() => setModalShowForReport(false)}
                    className="btn border px-4 py-2"
                  >
                    Cancel
                  </button>
                  <button
                      type="button"
                    onClick={() => handleSubmit()}
                    className="btn btn-primary text-white px-4 py-2"
                      disabled={isLoading}
                  >
                    {isLoading ? "Processing...":"Submit the report"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CandidateReport;
