function MultipleSkillError(props: any) {
    const errorMsg = props.error;


    return (
        <span style={{
            padding: 10,
            float: 'left',
            width: '100%',
            textAlign: 'center',
            fontSize: 11,
            color: 'red',
        }}>{errorMsg}</span>

    );
}

export default MultipleSkillError;