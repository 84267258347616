import messageBtn from "../../../../assets/images/icons/message-white.svg";
import messagePurpleBtn from "../../../../assets/images/icons/message-purple.svg";
import React, {useEffect, useState} from "react";
import UnverifiedNoticeModal from "./UnverifiedNoticeModal";
import ContactCandidateModal from "./ContactCandidateModal";


const ContactCandidate = (props:any) => {
    let detailsFunctionalities = props.detailsFunctionalities;
    let extraDetails = props.extraDetails ?? {};
    let isContacted = props.isContacted;

    const [showUnverifiedNoticeModal, setShowUnverifiedNoticeModal] = useState(false);
    const [showContactModal, setShowContactModal] = useState(false);
    const [isVerified, setIsVerified] = useState(false);


    useEffect(()=>{
        if(detailsFunctionalities?.verificationStatus == 'verified') {
            setIsVerified(true);
        }else{
            setIsVerified(false);
        }

    },[detailsFunctionalities.verificationStatus])

    const handleClose = () => {
        setShowContactModal(false);
        setShowUnverifiedNoticeModal(false);
    }
    const handleShow = () => {
        if(!isVerified) {
            setShowContactModal(false);
            setShowUnverifiedNoticeModal(true);
        }else{
            setShowContactModal(true);
            setShowUnverifiedNoticeModal(false);
        }
    };
    return (
        <>
            <div className="pb-3">
            {
                isContacted ?
                (
                        <button className="btn whiteBtn w-100" onClick={handleShow}>
                            <img src={messagePurpleBtn} alt="" className="img-fluid pe-2"/>
                            Contact candidate
                        </button>

                ) :
                (
                        <button className="btn btn-primary w-100 text-white" onClick={handleShow}>
                            <img src={messageBtn} alt="" className="img-fluid pe-2"/>
                            Contact candidate
                        </button>
                )
            }

            </div>
            <UnverifiedNoticeModal showModal={showUnverifiedNoticeModal} handleClose={handleClose} />
            <ContactCandidateModal user={extraDetails.userId ?? ''} showModal={showContactModal} handleClose={handleClose} />
        </>
    );
}

export default ContactCandidate;