import React, {useState} from 'react'
import PaymentForm from '../payment/PaymentForm';
import PaymentDetails from '../payment/PaymentDetails';
import {markAsHired, paymentReceived} from "../../../services/RecruiterService";
import {prepareAllErrorMsg} from "../../../services/RecruiterAuthenticationService";
import {useMessenger} from "../../../context/useMessenger";
import {useCandidateDetails} from "../../../context/useCandidateDetails";

const PaymentContent = (props: any) => {
    let tabObject = props.tabObject;
    let handleClose = props.handleClose;
    let activeClass = "tab-pane fade show active";
    let inActiveClass = "tab-pane fade";
    const setPaymentStatus = useCandidateDetails((state:any)=>state.setPaymentStatus);


    const [isLoading,setIsLoading] = useState<boolean>();

    let extraDetails = props.extraDetails;

    const sendPaymentUpdateRequest = async () => {
        setIsLoading(true);
        let requestObject = {
            user: extraDetails.userId
        };
        let response = await paymentReceived(requestObject);

        if(response && response.success) {
            setIsLoading(false);
            setPaymentStatus(response.data.status);
        }else{
            setIsLoading(false);
            let errorMsg = prepareAllErrorMsg(response);
            extraDetails.setError(errorMsg);
        }
        handleClose();
    }

  return (
    <div className="tab-content pt-5" id="myTabContent">
          {/*<div
            className={tabObject.showPaymentTab ? activeClass:inActiveClass}
            id="home-tab-pane"
            role="tabpanel"
            aria-labelledby="home-tab"
            tabIndex={0}
          >
            <form>
              <PaymentForm />
              <div className="row">
                <div className="col-12 ">
                  <div className="d-flex flex-md-row flex-column gap-3 justify-content-between pt-7 pb-6">
                    <button
                      className="btn btn-primary flex-grow-1 order-md-2"
                      type="submit"
                    >
                      Pay <span>500</span> ${" "}
                      <span className="small">
                        (approx. <span>53,047</span> ৳)
                      </span>
                    </button>
                    <button
                      className="btn btn-secondary-gray order-md-1"
                      onClick={handleClose}
                      type="button"
                    >
                      Skip
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>*/}
          <div
            //className={!tabObject.showPaymentTab ? activeClass:inActiveClass}
            className={activeClass}
            id="profile-tab-pane"
            role="tabpanel"
            aria-labelledby="profile-tab"
            tabIndex={0}
          >
            <PaymentDetails />
            <div className="d-flex flex-md-row flex-column gap-3 justify-content-between pt-7 pb-6">
              <button
                className="btn btn-primary flex-grow-1 order-md-2"
                type="submit"
                disabled={isLoading}
                onClick={sendPaymentUpdateRequest}
              >
                  {isLoading ? 'Processing...':
                <>Pay <span>500</span> ${" "}
                <span className="small">
                  (approx. <span>53,047</span> ৳)
                </span>
                </>
                  }
              </button>
              <button
                className="btn btn-secondary-gray order-md-1"
                onClick={handleClose}
              >
                Skip
              </button>
            </div>
          </div>
        </div>
  )
}

export default PaymentContent
