import { components } from "react-select";


const SingleValue = (props:any) => {
    const { title, subTitle } = props.getValue()[0];

    return (
        <components.SingleValue {...props}>
            <span>{title}</span>
        </components.SingleValue>
    );
};

export default SingleValue;