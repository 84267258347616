import message from "../../../../assets/images/icons/message-text-square-02.svg";
import job from "../../../../assets/images/icons/job.svg";
import sadFace from "../../../../assets/images/icons/sad-face.svg";
import industry from "../../../../assets/images/icons/industry.svg"
import React from "react";

const CandidateExtraDetails = (props:any) => {
    let extraDetails = props.extraDetails;
    
   return (
       <>
           <div className="col-12">
               <div className="d-flex">
                   <img src={message} alt="" className="img-fluid align-self-start" />
                   <div className="align-self-center ps-2">
                       <p className="mb-0 text-semidark">English</p>
                       <p className="mb-0 small pt-1">
                           {extraDetails?.englishLevel?.name ?? '' } ( {extraDetails?.englishLevel?.display ?? '' } )
                       </p>
                   </div>
               </div>
           </div>

           <div className="col-12">
               <div className="d-flex">
                   <img src={industry} alt="" className="img-fluid align-self-start" />
                   <div className="align-self-center ps-2">
                       <p className="mb-0 text-semidark">Industry</p>
                       <div className="d-flex flex-wrap gap-1 pt-1">
                           {extraDetails?.preferredIndustries && extraDetails?.preferredIndustries.map((item:any,index:any)=>{
                               return (<p key={index}  className="small mb-0 candidate-skill py-1 px-3 rounded-4">{item.name}</p>)
                           }) }
                       </div>
                   </div>
               </div>
           </div>
           <div className="col-12">
               <div className="d-flex">
                   <img src={job} alt="" className="img-fluid align-self-start" />
                   <div className="align-self-center ps-2">
                       <p className="mb-0 text-semidark">Interested in jobs</p>
                       <div className="d-flex flex-wrap gap-1 pt-1">
                           {extraDetails?.jobTypes && extraDetails?.jobTypes.map((item:any,index:any)=>{
                               return (<p key={index}  className="small mb-0 candidate-skill py-1 px-3 rounded-4">{item.name}</p>)
                           }) }
                       </div>
                   </div>
               </div>
           </div>
           <div className="col-12">
               <div className="d-flex">
                   <img src={sadFace} alt="" className="img-fluid align-self-start" />
                   <div className="align-self-center ps-2">
                       <p className="mb-0 text-semidark">Not Interested in</p>
                       <div className="d-flex flex-wrap gap-1 pt-1">
                           {extraDetails?.exclusionInterest && extraDetails?.exclusionInterest.map((item:any,index:any)=>{
                               return (<p key={index} className="small mb-0 candidate-skill py-1 px-3 rounded-4">{item.name}</p>)
                           }) }
                       </div>
                   </div>
               </div>
           </div>
       </>
   );
}

export default CandidateExtraDetails;