import country from "../../../../assets/images/icons/globe-2.svg";
import flag from "../../../../assets/images/icons/flag.svg";
import user from "../../../../assets/images/icons/users-2.svg";
import companyType from "../../../../assets/images/icons/company-type.svg";
import WhiteButton from "../../../../components/buttons/WhiteButton";
import { Link } from "react-router-dom";
import CompanyDetails from "./CompanyDetails";
import {unSaveCandidate} from "../../../../services/RecruiterService";
import {reportBeingHired} from "../../../../services/CandidateService";
import {useState} from "react";
import {prepareAllErrorMsg} from "../../../../services/RecruiterAuthenticationService";

const ReportHired = (props:any) => {
    const [isLoading,setIsLoading] = useState(false);
  let extraDetails = props.extraDetails;

  const handleReportBeingHired = async () => {
      setIsLoading(true);
      let requestObject = {
          user: extraDetails.user_id
      };
      let response = await reportBeingHired(requestObject);

      if(response && response.success) {
          setIsLoading(false);
          extraDetails.setIsHired(true);

      }else{
          setIsLoading(false);
          let errorMsg = prepareAllErrorMsg(response);
          extraDetails.setError(errorMsg);
      }
  }
  return (
    <>
        <button disabled={isLoading} className="btn btn-primary w-100 text-white" onClick={handleReportBeingHired}>
            {isLoading ? 'Processing':'I’ve got hired'}
        </button>
    </>
  )
}

export default ReportHired;
