type props = {
    error: string|null
}
const InputFieldErrorMessage = (props:props) =>{
    let error = props.error;
    return (
        <>
            { error && error.length>0 ?
                <> <p className="small pt-1 error-text mb-0" style={{marginBottom:0}}>
                    {error}
                </p>
                </>
                :
                ''}
        </>
    );
}

export default InputFieldErrorMessage;