import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import close from "../../../assets/images/icons/x-close-gray.svg";

const ProfileVerificationSubmitModal = (props: any) => {
  const [modalShowForVerification, setModalShowForVerification] =
    useState(false);
  let profileSubmissionModalObject = props.profileSubmissionModalObject;
  useEffect(() => {
    setModalShowForVerification(profileSubmissionModalObject.showModal);
  }, [profileSubmissionModalObject.showModal]);

  const handleCrossBtn = () => {
    setModalShowForVerification(false);
    profileSubmissionModalObject.hideModal();
  };

  return (
    <Modal
      show={modalShowForVerification}
      onHide={() => handleCrossBtn()}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter" className="px-1">
          <div className="d-flex gap-4">
            <h5>
              Your profile will be submitted to RemoteDevs for verification
            </h5>
            <img
              src={close}
              alt=""
              className="img-fluid align-self-start cursor-pointer"
              onClick={profileSubmissionModalObject.hideModal}
            />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-6 pt-0">
        <p className="mb-0">
          To ensure authenticity of information across the platform, we ensure
          that nothing gets passed without verification.
        </p>
        <div className="pt-6">
          <div className="d-md-flex justify-content-end">
            <button
              className="btn btn-primary w-100 w-md-auto order-md-2"
              type="button"
              onClick={profileSubmissionModalObject.submitForm}
            >
              Submit For Verification
            </button>
            <button
              className="btn border w-100 w-md-auto order-md-1 mt-2 mt-md-0"
              type="submit"
              onClick={profileSubmissionModalObject.hideModal}
              style={{ marginRight: 10 }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ProfileVerificationSubmitModal;
