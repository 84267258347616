const ProfilePageResponseShow = (props: any) => {
  let error = props.error;
  let success = props.success;

  return (
    <div className="align-self-center pt-4 pt-lg-0 text-center text-md-start">
        {error && error.length > 0 ? (
          <span className="error-text ps-lg-3" style={{ marginBottom: 0 }}>
            {error}
          </span>
        ) : (
          ""
        )}
        {success && success.length > 0 ? (
          <span
            className="ps-lg-3"
            style={{ marginBottom: 0, color: "#027A48" }}
          >
            {success}
          </span>
        ) : (
          ""
        )}
    </div>
  );
};
export default ProfilePageResponseShow;
