import xCross from "../../../assets/images/icons/x-close-red.svg";
import { useEffect, useState } from "react";
import {
  getCookie,
  setCookie,
} from "../../../services/RecruiterAuthenticationService";

const ProfileReview = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (getCookie("review_announcement")) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, []);

  const handleCross = () => {
    setCookie("review_announcement", "set", 720);
    setShow(false);
  };
  return (
    <>
      {!show ? (
        ""
      ) : (
        <section className="profile-review profile-review-border">
          <div className="container">
            <div className="row">
              <div className="col-11 py-4">
                <p className="mb-0">
                  <span className="fw-semibold">
                    The profile will be published after RemoteDevs review.
                  </span>{" "}
                  <br className="d-block d-md-none" />
                  You’ll get notified once your profile is approved.
                </p>
              </div>
              <div className="col-1 py-4">
                <img
                  onClick={() => handleCross()}
                  className="cursor-pointer"
                  src={xCross}
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ProfileReview;
