import MyChatList from "./components/MyChatList";
import SingleChat from "./components/SingleChat";
import {useEffect} from "react";
import useInbox from "./useInbox";

const Inbox = () => {
    const {myChatList,setMyChatList,getMyChatList,currentChatListPageNo,lastChatListPageNo} = useInbox();
    useEffect(()=>{
        getMyChatList(1);
    },[])

    let extraInboxData = {
      handleNextPage:getMyChatList,
        currentChatListPageNo:currentChatListPageNo,
        lastChatListPageNo:lastChatListPageNo
    };
  return (
    <div>
      <div
        style={{ maxHeight: "90vh", overflow: "hidden"}}
      >
        <div className="container">
          <div className="row">
            <MyChatList extraInboxData={extraInboxData}/>
            <SingleChat/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inbox;
