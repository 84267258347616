import {createContext, ReactNode, useReducer} from "react";


type Props = {
    children?:ReactNode;
}

export const AuthContext = createContext("");

export const authReducer = (state:any,action:any)=>{
    switch (action.type){
        case "LOGIN":
            localStorage.setItem('token',JSON.stringify(action.payload.token ?? ''))
            return {user:action.payload}
        case "LOGOUT":
            localStorage.clear();
            sessionStorage.clear();
            return {user:null}
        case "SIGNUP_MSG":
            return {msg:true}
        case "SET_PROFILE_STATUS":
            return {user:{...action.payload,status:'unverified'}}
        default:
            return state;
    }
}

export const AuthContextProvider = ({children}:Props) =>{
    const [state,dispatch] = useReducer(authReducer,{
        user: JSON.parse(localStorage.getItem('user')??'""')
    });
    //console.log("auth state: ",state);

    return (
        <AuthContext.Provider value={{...state,dispatch}}>
            {children}
        </AuthContext.Provider>
    )
}