import io, { Socket } from 'socket.io-client';
import MyChatList from "./MyChatList";
import {useEffect, useRef, useState} from "react";
import {getChatMessages, getInbox, saveChatMessages} from "../../services/ChatService";
import {useMessenger} from "../../context/useMessenger";
import ScrollableChat from "./ScrollableChat";
import {useAuthContext} from "../../context/useAuthContext";
const Chat = () => {
    const [messages,setMessages] = useState<any>([]);
    const [loading,setLoading] = useState(false);
    const [newMessage,setNewMessage] = useState('');
    const [socketConnected,setSocketConnected] = useState(false);
    const selectedChatUser = useMessenger((state:any)=>state.selectedChatUser);
    const selectedChat = useMessenger((state:any)=>state.selectedChat);
    const chatOwner = useMessenger((state:any)=>state.chatOwner);



    let socket= useRef<any>(null);
    const ENDPOINT = 'localhost:3002';
    let selectedChatCompare = "";

    useEffect(()=>{
        console.log("ssss");
        socket.current = io(ENDPOINT);

        let userObject = {
            userId:sessionStorage.getItem('user')
        }
        socket.current.emit("setup",userObject);
        socket.current.on("connected",()=>{
            setSocketConnected(true)
            console.log("reverse connected")
        })

    },[])



    const join = () => {
        console.log("hree");
        //socket = io(ENDPOINT);
        //socket.emit('join', { name: 'Paola', room: '1' }, () => {});
    };


    const getMessages = async (socket:any) => {
        if(selectedChatUser){
            setLoading(true);
            let response = await getChatMessages(selectedChatUser,1);
            console.log(response);
            if(response && response.data){
                let msgData = response.data;
                msgData.reverse();
                setMessages(msgData);
            }
            socket.current.emit("join chat",selectedChat);
            setLoading(false);
        }


    }

    const saveMessages = async () => {
        setNewMessage('');
        let messageObject = {
            user: selectedChatUser,
            content: newMessage
        };
        let response = await saveChatMessages(messageObject);
        console.log(response);
        if(response && response.success && response.data) {
            socket.current.emit('new message',selectedChat,response.data,selectedChatUser,chatOwner);
            setMessages([...messages,response.data])
        }
    }



    const sendMessage = (event:any) => {
        if(event.key==='Enter' && newMessage) {
            console.log("enter pressed");
            saveMessages();
        }
    }


    useEffect(()=>{
        console.log(messages);
    },[messages])

    useEffect(()=>{
        console.log("user changed");
        setMessages([]);
        getMessages(socket)

        selectedChatCompare = selectedChatUser;
    },[selectedChatUser]);

    useEffect(()=>{
        console.log("tut")
        console.log(messages)
        socket.current.on('message received',(newMessage:any)=>{

            if(selectedChat==newMessage.chat_id){
                console.log("Correct SingleChat");
                let newAllMessages = [...messages];
                newAllMessages.push(newMessage);
                setMessages(newAllMessages);
            }

            if(!selectedChatUser || selectedChatUser != newMessage.sender){
                //send notification
                console.log("here");
            }else{
                console.log("don't here");


            }

            return false;
        })
    })

    const typingHandler = (e:any) => {
        setNewMessage(e.target.value);
    }

    const handleSubmit = (e:any) => {
        e.preventDefault();
        return false;
    }

    return (
        <>
            <div className="container" style={{textAlign:'center'}}>
                <div className="row" style={{backgroundColor:"red",padding:10}}>
                    <div className="col-lg-2">
                        <button style={{float:"right"}} className="join-button" onClick={join} data-testid={'join-button'}>
                            Sign in
                        </button>
                    </div>
                        <div className="col-lg-10">
                            <h3>The great chat App</h3>
                        </div>




                </div>
                <div className="row">
                    <div className="col-lg-3" style={{border:"2px solid grey",margin:1}}>
                        <h5>My ChatList</h5>
                        <div>
                            <MyChatList/>
                        </div>

                    </div>
                    <div className="col-lg-8" style={{border:"2px solid grey",margin:1}}>
                        <div className="row" style={{minHeight:'70vh',border:"2px solid grey",margin:1}}>
                            {
                                loading ? (<img
                                    src={require("../../assets/images/icons/loading.png")}
                                    className="spinner"
                                    width="40"
                                    alt=""
                                />): (
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        overflowY: 'scroll',
                                        scrollbarWidth: 'none'}}
                                    >
                                        <ScrollableChat messages={messages}/>
                                    </div>
                                )
                            }
                        </div>
                        <div className="row" style={{border:"2px solid grey",margin:1}}>
                            <form onSubmit={handleSubmit} onKeyDown={sendMessage} >
                                <textarea
                                    placeholder="Enter your message"
                                    onChange={typingHandler}
                                    value={newMessage}
                                ></textarea>
                            </form>

                        </div>
                    </div>
                </div>

            </div>

        </>
    );
}

export default Chat;