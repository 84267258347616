import React from 'react'

const SearchNavTabs = (props:any) => {
  let tabObject = props.tabObject;
  let activeClass = "w-50 w-md-auto nav-link active border-top border-bottom border-start";
  let inActiveClass = "w-50 w-md-auto nav-link border-top border-bottom border-start";
  let activeClassEnd = "w-50 w-md-auto nav-link active border-top border-bottom border-end";
  let inActiveClassEnd = "w-50 w-md-auto nav-link border-top border-bottom border-end";
  let activeClassIcon = "bi bi-bookmark-check text-white";
  let inActiveClassIcon = "bi bi-bookmark-check text-semidark";
  return (
    <nav>
    <div className="nav nav-tabs" id="nav-tab" role="tablist">
      <button
          className={tabObject.showAllCandidateTab ? activeClass:inActiveClass}
        style={{
          borderRadius: "8px 0 0 8px",
          borderColor: "#D0D5DD",
        }}
        id="nav-home-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-home"
        type="button"
        role="tab"
        aria-controls="nav-home"
        aria-selected="true"
          onClick={tabObject.showTab}
          disabled={tabObject.showAllCandidateTab}
      >
        All candidates
      </button>
      <button
          className={!tabObject.showAllCandidateTab ? activeClassEnd:inActiveClassEnd}
        style={{
          borderRadius: "0 8px 8px 0 ",
          borderColor: "#D0D5DD",
        }}
        id="nav-profile-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-profile"
        type="button"
        role="tab"
        aria-controls="nav-profile"
        aria-selected="false"
          onClick={tabObject.hideTab}
          disabled={!tabObject.showAllCandidateTab}

      >
        {/* Note: when the saved candidate tab will be active the icon clour change into text-white  */}
        {/* <i className="bi bi-bookmark-check text-semidark " /> */}
        <i className={!tabObject.showAllCandidateTab ? activeClassIcon:inActiveClassIcon}/>
        <span className="ps-2">
          Saved{" "}
          <span className="d-none d-md-inline-block">
            candidates
          </span>{" "}
          only
        </span>
      </button>
    </div>
  </nav>
  )
}

export default SearchNavTabs
