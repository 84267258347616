import candidateImage from "../../../../assets/images/candidate-image.png";
import git from "../../../../assets/images/icons/github-gray.svg";
import linkedIn from "../../../../assets/images/icons/linked-in-gray.svg";
import whatsapp from "../../../../assets/images/icons/whatsapp-gray.svg";
import {Link} from "react-router-dom";
const CandidatePrivateInfo = (props:any) => {
  let candidatePrivateDetails = props.candidatePrivateDetails;
  let candidateDetails = props.candidateDetails;
  return (
      <>
      {candidatePrivateDetails && candidatePrivateDetails.name
      ? (    <div className="d-block d-md-flex gap-4  mb-md-7">
              <img
                  src={candidatePrivateDetails.avatar ?? ''}
                  alt=""
                  className="img-fluid rounded-3"
                  style={{ height: "172px", width: "172px" }}
              />
              <div>
                  <p className="mb-0 fw-semibold fs-4 common-text pt-4 pt-md-0">{candidatePrivateDetails.name ?? ''}</p>
                  <div className="d-flex flex-wrap gap-4 pt-4">
                      {candidatePrivateDetails?.github ?
                          (<a href={candidatePrivateDetails.github ?? ''} target="_blank" rel="noreferrer"> <img src={git} alt="" className="img-fluid"/></a>):''}
                      {candidatePrivateDetails?.linkedin ?
                          (<a href={candidatePrivateDetails.linkedin ?? ''} target="_blank" rel="noreferrer"> <img src={linkedIn} alt="" className="img-fluid"/></a>):""}
                      {candidatePrivateDetails?.whatsapp ?
                          (<a href={candidatePrivateDetails.whatsapp ? 'https://wa.me/'+candidatePrivateDetails.whatsapp:''} target="_blank" rel="noreferrer"> <img src={whatsapp} alt="" className="img-fluid"/></a>):''}
                  </div>
              </div>
          </div>)
          :''
      }
      </>
  );
};

export default CandidatePrivateInfo;
