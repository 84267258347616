import {useEffect, useState} from "react";
import save from "../../../../assets/images/icons/save-button.svg";
import saveBtnFill from "../../../../assets/images/icons/save-button-fill.svg";
import {reportCandidate, saveCandidate, unSaveCandidate} from "../../../../services/RecruiterService";
import {prepareAllErrorMsg} from "../../../../services/RecruiterAuthenticationService";
import {CommonErrorMsg} from "../../../../utils/CommonConstants";
const CandidateSave = (props:any) => {
  let saveObject = props.saveObject;
  let savedCandidateList = saveObject?.savedCandidateList ?? [];
  let handleSavedCandidateList = saveObject.handleSavedCandidateList;
  const [isSaved, setIsSaved] = useState(saveObject.isSaved);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(()=>{
    setIsSaved(saveObject.isSaved);
  },[saveObject.isSaved])

  const handleUnSave = async (e:any) => {
    e.stopPropagation();
    e.preventDefault();

    let requestObject = {
      user:saveObject.user,
    }
    let response = await unSaveCandidate(requestObject);

    if(response && response.success) {
      setIsSaved(false);
      let newSavedList = [...savedCandidateList];
      newSavedList.forEach((item:any,index:any)=>{
        if(item.user_id == saveObject.user) {
          newSavedList.splice(index,1);
          setIsSaved(true);
        }

        handleSavedCandidateList(newSavedList);
      })
      setIsLoading(false);

      if(saveObject.totalCandidate){
        saveObject.setTotalCandidate(saveObject.totalCandidate-1);
      }

    }else{
      setIsLoading(false);
      setIsSaved(true);
    }
  }


  const handleSave = async (e:any) => {
    e.stopPropagation();
    e.preventDefault();
    let requestObject = {
      user:saveObject.user,
    }
    let response = await saveCandidate(requestObject);
    if(response && response.success) {
      setIsLoading(false);
      setIsSaved(true);
    }else{
      setIsLoading(false);
      setIsSaved(false);
    }

  }

  return (
      <> {isLoading ?
          <img
              src={require("../../../../assets/images/icons/loading.png")}
              className="spinner img-fluid"
              width="30"
              height="30"
              style={{marginTop:'1vh'}}
              alt=""
          /> :
          (isSaved ?
                  <img src={saveBtnFill} alt="" className="img-fluid cursor-pointer" onMouseDown={e => e.stopPropagation()} onClick={handleUnSave}/>
                  : <img style={{zIndex:999}} src={save} alt="" className="img-fluid cursor-pointer" onMouseDown={e => e.stopPropagation()} onClick={handleSave}/>
          )
      }
      </>
  )
}

export default CandidateSave;
