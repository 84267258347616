import announcement from "../../../assets/images/icons/announcement.svg";
import WhiteButton from "../../buttons/WhiteButton";
import React from "react";

const AnnouncementForPaymentInfo = (props: any) => {
  let show = props.show;
  let handleClick = props.onClick;
  return (
    <>
      {show ? (
        <div className="bg-white p-3 rounded-3 border mb-4">
          <div className="row">
            <div className="col-12 col-lg-10 ">
              <div className="d-block d-md-flex">
                <img src={announcement} alt="" className="img-fluid pe-4" />
                <p className="mb-0 py-3 py-lg-0">
                  By contacting candidate you agree to pay{" "}
                  <span className="text-primary fw-semibold">
                    500 $ (approx. 53,047 ৳)
                  </span>{" "}
                  for each candidate hired. We need this to keep the site
                  running
                </p>
              </div>
            </div>

            <div className="col-12 col-lg-2 px-lg-0 pe-lg-2">
              <WhiteButton
                onclick={handleClick}
                text="I understand"
                type="submit"
                className="w-100"
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default AnnouncementForPaymentInfo;
