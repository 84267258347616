import React, {Context, useState} from "react";
import {useAuthContext} from "../../../context/useAuthContext";
import {signIn as recruiterSignIn, signUp as recruiterSignUp} from "../../../services/RecruiterAuthenticationService";
import validator from "validator";
import {
    CommonErrorMsg,
    InvalidConfirmPasswordErrorMsg,
    InvalidEmailDomainErrorMsg,
    InvalidEmailDomainList,
    InvalidEmailErrorMsg, InvalidPasswordErrorMsg,
    RequiredFieldCommonErrorMsg, TermsAndConditionsCheckErrorMsg
} from "../../../utils/CommonConstants";
import {exec} from "child_process";
import {RECRUITER_POST_SIGN_UP, RECRUITER_PROFILE} from "../../../utils/RouteNamingConfig";
import {useNavigate} from "react-router-dom";

type SignUpObject = {
    email:string;
    password:string
}

export const useSignUp = () =>{
    const [error,setError] = useState<string|null>(null);
    const [emailError,setEmailError] = useState<string|null>(null);
    const [passError,setPassError] = useState<string|null>(null);
    const [confirmPassError,setConfirmPassError] = useState<string|null>(null);
    const [isLoading,setIsLoading] = useState<boolean | null>(null);
    const {dispatch}:any= useAuthContext();
    const navigate = useNavigate();

    const validateEmail = (email:string) =>{
        if(!email || email==''){
            setEmailError(RequiredFieldCommonErrorMsg);
            return false;
        }
        if(!validator.isEmail(email)){
            setEmailError(InvalidEmailErrorMsg);
            return false;
        }
        let emailArr = email.toLocaleLowerCase().split('@');
        if(emailArr[1]){
            let res = true;
            let emailArrSecondStep = emailArr[1].split('.');
            emailArrSecondStep.forEach((item)=>{
                if(InvalidEmailDomainList.includes(item)){
                    setEmailError(InvalidEmailDomainErrorMsg);
                    res = false;
                }
            })
            return res;
        }else{
            setEmailError(InvalidEmailErrorMsg);
            return false;
        }


    }

    const validatePassword = (password:string) =>{
        if(!password || password==''){
            setPassError(RequiredFieldCommonErrorMsg);
            return false;
        }
        let re = /^(?=.*[a-zA-Z0-9])(?=.*[!@#$%^&*]).{6,32}$/;
        if(!re.test(password)){
            setPassError(InvalidPasswordErrorMsg);
            return false;
        }
        return true;
    }

    const validateConfirmPassword = (password:string,confirmPassword:string) =>{
        if(!confirmPassword || confirmPassword==''){
            setConfirmPassError(RequiredFieldCommonErrorMsg);
            return false;
        }

        if(password!==confirmPassword){
            setConfirmPassError(InvalidConfirmPasswordErrorMsg);
            return false;
        }
        return true;
    }

    const validateTermsChecked = (isChecked:boolean) =>{
        if(!isChecked){
            setError(TermsAndConditionsCheckErrorMsg);
        }else{
            setError(null);
        }
        return isChecked;
    }

    const signUp = async (email:string,password:string) =>{
        setIsLoading(true);
        setError(null);

        let signUpData:SignUpObject = {
            email: email,
            password: password
        };

        const response = await recruiterSignUp(signUpData);
        if(response && response.success){
            sessionStorage.setItem('signup_email',email);
            setIsLoading(false);
            navigate(RECRUITER_POST_SIGN_UP);
        }else{
            setIsLoading(false);
            let errorText = prepareErrorMsg(response);
            setError(errorText);
        }
    }

    const prepareErrorMsg =(response:any)=> {
        let errorText = "";
        if(response.data){

            let errorMsgFromApi = response.data;
            if(errorMsgFromApi.email){
                errorMsgFromApi.email.forEach((item:any)=>{
                    errorText+=item+"\n";
                })
            }
            if(errorMsgFromApi.password){
                errorMsgFromApi.password.forEach((item:any)=>{
                    errorText+=item+"\n";
                })
            }
        }else{
            errorText = response.message ?? CommonErrorMsg;
        }

        return errorText;
    }

    return {signUp,isLoading,error,setError,emailError,setEmailError,passError,
        setPassError,validateEmail,validatePassword,confirmPassError,setConfirmPassError,
        validateConfirmPassword,
        validateTermsChecked}
}
