import { ExclusionInterest } from "./ExclusionInterest";
import { Industry } from "./IndustryClass";
import { JobType } from "./JobType";
import { Skill } from "./Skill";


export class Candidate {
    id:number = 0;
    name!:string;
    email:string = "";
    imageLink:string = "";
    picture:string = "";
    profile_type:string="regular"


    // fullName!:string;
    jobPosition!:string;
    whatsappNumber!:string;
    currentSalary!:string;
    expectedSalary!:string;
    englishLevelId!:string;
    workExperience!:string;
    achievements!:string;
    expectations!:string;
    linkedinProfile!:string;
    githubProfile!:string;
    cvLink!:string;
    cv!:string;
    comment!:string;
    exclusionInterest:ExclusionInterest[] = [];
    preferredIndustries!:Industry[];
    extraSkills!:Skill[];
    primarySkill:Skill[] = [];
    jobType:JobType[] = [];
    workExperienceHighlights!: string;
    cv_name: string='';
    cv_size: string='';
}
