import {useState} from "react";
import {signIn as recruiterSignIn} from "../../../services/RecruiterAuthenticationService";
import {RECRUITER_PROFILE, RECRUITER_SEARCH_CANDIDATE} from "../../../utils/RouteNamingConfig";
import {CommonErrorMsg} from "../../../utils/CommonConstants";
import {getCandidateProfileDetails, getRecruiterProfileDetails} from "../../../services/RecruiterService";
import {useNavigate} from "react-router-dom";
import {useAuthContext} from "../../../context/useAuthContext";

const useRecruiterProfilePublicView = () => {
    const [recruiterDetails, setRecruiterDetails] = useState<any>(null);
    const [extraDetails, setExtraDetails] = useState<any>(null);
    const [showReportModal, setShowReportModal] = useState<any>(false);
    const [showReportButton, setShowReportButton] = useState<any>(false);
    const [verificationStatus, setVerificationStatus] = useState<any>('');
    const [isContacted, setIsContacted] = useState<any>(false);
    const [isHired, setIsHired] = useState<any>(false);
    const [isLoading, setIsLoading] = useState<any>(false);
    const [error, setError] = useState<any>('');
    let navigate = useNavigate();
    const {dispatch}:any= useAuthContext();


    const getDetails = async (userId:any) => {
        let object = {
            user: userId
        }
        setIsLoading(true);
        const response = await getRecruiterProfileDetails(object);
        if(response && response.success && response.data){
            let recruiterDetails = response.data.recruiter_details;
            setRecruiterDetails(response.data.recruiter_details);
            setShowReportButton(response.data.recruiter_details.show_report_button);
            let extraDetailsObject = {
                user_id:userId,
                company_country:  recruiterDetails.company_country,
                company_founding_year:  recruiterDetails.company_founding_year,
                company_employees_number:  recruiterDetails.company_employees_number,
                company_type:  recruiterDetails.company_type,
                setIsContacted:setIsContacted,
                setIsHired:setIsHired,
                setError:setError

            };
            setExtraDetails(extraDetailsObject);
            setVerificationStatus(response.data.user_details?.status);
            setIsContacted(response.data.isContacted);
            setIsHired(response.data.isHired);

        }else{
            console.log("No details data found")
        }
        setIsLoading(false);
    }

    return {recruiterDetails,getDetails,extraDetails,showReportModal, setShowReportModal,
        showReportButton, setShowReportButton,verificationStatus,isLoading,isContacted,isHired,error};
}
export default useRecruiterProfilePublicView;