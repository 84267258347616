import React from "react";

function NextButtonDynamic(props: any) {
    const text = props.text;
    const show = props.show;
    return (
        <>
            {show ?
            <button className="btn btn-primary w-100" type="submit" onClick={props.onClick}>
                {text}
            </button>
                :
                <button
                    className="btn btn-primary w-100"
                    type="submit"
                    style={{
                        background: '#e9d7fe',
                        border: '1px solid #e9d7fe',
                        cursor: 'not-allowed'
                    }}
                >
                    {text}
                </button>
            }
        </>

    );
}

export default NextButtonDynamic;