import {Outlet, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {AppStorageService} from "../services/AppStorageService";

const PrivateRoute = () => {
    let auth = true;
    let navigate = useNavigate();
    let storage = new AppStorageService;
    const [userLogInStatus, setUserLogInStatus] = useState(storage.userLoggedIn());
    useEffect(()=>{

        if(!userLogInStatus){
            navigate('/');
        }

    })


    return(
        <> { userLogInStatus ?
            <Outlet/>:''}</>
    )
}

export default PrivateRoute;