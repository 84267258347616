const CommonUploadError = (props: any) => {
  let show = props.show;
  const handleDelete = props.handleDelete;
  const text = props.text;
  return (
    <>
      {show ? (
        <div
          className="d-flex align-items-center"
          style={{
            height: "100%",
            width: "100%",
          }}
        >
         <div className="px-4 w-100">
         <div className="row">
            <div className="col-10 small error-text">
              <p className="mb-0">{text}</p>
            </div>
            <div className="col-2">
            <button onClick={handleDelete} className="btn cursor-pointer deleteCross mt-1 "/>
            </div>
          </div>
         </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default CommonUploadError;
