import { Link } from "react-router-dom";
import avatar from "../../../assets/images/avatar.png";
import backArrow from "../../../assets/images/icons/arrow-left.svg";
import icon from "../../../assets/images/icons/avatar-icon.svg";
import mobileLogo from "../../../assets/images/icons/logo-for-signup.svg";
import star from "../../../assets/images/icons/star-fill.svg";
import {HOME_PAGE, RECRUITER_SIGN_IN, RECRUITER_TERMS} from "../../../utils/RouteNamingConfig";
import AuthPageSideBar from "../../../components/recruiter/auth/AuthPageSideBar";
import {useState} from "react";
import {useSignUp} from "./useSignUp";
import InputFieldErrorMessage from "../../../components/errors/InputFieldErrorMessage";
import DisableEnableButton from "../../../components/buttons/DisableEnableButton";


const SignUp = () => {
  const [email,setEmail] = useState("");
  const [password,setPassword] = useState("");
  const [confirmPassword,setConfirmPassword] = useState("");
  const [termsChecked,setTermsChecked] = useState(false);
  const {
    signUp,isLoading,error,setError,emailError,setEmailError,passError,setPassError,
    validateEmail,
    validatePassword,
    confirmPassError,
    setConfirmPassError,
    validateConfirmPassword,
    validateTermsChecked
  } = useSignUp();

  const handleSubmit = async (e:any) =>{
    e.preventDefault();
    setError(null);
    setEmailError(null);
    setPassError(null);
    setConfirmPassError(null);

    let isValidEmail = validateEmail(email);
    let isValidPass = validatePassword(password);
    let isPassConfirmed = validateConfirmPassword(password,confirmPassword);
    let isTermsChecked = validateTermsChecked(termsChecked);

    if(isValidEmail && isValidPass && isPassConfirmed && isTermsChecked)
    {
      await signUp(email,password);
    }


  }

  return (
          <div className="col-lg-6 ">
            <div className="position-relative d-lg-flex align-items-center justify-content-center">
              <div
                  className="position-absolute start-0 recruiter-sign-up-back-button"
              >
                <Link to={RECRUITER_SIGN_IN}>
                  <img src={backArrow} className="img-fluid" alt="back arrow" />
                  <span className="small text-secondary ps-3"> Go back</span>
                </Link>
              </div>
              <div className="recruiter-spacious-section">
                <div className="row align-items-center justify-content-center ">
                <div className="col-md-7 col-12 px-0">
                  <div className="text-center">
                    <img
                      src={mobileLogo}
                      className="img-fluid d-md-none pb-6 pt-6"
                      alt=""
                    />
                    <h2 className="display-5 fw-semibold text-dark pb-2 pb-md-3">
                      Sign up as recruiter
                    </h2>
                    <p className="text-secondary mb-0">
                      Hire the talent of your choice
                    </p>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="row gy-4 gy-md-6 mx-0 pt-7">
                      <div className="col-12">
                        <label htmlFor="" className="form-label">
                          Email
                        </label>
                        <input
                          type="email"
                          className={(emailError && emailError?.length>0) ? "form-control  error-input":"form-control"}
                          placeholder="Enter your email"
                          onChange={(e)=>setEmail(e.target.value)}
                          value={email}
                        />
                        <InputFieldErrorMessage error={emailError}/>
                        <span className="small text-secondary mt-1">
                          Please use your corporate e-mail for sign-up
                        </span>
                      </div>

                      <div className="col-12">
                        <label htmlFor="" className="form-label">
                          Password
                        </label>
                        <input
                            type="password"
                            className={(passError && passError?.length>0) ?
                            "form-control  error-input":"form-control"}
                            placeholder="Enter your password"
                            onChange={(e)=>setPassword(e.target.value)}
                            value={password}
                        />
                        <InputFieldErrorMessage error={passError}/>
                      </div>

                      <div className="col-12">
                        <label htmlFor="" className="form-label">
                          Confirm password
                        </label>
                        <input
                            type="password"
                            className={(confirmPassError && confirmPassError?.length>0) ?
                                "form-control  error-input":"form-control"}
                            placeholder="Confirm password"
                            onChange={(e)=>setConfirmPassword(e.target.value)}
                            value={confirmPassword}
                        />
                        <InputFieldErrorMessage error={confirmPassError}/>
                      </div>

                      <div className="col-12">
                        <input className="form-check-input" type="checkbox"
                               onChange={(e) => setTermsChecked(e.target.checked)}
                        />
                        <label
                          className="form-check-label small ps-2"
                          htmlFor="flexCheckDefault"
                          style={{ color: "#667085" }}
                        >
                          I agree to the{" "}
                          <Link target="_blank" to={RECRUITER_TERMS}>
                            <span className="border-bottom fw-semibold">
                              terms and conditions
                            </span>
                          </Link>
                        </label>
                      </div>
                      {(error && error.length>0) ? <InputFieldErrorMessage error={error} /> : ""}
                      <div className="col-12 ">
                        <DisableEnableButton disable={isLoading} text="Sign Up" />
                      </div>
                    </div>
                  </form>
                  <div className="row mx-0">
                    <div className="col-12 py-7 text-center">
                      <p className="mb-0">
                        <span>Already have an account?</span>{" "}
                        <Link to={RECRUITER_SIGN_IN} className="small fw-semibold">
                          Sign in
                        </Link>
                      </p>
                    </div>
                    {/*<div className="col-12">
                      <Link to={HOME_PAGE}>
                        <button
                            className="btn btn-secondary-gray  w-100 "
                            type="submit"
                        >
                          Looking for a job? Click here
                        </button>
                      </Link>

                    </div>*/}
                  </div>
                </div>
                </div>
              </div>
            </div>
            {/* <div className="row align-items-center mx-0 pt-7 mt-md-3"></div> */}
          </div>
  );
};

export default SignUp;