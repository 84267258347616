import Modal from "react-bootstrap/Modal";
import React, {useState} from "react";
import {
    CommonErrorMsg, ContactCandidateSuccessMsg,
    FiftyCharacterErrorMsg,
    RecruiterContactEmailBodyLimit,
    RecruiterContactEmailSubjectLimit, RequiredFieldCommonErrorMsg, ThousandCharacterErrorMsg
} from "../../../../utils/CommonConstants";
import InputFieldErrorMessage from "../../../../components/errors/InputFieldErrorMessage";
import {sendRequestToContactCandidate} from "../../../../services/RecruiterService";
import {prepareErrorMsg} from "../../../../services/RecruiterAuthenticationService";
import {useCandidateDetails} from "../../../../context/useCandidateDetails";
import SweetAlert2 from "react-sweetalert2";


type propsType = {
    showModal: boolean,
    handleClose: (() => void),
    user: any
}

const ContactCandidateModal = (props:propsType) => {
    const [emailSubject,setEmailSubject] = useState('');
    const [emailSubjectError,setEmailSubjectError] = useState('');
    const [emailBody,setEmailBody] = useState('');
    const [emailBodyError,setEmailBodyError] = useState('');
    const [isLoading,setIsLoading] = useState(false);
    const [error,setError] = useState('');
    const [swalProps, setSwalProps] = useState<any>({});
    const setIsContacted = useCandidateDetails((state:any)=>state.setIsContacted);

    let showModal = props.showModal;
    let handleClose = props.handleClose;
    let user = props.user;

    const handleEmailSubject = (e:any) => {
        setEmailSubjectError('');
        let value = e.target.value ?? '';
        if(value.length>RecruiterContactEmailSubjectLimit) {
            setEmailSubjectError(FiftyCharacterErrorMsg);
            return false;
        }
        setEmailSubject(value);
    }

    const handleEmailBody = (e:any) => {
        setEmailBodyError('');
        let value = e.target.value ?? '';
        if(value.length>RecruiterContactEmailBodyLimit) {
            setEmailBodyError(ThousandCharacterErrorMsg);
            return false;
        }
        setEmailBody(value);
    }

    const handleSubmit = async () => {
        setEmailSubjectError('');
        setEmailBodyError('');
        setError('');
        if(!emailSubject){
            setEmailSubjectError(RequiredFieldCommonErrorMsg);
            return false;
        }
        if(!emailBody) {
            setEmailBodyError(RequiredFieldCommonErrorMsg);
            return false;
        }
        let requestObject = {
            'user' : user,
            'subject':emailSubject,
            'body': emailBody
        }
        setIsLoading(true);

        let response = await sendRequestToContactCandidate(requestObject);
        if(response && response.success) {
            setSwalProps({
                show: true,
                icon: 'info',
                title: 'Success',
                text: ContactCandidateSuccessMsg,
            });
            setEmailSubject('');
            setEmailBody('');
        }else{
            setError(prepareErrorMsg(response) ?? CommonErrorMsg);
        }
        setIsLoading(false);
    }

    return (
        <>
            <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header className="p-0">
                    <Modal.Title className="common-text common-text-size px-6 pt-6 pb-4">Please fill up the email subject and content</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0 pb-6 px-6">
                    <div className="pt-0 pb-6 ">
                        <label className="form-label d-none" htmlFor="subjectInput">Write the subject</label>
                        <input
                            id="subjectInput"
                            className="form-control"
                            type="text"
                            placeholder="Write the subject"
                            onChange={handleEmailSubject}
                            value={emailSubject}
                        />
                        <InputFieldErrorMessage error={emailSubjectError}/>
                    </div>
                    <div className="pt-0 pb-6 ">
                        <label
                            htmlFor="exampleFormControlTextarea1"
                            className="form-label d-none"
                        >
                            Email Body
                        </label>
                        <textarea
                            rows={4}
                            className="form-control"
                            placeholder="Enter your message"
                            id="exampleFormControlTextarea1"
                            onChange={handleEmailBody}
                            value={emailBody}
                        ></textarea>
                        <InputFieldErrorMessage error={emailBodyError}/>
                    </div>
                    <InputFieldErrorMessage error={error}/>
                    <div className="d-flex flex-column flex-md-row justify-content-md-end gap-3">
                        <button className="btn btn-secondary-gray" onClick={handleClose} disabled={isLoading}>Cancel</button>
                        <button onClick={(e)=>handleSubmit()} className="btn btn-primary" disabled={isLoading}>{isLoading ? 'Processing..': 'Contact'}</button>
                    </div>
                </Modal.Body>
            </Modal>

            <div>
                <SweetAlert2 {...swalProps}
                             didClose={() => {
                                 setIsContacted(true);
                                 handleClose();
                                 setSwalProps({})
                             }}
                />
            </div>

        </>
    );
}

export default ContactCandidateModal;