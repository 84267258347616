import { create } from "zustand";
import { persist, createJSONStorage } from 'zustand/middleware'

export const useSearchTab = create(
    // @ts-ignore
    persist(
        (set, get) => ({
            isSavedTabActive: false,
            hideSavedTab: () => set((state:any) => ({ isSavedTabActive: false})),
            showSavedTab: () => set((state:any) => ({ isSavedTabActive: true})),
        }),
        {
            name: 'active-tab', // name of the item in the storage (must be unique)
            storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
        }
    )
);