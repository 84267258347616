import {useEffect, useState} from "react";
import {useMessenger} from "../../context/useMessenger";
import {getInbox} from "../../services/ChatService";
import chat from "../chat/Chat";

const useInbox = () => {
    const [myChatList,setMyChatList] = useState([]);
    const [currentChatListPageNo,setCurrentChatListPageNo] = useState(1);
    const [lastChatListPageNo,setLastChatListPageNo] = useState(0);
    const selectedChat = useMessenger((state:any)=>state.selectedChat);
    const selectedChatUser = useMessenger((state:any)=>state.selectedChatUser);
    const setSelectedChatUser = useMessenger((state:any)=>state.setSelectedChatUser);
    const setSelectedChat = useMessenger((state:any)=>state.setSelectedChat);
    const setChatOwner = useMessenger((state:any)=>state.setChatOwner);
    const setTotalChats = useMessenger((state:any)=>state.setTotalChats);
    const setIsInboxLoading = useMessenger((state:any)=>state.setIsInboxLoading);
    const chats = useMessenger((state:any)=>state.chats);
    const setChats = useMessenger((state:any)=>state.setChats);


    const getMyChatList = async (pageNumber:number=1) => {
        if(pageNumber==1){
            setIsInboxLoading(true);
        }
        let response = await getInbox(pageNumber);
        if(response && response.success && response.data) {
            let data = response.data.data;
            let meta = response.data.meta;
            if(data && data.length>0){
                let newChats = [];
                if(chats.length>0){
                    newChats = [...chats];
                    newChats = newChats.concat(data);
                    setChats(newChats);
                }else{
                    setChats(data);
                }
            }
            if(response.extra && response.extra.user_id){
                setChatOwner(response.extra.user_id);
            }
            if(meta){
                setTotalChats(meta.total);
                setCurrentChatListPageNo(meta.current_page);
                setLastChatListPageNo(meta.last_page);
            }
        }
        setIsInboxLoading(false);
    }


    return {myChatList,setMyChatList,getMyChatList,currentChatListPageNo,lastChatListPageNo};
}
export default useInbox;