import {useEffect, useState} from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import mainLogo from "../../../assets/images/logos/logo_w_title_white.svg";
import mainLogoSm from "../../../assets/images/logos/logo_w_title_purple.svg";
import { AppStorageService } from "../../../services/AppStorageService";
import {
  CANDIDATE_PROFILE_PAGE,
  MULTIPLE_SKILLS_PAGE, RECRUITER_SEARCH_CANDIDATE, RECRUITER_SIGN_IN,
} from "../../../utils/RouteNamingConfig";
import {useAuthContext} from "../../../context/useAuthContext";
import RecruiterLoggedInMenu from "../../../components/header/RecruiterLoggedInMenu";
import hamburger from "../../../assets/images/icons/hamburger.svg";
import close from "../../../assets/images/icons/x-close-gray.svg";
import {useRecruiterHeader} from "../recruiter/useRecruiterHeader";
import SweetAlert2 from "react-sweetalert2";
import {sendRequestToLogout} from "../../../services/RecruiterService";

const Header = () => {
  let {user}:any = useAuthContext();
  let recruiter = user;
  const [isShown, setIsShown] = useState(false);
  const {loginErrorMsg,setLoginErrorMsg,renderGoogleLoginButtonAuto,renderGoogleLoginButtonOnClick,swalProps,setSwalProps} = useRecruiterHeader();

  let navigate = useNavigate();
  let storage = new AppStorageService();
  const location = useLocation();
  const currentRoute = location.pathname;
  let hideSignInButton = false;
  let isCandidateLoggedIn = storage.userLoggedIn();
  let profilePicture = "";
  let name = "";
  let email = "";

  if (currentRoute == MULTIPLE_SKILLS_PAGE) {
    hideSignInButton = true;
    window.scrollTo(0, 0);
  }

  if (isCandidateLoggedIn) {
    let candidateDetails = storage.getUserDetails();
    profilePicture = candidateDetails.picture;
    name = candidateDetails.name;
    email = candidateDetails.email;
  }

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);
    renderGoogleLoginButtonAuto('googleLoginBtnFromHeader');

  }, [currentRoute]);

  const signOut = () => {
    sendRequestToLogout().then(function (){
    });
    storage.setUserLoggedIn(false);
    localStorage.clear();
    window.location.reload();
  };

  const handleProfileButton = () => {
    navigate(CANDIDATE_PROFILE_PAGE);
  };

  const handleBurgerMenu = (e:any) => {
    setIsShown((e) => !e);
    setTimeout(()=>{
      renderGoogleLoginButtonOnClick('burger_menu_google_login_btn')
    },30)
  }

  let mainLogoLink = "/";

  if(recruiter) {
    mainLogoLink = RECRUITER_SEARCH_CANDIDATE;
  }
  return (
      <>
    <nav className="bg-theme navbar navbar-expand-lg">
      <div className="container px-md-0">
        <Link to={mainLogoLink} className="navbar-brand text-white">
          <img src={mainLogo} className="img-fluid" alt="Remote Devs" />
        </Link>


        {hideSignInButton || isCandidateLoggedIn || recruiter? (
          " "
        ) : (
          <>
            <div
                className={`cursor-pointer d-inline-block d-lg-none`}
                onClick={(e) => handleBurgerMenu(e)}
            >
              <img src={hamburger} alt="" />
            </div>
          <div className="collapse navbar-collapse" id="navbarScroll">
              <ul className="navbar-nav ms-auto my-2 my-lg-0">
                <li>
                  {/*<Link
                      to={RECRUITER_SIGN_IN}
                      className="btn"
                      style={{ color: "#7f54df", backgroundColor: "#F9F5FF" }}
                  >
                    Sign in as recruiter
                  </Link>*/}
                </li>
                <li className="nav-item ms-2" style={{ position: "relative" }}>
                  <a
                      className="btn"
                      style={{ color: "white", backgroundColor: "#7f54df" }}
                  >
                    Sign in as candidate
                  </a>
                  <button
                      className="btn btn-secondary w-100"
                      id="googleLoginBtnFromHeader"
                      style={{
                        padding: 0,
                        border: "1px solid #d0d5dd",
                        textAlign: "center",
                        margin: "0 auto",
                        background: "#ffffff",
                        position: "absolute",
                        left: 0,
                        opacity: 0,
                      }}
                  >
                    Sign up with Google
                  </button>
                </li>
              </ul>
          </div>
          </>
        )}

        {isCandidateLoggedIn ? (
          <div className="dropdown">
            <Dropdown>
              <Dropdown.Toggle id="profile_dropdown" className="profile_dropdown">
                <img
                  src={profilePicture}
                  className="img-fluid rounded-circle border border-3 border-white"
                  width="40"
                  height="40"
                  alt=""
                />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="me-2" style={{ width: 40 }}>
                      <img
                        src={profilePicture}
                        className="img-fluid rounded-circle border"
                        width="40"
                        height="40"
                        alt=""
                      />
                    </div>
                    <div>
                      <p className="mb-0 fw-semibold">{name}</p>
                      <p className="mb-0">{email}</p>
                    </div>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={handleProfileButton}>
                  <i className="bi bi-person me-3"></i>Profile
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={signOut}>
                  <i className="bi bi-box-arrow-right me-3"></i>Sign out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ) : ''}

        { recruiter ?
            <>
              <RecruiterLoggedInMenu user={recruiter}/>
            </>
            :
            ""
        }
      </div>
    </nav>

        {isShown && (
            <div className="position-fixed z-3 bg-white w-100 top-0">
              <div className="container">
                <div className="py-4 d-flex justify-content-between">
                  <img src={mainLogoSm} alt="" />
                  <img className="cursor-pointer" src={close} alt="" onClick={() => setIsShown((e) => !e)} />
                </div>
                <div className="py-6 position-relative">
                  <a
                      id="div_tag_id"
                      className="btn w-100"
                      style={{ color: "white", backgroundColor: "#7f54df" }}
                  >
                    Sign in as candidate
                  </a>
                    <button
                        className="btn w-100 candidate"
                        id="burger_menu_google_login_btn"
                        style={{
                          padding: 0,
                          border: "1px solid #d0d5dd",
                          textAlign: "center",
                          margin: "0 auto",
                          background: "#ffffff",
                          position: "absolute",
                          left: 0,
                          opacity: 0,
                        }}
                    >
                      Sign up with Google
                    </button>
                  {/*<Link
                      to={RECRUITER_SIGN_IN}
                      className="btn w-100 mt-3"
                      style={{ color: "#7f54df", backgroundColor: "#F9F5FF" }}
                  >
                    Sign in as recruiter
                  </Link>*/}
                </div>
              </div>
            </div>
        )}

        <SweetAlert2 {...swalProps}
                     didClose={() => {
                       setSwalProps({})
                     }}
        />
      </>


  );
};

export default Header;
