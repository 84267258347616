import { create } from "zustand";

export const useMessenger = create((set) => {
    return {
        selectedChat: '',
        selectedChatUser: '',
        selectedChatUserInfo: {},
        chatOwner: '',
        totalChats:0,
        isInboxLoading:false,
        setIsInboxLoading: (status:boolean) => set((state:any) => ({ isInboxLoading: status})),
        setTotalChats: (totalChat:any) => set((state:any) => ({ totalChats: totalChat})),
        setSelectedChat: (chatId:string) => set((state:any) => ({ selectedChat: chatId})),
        setSelectedChatUser: (userId:string) => set((state:any) => ({ selectedChatUser: userId})),
        setSelectedChatUserInfo: (userInfo:any) => set((state:any) => ({ selectedChatUserInfo: userInfo})),
        setChatOwner: (userId:string) => set((state:any) => ({ chatOwner: userId})),
        chats: [],
        setChats: (data:any) => set((state:any) => ({ chats: data})),
    };
});