import { Link } from "react-router-dom";
import avatar from "../../../assets/images/avatar.png";
import icon from "../../../assets/images/icons/avatar-icon.svg";
import mobileLogo from "../../../assets/images/icons/logo-for-signup.svg";
import star from "../../../assets/images/icons/star-fill.svg";
import AuthPageSideBar from "../../../components/recruiter/auth/AuthPageSideBar";
import {
  RECRUITER_FORGOT_PASSWORD,
  RECRUITER_SIGN_UP,
} from "../../../utils/RouteNamingConfig";
import { useState } from "react";
import { useSignIn } from "./useSignIn";
import InputFieldErrorMessage from "../../../components/errors/InputFieldErrorMessage";
import NextButtonDynamic from "../../../components/buttons/NextButtonDynamic";
import DisableEnableButton from "../../../components/buttons/DisableEnableButton";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const {
    emailError,
    setEmailError,
    passError,
    setPassError,
    validateEmail,
    validatePassword,
    signIn,
    isLoading,
    error,
    setError,
  } = useSignIn();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setEmailError("");
    setPassError("");
    let isValidEmail = validateEmail(email);
    let isValidPass = validatePassword(password);

    if (isValidEmail && isValidPass) {
      await signIn(email, password);
    }
  };
  return (
    <div className="recruiter-spacious-section col-lg-6 d-lg-flex align-items-center justify-content-center">
      <div className="row mx-0">
        <div className="col-md-7 col-12 mx-auto px-0">
          <div className="text-center">
            <img src={mobileLogo} className="img-fluid d-md-none pb-6" alt="" />
            <h2 className="display-5 fw-semibold text-dark pb-2 pb-md-3">
              Sign in as recruiter
            </h2>
            <p className="text-secondary mb-0">
              Welcome back! Let's get started
            </p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="row gy-4 gy-md-6 mx-0 pt-7">
              <div className="col-12">
                <label htmlFor="" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className={
                    emailError && emailError?.length > 0
                      ? "form-control  error-input"
                      : "form-control"
                  }
                  placeholder="Enter your email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <InputFieldErrorMessage error={emailError} />
              </div>

              <div className="col-12">
                <label htmlFor="" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  className={
                    passError && passError?.length > 0
                      ? "form-control  error-input"
                      : "form-control"
                  }
                  placeholder="Enter your password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
                <InputFieldErrorMessage error={passError} />
              </div>

              <div className="col-12 ">
                <div className="d-flex justify-content-between">
                  <Link to={RECRUITER_FORGOT_PASSWORD}>
                    <span className="small fw-semibold">Forgot password?</span>
                  </Link>
                </div>
              </div>
              <div className="col-12 ">
                <DisableEnableButton disable={isLoading} text="Sign In" />
                <InputFieldErrorMessage error={error} />
              </div>
            </div>
          </form>
          <div className="row pb-7 mx-0">
            <div className="col-12 py-7 text-center">
              <p className="mb-0">
                <span>Don’t have an account?</span>{" "}
                <Link to={RECRUITER_SIGN_UP} className="small fw-semibold">
                  Sign up
                </Link>
              </p>
            </div>
            {/*<div className="col-12 mb-7 pb-7">
              <button className="btn btn-secondary-gray w-100 " type="submit">
                Looking for a job? Click here
              </button>
            </div>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
