const MultipleSkillsPageHeading = (props:any) => {

    let title = props.title;
    let description = props.description;
    
    return (
        <div className="col">
            <h2
                className="text-start text-md-center text-white display-2 fw-semibold mb-6"
            >
                { title }
            </h2>
            <p className="text-start text-md-center text-white mb-7 pb-4 lead fw-normal">
                {
                    description
                }
            </p>
        </div>
    );
    
}

export default MultipleSkillsPageHeading;