import React from "react";

const Loader = () => {
  return (
    <>
      <div className="svg36 loader-animate3 horizontal-margin-auto mb-2" />
      {/*<div className="text-center letter-space drop-shadow text-uppercase fs-12 w-100 mb-3">
        Today
      </div>*/}
    </>
  );
};

export default Loader;
