import React from "react";

const LoadingSalary = () => {
    return (
        <>
            <p className="mb-0 ">
                    <span className="text-white pe-2 fs-3">
                      {" "}
                        Loading...{" "}
                    </span>
            </p>
        </>
    );
}

export default LoadingSalary;