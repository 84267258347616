import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { AppStorageService } from "../../../../services/AppStorageService";

const Pagenation = (props: any) => {
  let candidateObject = props.candidateObject;
  let allDataFromApi = candidateObject.allDataFromApi;
  let totalCandidate = candidateObject.totalCandidate;
  let itemsPerPage = 10;
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [items, setItems] = useState(allDataFromApi.data ?? []);
  const [showPrevBtn, setShowPrevBtn] = useState(true);
  const [showNextBtn, setShowNextBtn] = useState(true);


  useEffect(()=>{
    if((candidateObject.paginationCurrentPage+1)==1){
      setShowPrevBtn(false);
    }else{
      setShowPrevBtn(true);
    }

    if((candidateObject.paginationCurrentPage+1)==pageCount){
      setShowNextBtn(false);
    }else{
      setShowNextBtn(true);
    }

    if(pageCount==1){
      setShowNextBtn(false);
      setShowPrevBtn(false);
    }

  },[candidateObject.paginationCurrentPage,pageCount])


  useEffect(() => {
    if (candidateObject && allDataFromApi) {
      setPageCount(Math.ceil(totalCandidate / itemsPerPage));
    }

  }, [candidateObject]);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    // @ts-ignore
    setCurrentItems(items.slice(itemOffset, endOffset));
  }, [itemOffset, itemsPerPage]);

  const handlePageClick = (event: any) => {
    let storage = new AppStorageService();
    let filterData = storage.getAllSelectedFilterData();
    let pageObject = {
      page: event.selected + 1,
    };
    sessionStorage.setItem('current_page',JSON.stringify(event.selected));
    if (filterData) {
      pageObject = { ...filterData };
      pageObject.page = event.selected + 1;
      storage.storeAllSelectedFilterData(pageObject);
    }
    candidateObject.handleNextPage(pageObject);
  };

  let prevLink = (
    <button className={showPrevBtn ? "btn small text-secondary paginationBorder left-arrow" : "d-none" }>
      <i className="bi bi-arrow-left " />{" "}
      <span className="ps-2 d-none d-md-inline-block">Previous</span>
    </button>
  );

  let nextLink = (
    <button className={showNextBtn ? "btn small text-secondary paginationBorder right-arrow":"d-none"}>
      <span className="pe-2 d-none d-md-inline-block">Next</span>
      <i className="bi bi-arrow-right" />
    </button>
  );

  let breakLabel = <span className="px-4 py-3">....</span>;

  return (
    <>
      {(totalCandidate <= itemsPerPage && candidateObject.paginationCurrentPage == 0) ? '' :
          <>
          <hr className="m-0"/>

            <ReactPaginate
            nextLabel={nextLink}
            forcePage={candidateObject.paginationCurrentPage}
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel={prevLink}
            prevRel={null}
            pageClassName="px-4 py-3 text-center"
            breakLabel={breakLabel}
            breakClassName="px-4 py-3"
            containerClassName="pagination"
            activeClassName="activePage "
            renderOnZeroPageCount={null}
            />
          </>
      }
    </>
  );
};

export default Pagenation;
