import React from "react";

const NegotiableSalary = () => {

    return (
        <>
            <p className="mb-0 ">
                <span style={{ color: "#F9FAFB" }}>Salary</span>
                    <span className="text-white pe-2 fs-3">
                      {" "}
                        Negotiable{" "}
                    </span>

            </p>
        </>
    );
}

export default NegotiableSalary;