import React from 'react'
import Header from '../Header'
import Footer from '../Footer'
import StaticContent from './StaticContent'
import { useAnalytics } from '../../../../utils/useAnalytics'

const HomeLayout = () => {
  useAnalytics()
  return (
    <>
    <Header />
    <StaticContent />
    <Footer />
</>
  )
}

export default HomeLayout