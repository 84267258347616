import { Outlet } from "react-router-dom";
import RecruiterHeader from "./RecruiterHeader";
import RecruiterFooter from "./RecruiterFooter";

const RecruiterBaseLayoutWithFooter = () => {
  return (
    <>
        <RecruiterHeader />
            <div className="main">
                <Outlet />
            </div>
        <RecruiterFooter/>
    </>
  );
};

export default RecruiterBaseLayoutWithFooter;
