import locationPointer from "../../../../assets/images/icons/location-pointer.svg";
import { RECRUITER_CANDIDATE_PROFILE_BASE_URL } from "../../../../utils/RouteNamingConfig";
import { Link } from "react-router-dom";
import CandidateSave from "../../candidate-profile/component/CandidateSave";
import ExpectedSalary from "./ExpectedSalary";
import NegotiableSalary from "./NegotiableSalary";
import { SearchPageOverviewCharacterBreak } from "../../../../utils/CommonConstants";
import CustomTooltip from "../../../../components/tooltip/CustomTooltip";
import PassiveJobSeekerBadge from "./PassiveJobSeekerBadge";

const AllCadidates = (props: any) => {
  let characterBeak = SearchPageOverviewCharacterBreak;
  let candidate = props.candidate;
  let expectedSalary = candidate.expected_salary
    ? candidate.expected_salary.toLocaleString()
    : "";
  let workExperience = candidate.work_experience ?? "";
  if (workExperience) {
    if (workExperience.length > characterBeak) {
      workExperience = workExperience.substring(0, characterBeak) + "...";
    }
  }
  let jobTypesObject = candidate.job_types ?? [];
  let extraSkillObject = candidate.extra_skills ?? [];
  let primarySkillObject = candidate.primary_skills ?? [];
  let extraSkillArr: any = [];
  let totalExtraSkill = extraSkillObject.length;
  let profileUrl =
    RECRUITER_CANDIDATE_PROFILE_BASE_URL + "/" + candidate.user_id + "/details";

  for (let i = 0; i < extraSkillObject.length; i++) {
    if (i > 2) {
      let extraSkill = totalExtraSkill - i;
      let moreSkill = <span>+ {extraSkill}</span>;

      let moreSkillsName = extraSkillObject
        .slice(3)
        .map((item: any, index: any) => (
          <span key={index}>
            {index ? ", " : ""}
            {item.name}
          </span>
        ));
      extraSkillArr.push(
        <CustomTooltip
          btnText={moreSkill}
          placement={"auto"}
          tooltipText={moreSkillsName}
        />
      );
      break;
    }
    extraSkillArr.push(extraSkillObject[i].name);
  }

  let saveObject = {
    user: candidate.user_id,
    isSaved: candidate?.save_list ? candidate.save_list.length > 0 : false,
  };

  return (
    <>
      <Link to={profileUrl}>
        <div className="col-12 cursor">
          <div className="border shadow-sm p-4 rounded-3">
            <div className="row gy-4">
              <div className="col-12">
                <div className="row">
                  <div className="col-10 col-md-11">
                    <div className="d-md-flex  gap-2 pb-1">
                      <p className="mb-0 pb-1 fw-semibold candidate-title">
                        {candidate.job_title}
                      </p>
                      {expectedSalary.length <= 0 ? (
                        <PassiveJobSeekerBadge />
                      ) : (
                        ""
                      )}
                    </div>

                    <p className="mb-0 candidate-common-text">
                      {primarySkillObject &&
                        primarySkillObject.map((item: any, index: any) => {
                          return (
                            <span key={index}>
                              <span>
                                {index ? ", " : ""}
                                {item.name}{" "}
                                {item.pivot.experience
                                  ? item.pivot.experience + " years"
                                  : ""}
                                {item.pivot.currently_working == "Yes"
                                  ? " (using now)"
                                  : ""}
                              </span>{" "}
                            </span>
                          );
                        })}
                    </p>
                  </div>
                  <div className="col-2 col-md-1 px-md-0 px-lg-3">
                    <CandidateSave saveObject={saveObject} />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="d-flex gap-1">
                  {extraSkillArr &&
                    extraSkillArr.map((item: any, index: any) => {
                      return (
                        <span key={index}>
                          <p className="small mb-0 px-2 py-1 text-semidark rounded-4 skillBg">
                            {item}
                          </p>
                        </span>
                      );
                    })}
                </div>
              </div>
              <div className="col-12">
                <p className="mb-0 candidate-search-details">
                  {workExperience}
                </p>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-9">
                    <div className="d-flex gap-2">
                      <img
                        src={locationPointer}
                        alt=""
                        className="img-fluid align-self-start pt-1"
                      />
                      <p className="mb-0 candidate-common-text">
                        {jobTypesObject &&
                          jobTypesObject.map((item: any, index: any) => {
                            return (
                              <span key={index}>
                                <span>
                                  {index ? ", " : ""}
                                  {item.name}
                                </span>{" "}
                                <span></span>{" "}
                              </span>
                            );
                          })}
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-3 ">
                    {expectedSalary.length > 0 ? (
                      <ExpectedSalary expectedSalary={expectedSalary} />
                    ) : (
                      <NegotiableSalary />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default AllCadidates;
