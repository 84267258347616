const ExpectedSalary = (props:any) => {
    let expectedSalary = props.expectedSalary;
    return (
        <>
            <div className="d-flex gap-2 justify-content-md-end pt-4 pt-md-0">
                <p
                    className="mb-0 text-dark fw-semibold"
                    style={{ fontSize: "20px" }}
                >
                    {expectedSalary}
                </p>
                <p className="mb-0 candidate-common-text align-self-center fw-normal">
                    BDT/month
                </p>
            </div>
        </>
    );
}

export default ExpectedSalary;