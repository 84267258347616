import React from 'react'

const PaymentNavTabs = (props:any) => {
    let tabObject = props.tabObject;
    let activeClass = "nav-link rounded-0 p-3 fw-semibold text-start  active";
    let inActiveClass = "nav-link rounded-0 p-3 fw-semibold text-start";
  return (
    <nav className="pt-4">
          <div
            className="nav nav-tabs flex-row payment-nav "
            id="nav-tab"
            role="tablist"
          >
            {/*<button
              className={tabObject.showPaymentTab ? activeClass:inActiveClass}
              id="nav-profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-profile"
              type="button"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="true"
              onClick={tabObject.showTab}
            >
              Pay by card
            </button>*/}
            <button
              //className={!tabObject.showPaymentTab ? activeClass:inActiveClass}
              className={activeClass}
              id="nav-billing-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-billing"
              type="button"
              role="tab"
              aria-controls="nav-billing"
              aria-selected="true"
              onClick={tabObject.hideTab}
            >
              Pay via bank
            </button>
          </div>
        </nav>
  )
}

export default PaymentNavTabs
