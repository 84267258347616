import ScrollableFeed from "react-scrollable-feed";
import {useMessenger} from "../../context/useMessenger";


const ScrollableChat = ({messages}:any) => {
    const selectedChatUser = useMessenger((state:any)=>state.selectedChatUser);

    const sameSenderMargin = (messages:any,m:any,i:any,opponent:any) => {

        if(m.sender==opponent){
            return 0;
        }else{
            return 533;
        }
    }
    return (
        <>
            <ScrollableFeed>
                {messages && messages.map((m:any,i:any)=>(
                        <div style={{display:'flex'}} key={i}>
                            <span style={{
                                backgroundColor: `${
                                    m.sender==selectedChatUser ? "#BEE3F8":"#F9F5F0"
                                }`,
                                borderRadius:"20px",
                                padding: "5px 15px",
                                maxWidth: "75%",
                                marginLeft: sameSenderMargin(messages,m,i,selectedChatUser)
                            }}>
                                {m.content}
                            </span>
                        </div>
                    )
                )}
            </ScrollableFeed>

        </>
    );
}

export default ScrollableChat;