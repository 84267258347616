import {
    RECRUITER_FORGOT_PASSWORD,
    RECRUITER_POST_FORGOT_PASSWORD,
    RECRUITER_POST_SIGN_UP,
    RECRUITER_RESET_PASSWORD,
    RECRUITER_RESET_PASSWORD_CONFIRM,
    RECRUITER_SIGN_IN,
    RECRUITER_SIGN_UP
} from "./RouteNamingConfig";

export const SOCKET_ENDPOINT = process.env.REACT_APP_SOCKET_ENDPOINT ?? '';
export const CommonErrorMsg = "Something went wrong";
export const NonBdNumberErrorMsg = "Please provide your 11 digit Bangladeshi number which starts with 01";
export const SalaryLengthError = "4 to 6 digit number is allowed";
export const NumberOfPrimarySkills = ['1','2','3','4','5','6'];
export const EmptyFeedbackErrorMsg = "Please specify a reason";
export const SocialShareLink = "https://remotedevs.com";
export const HJID = process.env.REACT_APP_HOTJAR_HJID ? parseInt(process.env.REACT_APP_HOTJAR_HJID) :parseInt('');
export const HJSV = process.env.REACT_APP_HOTJAR_HJSV ? parseInt(process.env.REACT_APP_HOTJAR_HJSV):parseInt('');
export const GTM_ID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID ? process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID : '';
export const GoogleLoginSignInMsg = "Please sign up with Google to continue";
export const RequiredFieldCommonErrorMsg = "This is required";
export const CvDeleteErrorMsg = "Something went wrong!";
export const InvalidEmailErrorMsg = "Please provide a valid email";
export const InvalidEmailDomainErrorMsg = "Please sign up with your corporate e-mail to ensure your identity";
export const InvalidPasswordErrorMsg = "Password must be at least 6 characters long and include at least one special character and alphanumeric characters";
export const InvalidConfirmPasswordErrorMsg = "Passwords do not match";
export const InvalidYearErrorMsg = "Enter a valid year in between 1900 and current year";
export const TermsAndConditionsCheckErrorMsg = "Please agree to the Terms and Conditions";
export const InvalidEmailDomainList = ['gmail','outlook','aol','yahoo','protonmail','zohomail','gmxmail'];
export const ApiBaseUrl = process.env.REACT_APP_API_BASE_URL;
export const FromSalaryBiggerError = "'From' number must be less than 'To' number";
export const FiftyCharacterErrorMsg = "Max 50 characters allowed";
export const ThousandCharacterErrorMsg = "Max 1000 characters allowed";
export const ContactCandidateSuccessMsg = "Success! Your email has been sent to the candidate. You will receive responses from candidates in your inbox.";
export const CandidateProfileJobTypeRequiredMsg = "Please select at least one job type";
export const CandidateProfilePrimarySkillRequiredMsg = "Please select at least one primary skill";
export const RecruiterSignInButtonHideArray = [
    RECRUITER_SIGN_IN,
    RECRUITER_SIGN_UP,
    RECRUITER_POST_SIGN_UP,
    RECRUITER_FORGOT_PASSWORD,
    RECRUITER_POST_FORGOT_PASSWORD,
    RECRUITER_RESET_PASSWORD,
    RECRUITER_RESET_PASSWORD_CONFIRM
];

export const RecruiterStatusCollection:any = [];
RecruiterStatusCollection['new'] = 'New';
RecruiterStatusCollection['unverified'] = 'Unverified';
RecruiterStatusCollection['under_review'] = 'Under Review';
RecruiterStatusCollection['rejected'] = 'Rejected';

export const RecruiterContactEmailSubjectLimit = 50;
export const RecruiterContactEmailBodyLimit = 1000;
export const MultiRangeSliderStep = 1;
export const MultiRangeSliderMax = 15;
export const MultiRangeSliderMin = 0;
export const MultiRangeSliderDefaultMax = 15;
export const MultiRangeSliderDefaultMin = 0;

export const RecruiterProfileAnnouncementsActiveClass = "tab-pane fade show pt-0 active";
export const RecruiterProfileAnnouncementCookieKey = 'announce'
export const RecruiterNewProfileAnnouncementCookieKey = 'new_profile_announcement'

export const SearchPageOverviewCharacterBreak = 222;
export const ProfileMessageShowDuration = 3000;
export const InvalidCharactersFoNumberInput = ["-", "e", "+", "E"];
