import React from "react";

function NextButton(props: any) {
    const text = props.text;
    return (
        <>
            <button className="btn btn-primary w-100" type="submit" onClick={props.onClick}>
                {text}
            </button>
        </>

    );
}

export default NextButton;