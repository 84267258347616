import FileSaver from "file-saver";

const AfterUpload = (props: any) => {
  let show = props.show;
  const handleDelete = props.handleDelete;
  const disbaleDeleteBtn = props.disbaleDeleteBtn;
  const name = props.name;
  const size = props.size;
  const fileUrl = props.url;

  const downloadFile = async () => {
    try {
      fetch(fileUrl).then((response) => {
        response.blob().then((blob) => {
          const FileSaver = require("file-saver");
          let file = new Blob([blob], { type: "application/pdf" });
          FileSaver.saveAs(file, name);
        });
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {show ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <div className="px-4 w-100">
            <div className="row">
              <div className="col-10">
                <p className="mb-0 small text-semidark">{name}</p>
                <p className="mb-0 small text-secondary">{size}</p>
                <br />
                <p
                  className=" mb-0 small text-dark "
                  style={{ textAlign: "center" }}
                >
                  <span
                    onClick={downloadFile}
                    className="cursor-pointer text-theme fw-semibold"
                  >
                    <i className="bi bi-box-arrow-down cursor-pointer mt-1 me-2"></i>
                    Download
                  </span>
                </p>
              </div>

            {/*<i className="bi bi-box-arrow-down cursor-pointer mt-1 me-2"></i>*/}
              {disbaleDeleteBtn ?
                  <button
                      onClick={handleDelete}
                      className="btn cursor-pointer deleteBtnDisabled deleteError mt-1 "
                      disabled={true}
                ></button>
                :<button
                onClick={handleDelete}
                className="btn cursor-pointer deleteError mt-1 "
                ></button>
                }
          </div>
        </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default AfterUpload;
