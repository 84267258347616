import checkedIcon from "../../../../assets/images/icons/check-icon.svg";
import notFount from "../../../../assets/images/icons/not-found.svg";
const NoSavedCandidateFound = () => {
  return (
    <div className="py-6">
      <img src={notFount} alt="" className="img-fluid pb-6" />
      <div className="bg-light p-4 rounded-3">
        <p
          className="mb-0 fw-semibold text-black pb-4"
          style={{ fontSize: "20px" }}
        >
          {" "}
          You can consider these suggestions:{" "}
        </p>
        <ul className="p-0 mb-0">
          <li className="d-flex gap-2 pb-2">
            <img src={checkedIcon} alt="" />
            <p className="mb-0">
              Adjust different filters (remove a few if needed)
            </p>
          </li>
          <li className="d-flex gap-2 pb-2">
            <img src={checkedIcon} alt="" />
            <p className="mb-0">Make sure all words are spelt correctly</p>
          </li>
          <li className="d-flex gap-2 pb-2">
            <img src={checkedIcon} alt="" />
            <p className="mb-0">Use AND ,OR operator carefully</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NoSavedCandidateFound;
