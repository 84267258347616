import React from "react";

const HomePageHeading = (props:any) => {
    let title = props.title;
    let description = props.description;
return (
    <div className="col-12 col-md-8">
        <h2 className="text-center mb-4 mb-md-5">
            {title}
        </h2>
        <p className="text-center lead fw-normal mb-7 pb-4">
            {description}
        </p>
    </div>
);
}

export default HomePageHeading;