import React from 'react'

import mainLogoTitle from '../../../assets/images/logos/logo_w_title_white.svg';
import mainLogo from '../../../assets/images/logos/logo_white.svg';
import {PRIVACY_POLICY_PAGE, TERMS_AND_CONDITIONS_PAGE} from "../../../utils/RouteNamingConfig";

const Footer = () => {
  let terms = TERMS_AND_CONDITIONS_PAGE;
  let privacyPolicy = PRIVACY_POLICY_PAGE;
  return (
      <footer className="py-7" style={{backgroundColor: '#1d2939'}}>
        <div className="container">
          <div className="row justify-content-between align-items-center my-4">
            <div className="col-12 col-md-auto text-center">
              <img
                  src={mainLogoTitle}
                  className="img-fluid d-none d-md-block"
                  alt="Remote Devs"
              />

              <img
                  src={mainLogo}
                  className="img-fluid d-md-none"
                  width="56"
                  alt="Remote Devs"
              />
            </div>
            <div className="col-12 col-md-auto py-7 py-md-0">
              <div className="row justify-content-between">
                <div className="col-auto">
                  <a href={terms} className="text-white fw-semibold" target="_blank">Terms of service</a>
                </div>
                <div className="col-auto">
                  <a href={privacyPolicy} className="text-white fw-semibold" target="_blank">Privacy policy</a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-auto text-center mt-4 mt-md-0">
              <p className="mb-0" style={{color: '#98a2b3'}}>
                © 2023 RemoteDevs. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>

  )
}

export default Footer