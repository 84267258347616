import xCross from "../../../assets/images/icons/x-close-red.svg";
import {useEffect, useState} from "react";
import {getCookie, setCookie} from "../../../services/RecruiterAuthenticationService";
import {useAuthContext} from "../../../context/useAuthContext";

const ProfileReject = () => {
  const [show,setShow] = useState(false);
  const {user}:any= useAuthContext();
  useEffect(()=>{


  },[]);

  useEffect(()=>{
    if(!user.rejectionId){
      setShow(false);
    }else{
      if(getCookie('_rej'+user.rejectionId)){
        setShow(false);
      }else{
        setShow(true);
      }
    }
  },[user])

  const handleCross = () => {
    setCookie('_rej'+user.rejectionId,'set',720);
    setShow(false);
  }
  return (
      <>
        {!show ? "":
    <section className="profile-reject profile-reject-border">
        <div className="container">
          <div className="row">
            <div className="col-11 py-4">
              <p className="fw-semibold mb-0">
                We're sorry, but your profile cannot be published at this time.
              </p>
              <p className="mb-0">
                Rejection reason: {user.rejectionReason ?? ''}
              </p>
            </div>
            <div className="col-1 py-4">
              <img onClick={()=>handleCross()} className="cursor-pointer" src={xCross} alt="" />
            </div>
          </div>
        </div>
      </section>
        }
      </>
  )
}

export default ProfileReject
