import React, { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Select from "react-select";
import backgroundPattern from "../../../../assets/images/background-pattern.svg";
import RequiredFieldError from "../../../../components/errors/RequiredFieldError";
import HomePageHeading from "../../../../components/headings/HomePageHeading";
import { AppStorageService } from "../../../../services/AppStorageService";
import {
  checkForValidExperience,
  getAllSkills,
  getExperiencesBySkillId,
} from "../../../../services/SkillService";
import { ExperienceList } from "../../../../types/ExperienceList";
import { Skill } from "../../../../types/Skill";
import { MULTIPLE_SKILLS_PAGE } from "../../../../utils/RouteNamingConfig";
import useHome from "../../../../views/home/useHome";

const StaticContent = () => {
  let pageHeadingTitle = "Many devs like you are concerned too";
  let pageHeadingDescription =
    "According to several studies, Bangladeshi developers are not receiving fair compensation for their expertise, which causes several problems";
  let classWithError = "col-12 col-md-7 select__control_error";
  let classWithoutError = "col-12 col-md-7";
  const [isClearable, isSearchable] = useHome();
  let navigate = useNavigate();
  let storage = new AppStorageService();
  let allSkillsFromStorage = storage.getAllSkills();
  const skillRef = useRef(null);
  const experienceRef = useRef(null);

  const [skills, setSkills] = useState<any[]>(allSkillsFromStorage);
  const [experiences, setExperience] = useState<any[]>([]);
  const [selectedSkills, setSelectedSkills] = useState<Skill[]>([]);
  const [selectedExperiences, setSelectedExperiences] = useState<
    ExperienceList[]
  >([]);
  const [skillError, setSkillError] = useState(false);
  const [experienceError, setExperienceError] = useState(false);

  const getSkills = async () => {
    const skillsFromServer = await getAllSkills();
    let skillOptions: any = [];
    if (skillsFromServer && skillsFromServer.data) {
      skillsFromServer.data.map((item: any) => {
        let temp: any = {};
        temp.value = item.id;
        temp.label = item.name;
        skillOptions.push(temp);
      });
      storage.storeAllSkills(skillOptions);
      setSkills(skillOptions);
    } else {
      console.log("No skills found from server");
    }
  };

  useEffect(() => {
    if (allSkillsFromStorage && allSkillsFromStorage.length > 0) {
    } else {
      getSkills();
    }
  }, []);

  useEffect(() => {
    if (selectedSkills.length > 0) {
      selectedSkills.map((item: any) => {
        setExperience(getExperiencesBySkillId(item.value));
        //setSelectedExperiences([]);
        return;
      });
    }
  }, [selectedSkills]);

  useEffect(() => {
    if (selectedExperiences.length > 0 && experiences.length > 0) {
      selectedExperiences.map((item) => {
        if (checkForValidExperience(item, experiences)) {
        } else {
          setSelectedExperiences([]);
        }
      });
    }
  }, [experiences]);

  const validateForm = () => {
    setSkillError(false);
    setExperienceError(false);
    //@ts-ignore
    skillRef.current.className = classWithoutError;
    //@ts-ignore
    experienceRef.current.className = classWithoutError;

    let isValid = false;
    if (
      selectedSkills &&
      selectedSkills.length > 0 &&
      selectedExperiences &&
      selectedExperiences.length > 0
    ) {
      isValid = true;
    }
    if (!selectedSkills || selectedSkills.length <= 0) {
      //@ts-ignore
      skillRef.current.className = classWithError;
      setSkillError(true);
    }
    if (!selectedExperiences || selectedExperiences.length <= 0) {
      //@ts-ignore
      experienceRef.current.className = classWithError;
      setExperienceError(true);
    }
    return isValid;
  };

  const handleSubmit = () => {
    const isValid = validateForm();
    if (!isValid) {
      console.error("Invalid Form!");
      return false;
    }
    setSelectedSkills([]);
    setSelectedExperiences([]);
    navigate(MULTIPLE_SKILLS_PAGE);
  };

  const handlePrimarySkill: any = (skillData: any) => {
    // @ts-ignore
    skillRef.current.className = classWithoutError;
    setSkillError(false);

    let skillsArr: Skill[] = [];
    if (skillData) {
      skillsArr.push(skillData);
      storage.storeSelectedSkills(skillsArr);
      //setSkills(disabledSkillOption(currentSkills, selectedSkills));
    } else {
      storage.clearSelectedSkills();
    }
    setSelectedSkills(skillsArr);
  };

  const handleExperience: any = (experienceData: any) => {
    //@ts-ignore
    experienceRef.current.className = classWithoutError;
    setExperienceError(false);

    let experienceListArr: ExperienceList[] = [];
    if (experienceData) {
      experienceListArr.push(experienceData);
      storage.storeSelectedExperience(experienceListArr);
    } else {
      storage.clearSelectedExperience();
    }
    setSelectedExperiences(experienceListArr);
  };

  return (
    <React.Fragment>
      <section className="spacious-section bg-theme overflow-hidden position-relative pb-0">
        <Outlet />

        <div className="bg-white position-absolute start-0 end-0 bottom-0 z-0 ">
          <img
            style={{ width: "100%" }}
            src={backgroundPattern}
            className="object-fit-cover"
            alt=""
          />
        </div>
      </section>

      <section className="spacious-section bg-light">
        <div className="container">
          <div className="row justify-content-center">
            <HomePageHeading
              title={pageHeadingTitle}
              description={pageHeadingDescription}
            />
          </div>
          <div className="row row-cols-3 g-7 mt-4">
            <div className="col-12 col-md mb-6 mb-md-0">
              <div className="card border-0 h-100">
                <div
                  className="d-flex justify-content-center align-items-center position-absolute rounded-circle top-0 start-50 translate-middle"
                  style={{ width: 48, height: 48, backgroundColor: "#f9f5ff" }}
                >
                  <div
                    className="d-flex justify-content-center align-items-center position-absolute rounded-circle"
                    style={{
                      width: 36,
                      height: 36,
                      backgroundColor: "#f4ebff",
                    }}
                  >
                    <span>
                      <img
                        src={
                          require("../../../../assets/images/icons/arrows-down.svg")
                            .default
                        }
                        className="img-fluid"
                        alt=""
                        width="20"
                      />
                    </span>
                  </div>
                </div>
                <div className="card-body text-center mt-6 pt-5">
                  <h5 className="card-title -5">
                    Not enough paid according to skill sets
                  </h5>
                  <p className="card-text">
                    Go with the flow may end up to wasting your valuable time & work for the skills that are not in-demand.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md mb-6 mb-md-0">
              <div className="card border-0 h-100">
                <div
                  className="d-flex justify-content-center align-items-center position-absolute rounded-circle top-0 start-50 translate-middle"
                  style={{ width: 48, height: 48, backgroundColor: "#f9f5ff" }}
                >
                  <div
                    className="d-flex justify-content-center align-items-center position-absolute rounded-circle"
                    style={{
                      width: 36,
                      height: 36,
                      backgroundColor: "#f4ebff",
                    }}
                  >
                    <span>
                      <img
                        src={
                          require("../../../../assets/images/icons/face-neutral.svg")
                            .default
                        }
                        className="img-fluid"
                        alt=""
                        width="20"
                      />
                    </span>
                  </div>
                </div>
                <div className="card-body text-center mt-6 pt-5">
                  <h5 className="card-title -5">
                    Less confidence during negotiation
                  </h5>
                  <p className="card-text">
                    Unsure about how to confidently ask for more & professionals may remain stuck in the low-paid group longer.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md">
              <div className="card border-0 h-100">
                <div
                  className="d-flex justify-content-center align-items-center position-absolute rounded-circle top-0 start-50 translate-middle"
                  style={{ width: 48, height: 48, backgroundColor: "#f9f5ff" }}
                >
                  <div
                    className="d-flex justify-content-center align-items-center position-absolute rounded-circle"
                    style={{
                      width: 36,
                      height: 36,
                      backgroundColor: "#f4ebff",
                    }}
                  >
                    <span>
                      <img
                        src={
                          require("../../../../assets/images/icons/pie-chart.svg")
                            .default
                        }
                        className="img-fluid"
                        alt=""
                        width="20"
                      />
                    </span>
                  </div>
                </div>
                <div className="card-body text-center mt-6 pt-5">
                  <h5 className="card-title -5">
                    Less-likely to take data-driven career decision
                  </h5>
                  <p className="card-text">
                    Due to little or no awareness about the market & tech stacks, its impossible to take data-driven career decision.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="spacious-section pb-0">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <span className="badge rounded-pill mb-4">Good news</span>
              <h2 className="mb-4 mb-md-5">
                Well, lucky you. We’ve solved these problems!
              </h2>
              <p className="mb-0 lead fw-normal">
                Know your worth, have the right skills, ace the negotiation like
                a pro
              </p>
            </div>
          </div>

          <div className="row justify-content-between align-items-center my-7 py-7">
            <div className="col-12 col-md-6">
              <div
                className="d-flex justify-content-center align-items-center rounded-circle mb-6"
                style={{ width: 48, height: 48, backgroundColor: "#f9f5ff" }}
              >
                <div
                  className="d-flex justify-content-center align-items-center rounded-circle"
                  style={{ width: 36, height: 36, backgroundColor: "#f4ebff" }}
                >
                  <span>
                    <img
                      src={
                        require("../../../../assets/images/icons/user-check.svg")
                          .default
                      }
                      className="img-fluid"
                      alt=""
                      width="20"
                    />
                  </span>
                </div>
              </div>
              <h3 className="mb-4">Know your market worth</h3>
              <p className="mb-0">
                Discover if you're paid fairly among your peers & where do you
                stand in the employable workforce. You’ve spend years of
                learning & developing yourself as an IT professional and we’re
                giving you the tool for your worth check.
              </p>
            </div>
            <div className="col-12 col-md-6 position-relative">
              <div
                className="bg-light rounded-4"
                style={{ padding: 40, width: "fit-content" }}
              >
                <img
                  src={require("../../../../assets/images/home-section-3-image-1.png")}
                  className="img-fluid"
                  width="480"
                  alt=""
                />
                <img
                  src={require("../../../../assets/images/home-section-3-pattern.png")}
                  className="img-fluid position-absolute d-none d-md-block"
                  width="298"
                  alt=""
                  style={{ top: 88, right: -44 }}
                />
              </div>
            </div>
          </div>

          <div className="row justify-content-between align-items-center my-7 py-7">
            <div className="col-12 col-md-6">
              <div
                className="d-flex justify-content-center align-items-center rounded-circle mb-6"
                style={{ width: 48, height: 48, backgroundColor: "#f9f5ff" }}
              >
                <div
                  className="d-flex justify-content-center align-items-center rounded-circle"
                  style={{ width: 36, height: 36, backgroundColor: "#f4ebff" }}
                >
                  <span>
                    <img
                      src={
                        require("../../../../assets/images/icons/star.svg").default
                      }
                      className="img-fluid"
                      alt=""
                      width="20"
                    />
                  </span>
                </div>
              </div>
              <h3 className="mb-4">
                Know what skills you need to get what you deserve
              </h3>
              <p className="mb-0">
                Understand if you're getting paid enough for your skills or what
                skills are in-demand when it comes to market value & career
                growth. Get a clear picture about the steps you need to take.
              </p>
            </div>
            <div className="col-12 col-md-6 order-md-first position-relative">
              <div
                className="bg-light rounded-4"
                style={{ padding: 40, width: "fit-content" }}
              >
                <img
                  src={require("../../../../assets/images/home-section-3-image-2.png")}
                  className="img-fluid"
                  width="480"
                  alt=""
                />
                <img
                  src={require("../../../../assets/images/home-section-3-pattern.png")}
                  className="img-fluid position-absolute d-none d-md-block"
                  width="298"
                  alt=""
                  style={{ top: -88, right: -88 }}
                />
              </div>
            </div>
          </div>

          <div className="row justify-content-between align-items-center my-7 py-7">
            <div className="col-12 col-md-6">
              <div
                className="d-flex justify-content-center align-items-center rounded-circle mb-6"
                style={{ width: 48, height: 48, backgroundColor: "#f9f5ff" }}
              >
                <div
                  className="d-flex justify-content-center align-items-center rounded-circle"
                  style={{ width: 36, height: 36, backgroundColor: "#f4ebff" }}
                >
                  <span>
                    <img
                      src={
                        require("../../../../assets/images/icons/face-happy.svg")
                          .default
                      }
                      className="img-fluid"
                      alt=""
                      width="20"
                    />
                  </span>
                </div>
              </div>
              <h3 className="mb-4">Negotiate your salary confidently</h3>
              <p className="mb-0">
                When you’ve the 360 idea about where do you stand compared to your peers & professional competitors, you can negotiate your next pay more confidently.
              </p>
            </div>
            <div className="col-12 col-md-6 position-relative">
              <div
                className="bg-light rounded-4"
                style={{ padding: 40, width: "fit-content" }}
              >
                <img
                  src={require("../../../../assets/images/home-section-3-image-3.png")}
                  className="img-fluid"
                  width="480"
                  alt=""
                />
                <img
                  src={require("../../../../assets/images/home-section-3-pattern.png")}
                  className="img-fluid position-absolute d-none d-md-block"
                  width="298"
                  alt=""
                  style={{ top: 88, left: -88 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*
          Blogs part hidden
  <section className="spacious-section">
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <span className="badge rounded-pill mb-4">Stay informed</span>
          <h2 className="mb-5">Blogs</h2>
          <p className="mb-0 lead fw-normal">
            Interviews, tips, guides, industry best practices, and news.
          </p>
        </div>
      </div>
    </div>
    <div className="container pe-0 pe-md-auto">
      <div className="blog-carousel my-7 py-4 py-md-7">
        <div className="card" style={{borderColor: '#eaecf0'}}>
          <img
              src={require('../../../assets/images/home-blog-thumb-1.png')}
              className="card-img-top"
              alt="..."
          />
          <div className="card-body -6 py-7">
            <div className="mb-3">
              <a href="#" className="small fw-semibold">Android</a>
            </div>
            <div className="d-flex justify-content-between align-items-start">
              <a href="#" target="_blank" className="card-title h5">
                Android projects from Google Dev Library
              </a>
              <div className="text-end" style={{width: 40}}>
              <span>
                <img
                    src={require('../../../assets/images/icons/arrow-up-right.svg').default}
                    className="img-fluid"
                    alt=""
                    width="20"
                />
              </span>
              </div>
            </div>
            <p className="card-text">
              Android offers developers a rich set of tools and SDKs/APIs for
              building innovative and engaging mobile apps. Developers can ...
            </p>
          </div>
        </div>

        <div className="card" style={{borderColor: '#eaecf0'}}>
          <img
              src={require('../../../assets/images/home-blog-thumb-2.png')}
              className="card-img-top"
              alt="..."
          />
          <div className="card-body -6 py-7">
            <div className="mb-3">
              <a href="#" className="small fw-semibold">Privacy</a>
            </div>
            <div className="d-flex justify-content-between align-items-start">
              <a href="#" target="_blank" className="card-title h5">
                New privacy-enhancing technology for everyone
              </a>
              <div className="text-end" style={{width: 40}}>
              <span>
                <img
                    src={require('../../../assets/images/icons/arrow-up-right.svg').default}
                    className="img-fluid"
                    alt=""
                    width="20"
                />
              </span>
              </div>
            </div>
            <p className="card-text">
              Android offers developers a rich set of tools and SDKs/APIs for
              building innovative and engaging mobile apps. Developers can ...
            </p>
          </div>
        </div>

        <div className="card" style={{borderColor: '#eaecf0'}}>
          <img
              src={require('../../../assets/images/home-blog-thumb-3.png')}
              className="card-img-top"
              alt="..."
          />
          <div className="card-body -6 py-7">
            <div className="mb-3">
              <a href="#" className="small fw-semibold">API</a>
            </div>
            <div className="d-flex justify-content-between align-items-start">
              <a href="#" target="_blank" className="card-title h5">
                The Quest for REST
              </a>
              <div className="text-end" style={{width: 40}}>
              <span>
                <img
                    src={require('../../../assets/images/icons/arrow-up-right.svg').default}
                    className="img-fluid"
                    alt=""
                    width="20"
                />
              </span>
              </div>
            </div>
            <p className="card-text">
              This post focuses on listing some of the lurking issues in the
              "Glory of REST" and provides hints at ways to solve them.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <button className="btn btn-primary w-100 w-md-auto">View all posts</button>
        </div>
      </div>
    </div>
  </section>
  */}

      <section className="spacious-section bg-light">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-12 col-md-auto mb-7 pb-2 mb-md-0 pb-md-0">
              <img
                src={require("../../../../assets/images/home-testimonial-1.png")}
                className="img-fluid"
                alt=""
                width="328"
              />
            </div>
            <div className="col-12 col-md-8">
              <div className="mb-4 mb-md-6" style={{ color: "#fec84b" }}>
                <i className="bi bi-star-fill me-1"></i>
                <i className="bi bi-star-fill me-1"></i>
                <i className="bi bi-star-fill me-1"></i>
                <i className="bi bi-star-fill me-1"></i>
                <i className="bi bi-star-fill me-1"></i>
              </div>
              <div className="mb-7">
                <h2 className="fw-medium mb-0">
                  RemoteDev salary evaluation tool makes me realize where I stand in the professional arena & provides me insight so that I can take data-informed career decision.
                </h2>
              </div>
              <div>
                <p className="text-dark mb-1 fw-semibold">— Naila Haq</p>
                <p className="small text-muted mb-0">Java developer, Brac IT</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="spacious-section bg-light">
        <div className="container">
          <div className="row gy-7 gy-lg-0  justify-content-center justify-content-lg-between align-items-center">
            <div className="col-12 col-lg-6">
              <h2 className="mb-4 mb-md-6 display-3 fw-semibold">
                Thousands of IT professionals have improved their career choice already
              </h2>
              <p className="pb-4 pb-md-6">
                You can join them just by starting to evaluate your salary
              </p>
              <form>
                <div className="row g-6 g-md-5">
                  <div className="col-12 col-md-10 col-lg-7" ref={skillRef}>
                    <label htmlFor="inputSkill" className="form-label d-none">
                      Your primary skills
                    </label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={isClearable}
                      isSearchable={isSearchable}
                      name="color"
                      options={skills}
                      placeholder={<>Your Primary Skills</>}
                      onChange={handlePrimarySkill}
                      value={selectedSkills}
                    />
                    <RequiredFieldError show={skillError} />
                  </div>

                  <div className="col-12 col-md-10 col-lg-7" ref={experienceRef}>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={isClearable}
                      isSearchable={isSearchable}
                      name="experiences"
                      options={experiences}
                      placeholder={<>Years of professional experience</>}
                      onChange={handleExperience}
                      value={selectedExperiences}
                    />
                    <RequiredFieldError show={experienceError} />
                  </div>

                  <div className="col-12 col-md-10 col-lg-7">
                    <button
                      onClick={handleSubmit}
                      className="btn btn-primary w-100"
                      type="button"
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className="col-auto spacious-section pb-0 py-md-0">
              <img
                src={require("../../../../assets/images/home-cta-1.png")}
                className="img-fluid"
                alt=""
                width="576"
              />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default StaticContent;
