import mail from "../../../assets/images/icons/mail.svg";
import CandidateProfileReport from "./CandidateProfileReport";
import HiredModal from "./HiredModal";
import InfiniteScroll from "react-infinite-scroll-component";
import React, { useEffect, useRef, useState } from "react";
import Loader from "./Loader";
import SingleMessageItem from "./SingleMessageItem";
import { useMessenger } from "../../../context/useMessenger";
import {
  getChatMessages,
  saveChatMessages,
} from "../../../services/ChatService";
import io from "socket.io-client";
import SweetAlert2 from "react-sweetalert2";
import useSingleChat from "./useSingleChat";
import sendIcon from "../../../assets/images/icons/send-sm.svg"

const SingleChat = () => {
  const {
    selectedChat,
    headerName,
    jobTitle,
    messages,
    fetchMoreData,
    hasMore,
    handleSubmit,
    sendMessage,
    typingHandler,
    newMessage,
    swalProps,
    setSwalProps,
  } = useSingleChat();

  return (
    <div className="col-12 col-md-8 col-lg-9 px-0">
      {!selectedChat ? (
        <div style={{ textAlign: "center", marginTop: "25vh" }}>
          <span>
            <img src={mail} alt="" /> Select a chat or start a new conversation
          </span>
        </div>
      ) : (
        <div className="card chat chat-panel">
          <div className="px-6 py-5 chat-header">
            <div className=" d-lg-flex ">
              <div className="flex-grow-1">
                <div>
                  <a href="!#">
                    <p className=" mb-0 text-semidark fw-semibold">
                      {headerName}
                    </p>
                  </a>
                  <p className="small text-gray mb-0">{jobTitle} </p>
                </div>
              </div>
              <div className="align-self-center d-flex justify-content-between gap-3">
                <CandidateProfileReport />
                <HiredModal />
              </div>
            </div>
          </div>
          <div className="chat-user-height d-flex align-items-end">
            <div className="flex-grow-1">
              <div className="end-text">
                <div
                  id="scrollableDiv"
                  className=" p-4 p-lg-7 chat-tab flex-grow-1"
                  style={{
                    overflow: "auto",
                    display: "flex",
                    flexDirection: "column-reverse",
                  }}
                >
                  {/*Put the scroll bar always on the bottom*/}
                  {messages && messages.length > 0 ? (
                    <InfiniteScroll
                      style={{
                        display: "flex",
                        flexDirection: "column-reverse",
                      }} //To put endMessage and loader to the top.
                      inverse={true}
                      dataLength={messages.length}
                      next={fetchMoreData}
                      hasMore={hasMore}
                      loader={<Loader />}
                      endMessage={
                        <p
                          style={{
                            marginTop: "4vh",
                            textAlign: "center",
                            fontSize: "10px",
                          }}
                        >
                          Conversation Started
                        </p>
                      }
                      scrollableTarget="scrollableDiv"
                    >
                      {messages &&
                        messages.map((item: any, index: any) => {
                          return (
                            <div key={index}>
                              <SingleMessageItem messages={item} />
                            </div>
                          );
                        })}
                    </InfiniteScroll>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="px-4 px-md-7 pb-6">
                <form onSubmit={handleSubmit} onKeyDown={sendMessage}>
                  <div className="px-3 px-md-4 pb-3 pb-md-0 py-md-3 chat-text-area">
                    <div className="d-flex d-md-block gap-4">
                      <textarea
                        rows={1}
                        className="w-100 text-area border-0 chat-text-area-sm"
                        placeholder="Write your message"
                        onChange={typingHandler}
                        value={newMessage}
                      ></textarea>
                      <button
                        onClick={sendMessage}
                        className="btn bg-primary text-white d-block d-md-none"
                      >
                        <img src={sendIcon} alt="send" className="img-fluid" />
                      </button>
                    </div>
                    <p className="mb-0 text-center small text-decoration-underline d-block d-md-none pt-3">Share my detail profile</p>
                    <div className="d-none d-md-flex justify-content-end gap-2 pb-2">
                      <button className="btn whiteBtn">
                        Share my detail profile
                      </button>
                      <button
                        onClick={sendMessage}
                        className="btn bg-primary text-white"
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <SweetAlert2
        {...swalProps}
        didClose={() => {
          setSwalProps({});
        }}
      />
    </div>
  );
};

export default SingleChat;
