import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";




const NotFound = () => {
    return (
        <section className="spacious-section ">
            <div className="container">
                <div className="row justify-content-between align-items-center">
                    <div className="col-md">
                        <img
                            src={require('../../assets/images/404.png')}
                            className="img-fluid mt-6 mb-7 my-md-0"
                            alt=""
                        />
                    </div>
                    <div className="col-md order-md-first">
                        <h2 className="display-4 fw-semibold mb-6">Oops!</h2>
                        <p className="w-75 mb-7 pb-4">
                            Sorry, the page you are looking for doesn't exist or has been moved.
                            Let’s start with our homepage.
                        </p>
                        <Link to={'/'} className="btn btn-primary">Go to homepage</Link>
                    </div>
                </div>
            </div>
        </section>


    )
}

export default NotFound
