import {useEffect, useRef, useState} from "react";
import {useMessenger} from "../../../context/useMessenger";
import io from "socket.io-client";
import {getChatMessages, saveChatMessages} from "../../../services/ChatService";
import {Simulate} from "react-dom/test-utils";
import emptied = Simulate.emptied;
import {SOCKET_ENDPOINT} from "../../../utils/CommonConstants";

const useSingleChat = () => {
    let socket= useRef<any>(null);

    let selectedChatCompare = "";

    const [hasMore, setHasMore] = useState(true);
    const selectedChat = useMessenger((state:any)=>state.selectedChat);
    const selectedChatUser = useMessenger((state:any)=>state.selectedChatUser);
    const setSelectedChatUser = useMessenger((state:any)=>state.setSelectedChatUser);
    const setSelectedChat = useMessenger((state:any)=>state.setSelectedChat);
    const chats = useMessenger((state:any)=>state.chats);
    const setChats = useMessenger((state:any)=>state.setChats);
    const chatOwner = useMessenger((state:any)=>state.chatOwner);
    const [socketConnected,setSocketConnected] = useState(false);
    const selectedChatUserInfo = useMessenger((state:any)=>state.selectedChatUserInfo);
    const [messages,setMessages] = useState<any>([]);
    const [loading,setLoading] = useState(false);
    const [newMessage,setNewMessage] = useState('');
    const [currentPageNo,setCurrentPageNo] = useState(1);
    const [lastPageNo,setLastPageNo] = useState(0);
    const [swalProps, setSwalProps] = useState({});

    let headerName = selectedChatUserInfo?.opponent?.chat_box_name ?? '';
    let jobTitle = selectedChatUserInfo?.opponent?.job_title ?? '';
    let selectedChatRef = useRef();
    let socketConnectionRef = useRef<any>();
    selectedChatRef.current = selectedChat;
    socketConnectionRef.current = socketConnected;

    useEffect(()=>{
        if(chatOwner){
            connectToSocketAndJoinRoom();
            socket.current.on("connected",()=>{
                setSocketConnected(true)
                console.log("reverse connected")
            })
        }
    },[chatOwner]);

    useEffect(()=>{
        console.log("user changed");
        setHasMore(true);
        setMessages([]);
        getMessages(socket,1,[]);


        selectedChatCompare = selectedChatUser;
    },[selectedChatUser]);

    useEffect(()=>{
        //console.log(messages);
    },[messages]);

    useEffect(()=>{
        //socket gets disconnected somehow , reconnect mechanism
        if(!socketConnectionRef.current && selectedChatRef.current){
            connectToSocketAndJoinRoom();
            socket.current.on("connected",()=>{
                setSocketConnected(true)
                setSwalProps({});
            })
        }

        //For every new message received
        socket?.current?.on('message received',(newMessage:any)=>{

            //So that messages get updated only on the selected
            //chat window
            if(selectedChatRef.current==newMessage.chat_id){
                console.log("Correct SingleChat");
                let newAllMessages:any = [];
                newAllMessages = [...newAllMessages,newMessage];
                newAllMessages = newAllMessages.concat(messages);
                setMessages(newAllMessages);
            }

            //So that the last received message shows updated in the inbox
            updateLastMessageInInbox(chats,newMessage,newMessage.chat_id);

            if(!selectedChatUser || selectedChatUser != newMessage.sender){
                //send notification
                console.log("here");
            }else{
                console.log("don't here");
            }

            return false;
        });

        //If disconnected from the server
        socket?.current?.on('disconnect', function(){
            console.log("Disconnected");
            setSocketConnected(false);
            setSwalProps({
                show: true,
                icon: 'error',
                title: 'Disconnected',
                text: 'Chat is disconnected,please reload the page',
            });
        });
    });


    const connectToSocketAndJoinRoom = () => {
        //socket.current = io('https://api.remotedevs.exabyting.com',{path: '/socket_server/socket.io'});
        socket.current = io(SOCKET_ENDPOINT,{
            path: '/socket_server/socket.io',
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionDelayMax : 5000,
            reconnectionAttempts: 5})
        ;
        let userObject = {
            userId: chatOwner
        }
        socket.current.emit("setup",userObject);
    }

    const getMessages = async (socket:any,pageNo:number=1,messages:[]) => {
        if(selectedChatUser){
            setLoading(true);
            let response = await getChatMessages(selectedChatUser,pageNo);
            if(response && response.data){
                let msgData = response.data;
                //msgData.reverse();
                let newMsgData = [...messages];
                newMsgData = newMsgData.concat(msgData);
                setMessages(newMsgData);
                if(response.current_page){
                    setCurrentPageNo(response.current_page);
                }
                if(response.last_page){
                    setLastPageNo(response.last_page);
                }
            }

            socket?.current?.emit("join chat",selectedChat);
            setLoading(false);
        }
    }

    const fetchMoreData = () => {
        setTimeout(() => {
            let nextPage = currentPageNo+1;
            if(nextPage> lastPageNo){
                setHasMore(false);
            }else{
                getMessages(socket,nextPage,messages);
            }

            //setData(data.concat(Array.from({ length: 10 })));
        }, 500);

    };

    const saveMessages = async () => {
        setNewMessage('');
        let messageObject = {
            user: selectedChatUser,
            content: newMessage
        };
        let response = await saveChatMessages(messageObject);
        if(response && response.success && response.data) {
            socket?.current?.emit('new message',selectedChat,response.data,selectedChatUser,chatOwner);
            let newMessages:any = [];
            newMessages = [...newMessages,response.data];
            newMessages = newMessages.concat(messages);
            setMessages(newMessages)
            setNewMessage('');
            updateLastMessageInInbox(chats,response.data,selectedChatRef.current);
        }
    }

    const sendMessage = (event:any) => {
        if((event.key==='Enter' || event.type=="click") && newMessage) {
            saveMessages();
        }
    }

    const typingHandler = (e:any) => {
        setNewMessage(e.target.value);
    }

    const handleSubmit = (e:any) => {
        e.preventDefault();
        return false;
    }

    const updateLastMessageInInbox = (chatList:any, newChatMsg:any,chatId:any) => {
        let updatedRow:any = {};
       chatList.forEach((item:any,index:number)=>{
            if(item.chat_id==chatId){
                updatedRow = item;
                chatList.splice(index,1);
                updatedRow.last_message = newChatMsg.content;
                updatedRow.created_at = newChatMsg.created_at;

                let newChatList = [updatedRow];
                newChatList = newChatList.concat(chatList);
                setChats(newChatList);
                return false;
            }
        })


    }

    return {selectedChat,headerName,jobTitle,messages,fetchMoreData,hasMore,handleSubmit,sendMessage,typingHandler,newMessage,
        swalProps,setSwalProps};
}
export default useSingleChat;