import React from 'react'

const PaymentDetails = () => {
  return (
    <>
        <div
              className="p-3 rounded-2"
              style={{ backgroundColor: "#F2F4F7" }}
            >
              <p className="mb-0 text-dark fw-semibold pb-3">
                Bank account details
              </p>
              <div className="text-semidark">
                <p className="mb-0 text-semidark">
                  Account name: <span>RemoteDevs</span>
                </p>
                <p className="mb-0">
                  Account number: <span>XXXXXXXXYYYYY</span>
                </p>
                <p className="mb-0">
                  Bank name: <span>Bank name here</span>
                </p>
                <p className="mb-0">
                  Branch name: <span>Branch name here</span>
                </p>
                <p className="mb-0">
                  Swift Code: <span> SWIFTCODE</span>
                </p>
                <p className="mb-0">
                  Routing number: <span>888222777</span>
                </p>
              </div>
            </div>
            <p className="my-5 text-dark">
              After depositing the amount please send us the payment proof to{" "}
              <span className="border-bottom">invoices@remotedevs.com</span>
            </p>
            <p className="mb-0 text-dark">
              Please note: always put your "invoice number" as reference.
            </p>
    </>
  )
}

export default PaymentDetails
