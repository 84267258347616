import ProfileReview from "./ProfileReview";
import ProfileReject from "./ProfileReject";

type props= {
    type:string,
}
const ProfileStatusAnnouncementModal = (props:props) => {
    let type = props.type;
    return (
        <>
            {type=='under_review' ?
                <ProfileReview />
                :''
            }
            {type=='rejected' ?
                <ProfileReject />
                :''
            }


        </>
    );
}

export default ProfileStatusAnnouncementModal;