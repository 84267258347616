const SalaryInputError= (props:any)=>{
    let show: boolean = props.show;
    return (
        <>
            {show ? (
                <span className="small pt-1 error-text">4 to 6 digit number is allowed</span>
            ) : (
                ""
            )}
        </>
    );
};

export default SalaryInputError;