import check from "../../../../assets/images/icons/check-white.svg";
import React, {useState} from "react";
import {prepareAllErrorMsg} from "../../../../services/RecruiterAuthenticationService";
import {markAsHired} from "../../../../services/RecruiterService";
import {useCandidateDetails} from "../../../../context/useCandidateDetails";


const MarkAsHired = (props:any) => {
    const [isLoading,setIsLoading] = useState(false);
    let extraDetails = props?.extraDetails;
    const setShowPaymentModal = useCandidateDetails((state:any)=>state.setShowPaymentModal);
    const setIsHired = useCandidateDetails((state:any)=>state.setIsHired);

    const handleReportBeingHired = async () => {
      setIsLoading(true);
      let requestObject = {
          user: extraDetails.userId
      };
      let response = await markAsHired(requestObject);

      if(response && response.success) {
          setIsLoading(false);
          setShowPaymentModal(true);
          setIsHired(true);

      }else{
          setIsLoading(false);
          let errorMsg = prepareAllErrorMsg(response);
          extraDetails.setError(errorMsg);
      }
  }
  return (
    <>
        <button disabled={isLoading} className="btn btn-primary w-100 text-white" onClick={handleReportBeingHired}>
            <img src={check} alt="" className="img-fluid pe-2"/>
            {isLoading ? 'Processing...':'Mark as hired'}
        </button>
    </>
  )
}

export default MarkAsHired;
