import { useState } from "react";
import tick from "../../../../assets/images/icons/check-circle-green.svg";
import cross from "../../../../assets/images/icons/x-close-green.svg";

const ReportSuccessAlert = (props:any) => {
  let detailsFunctionalities = props.detailsFunctionalities;



  const handleClick = () => {
    detailsFunctionalities.handleHideReportModal();
  };

  return (
    <>
      {detailsFunctionalities.showReportModal && (
        <div className="pt-4">
          <div className="success-box p-4 d-flex gap-3">
            <img src={tick} alt="" className="img-fluid" />
            <p className="mb-0 success-text">
              {" "}
              Your report has been submitted successfully
            </p>
            <img
              src={cross}
              alt=""
              className="img-fluid cursor"
              onClick={handleClick}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ReportSuccessAlert;
