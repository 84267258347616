import {useNavigate, useSearchParams} from "react-router-dom";
import {resetPasswordVerify, signUp as recruiterSignUp, verify} from "../../../services/RecruiterAuthenticationService";
import {useAuthContext} from "../../../context/useAuthContext";
import {RECRUITER_PROFILE, RECRUITER_RESET_PASSWORD} from "../../../utils/RouteNamingConfig";
import {CommonErrorMsg} from "../../../utils/CommonConstants";
import {useEffect, useState} from "react";


type VerificationObject = {
    xde:string|null,
    udev:string|null
}

const VerificationResetPassword = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [error, setError] = useState('');
    let udev = searchParams.get("udev")
    let xde = searchParams.get("xde")
    let verificationObj:VerificationObject = {xde:xde,udev:udev};
    const {dispatch}:any= useAuthContext();

    useEffect(()=>{
        if(udev && xde){
            process(verificationObj);
        }else{
            setError('Invalid!'+CommonErrorMsg)
        }


    },[])

    const process = async (verificationObj:VerificationObject) => {
        setError('');


        let updatedSearchParams = new URLSearchParams('');
        setSearchParams(updatedSearchParams.toString());
        let  response = await resetPasswordVerify(verificationObj);
        if(response && response.success){
            sessionStorage.clear();
            localStorage.clear();
            sessionStorage.setItem('verificationObject',JSON.stringify(verificationObj))
            navigate(RECRUITER_RESET_PASSWORD);
        }else{
            setError(response.message ?? CommonErrorMsg)
        }

    }
    return (
        <>
            <div style={{textAlign:"center",marginTop:"30vh"}}>

                {(error && error.length>0) ?
                    <>
                        {error}
                    </>
                    :

                    <>
                        <img
                        style={{ marginRight: 6 }}
                        src={require("../../../assets/images/icons/loading.png")}
                        className="spinner"
                        width="28"
                        alt=""
                    /> <p>Verifying, please wait..</p>
                    </>
                }


            </div>
        </>
    );
}
export default VerificationResetPassword;