export class Skill {
    id!:number;
    name!:string;
    primary:string = "Yes";
    experience!:string;
    currentlyWorkingAt:boolean = false;
    currently_working!:string;
    isDisabled:boolean = false;
    value?:number;
    maxYearsToShow:any=null;
}
