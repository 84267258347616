export class Recruiter {
    id:number = 0;
    name:string='';
    jobTitle:string='';
    countryId:string='';
    linkedinProfile:string='';
    mobileNumber:string='';
    whatsappNumber:string='';
    sameAsMobile:boolean=false;
    companyName:string = "";
    companySite:string = "";
    companyCountry:string=""
    companyFoundingYear:number=0;
    companyNumberOfEmployees:string=""
    companyType:string=""
    companyDescription:string=""
    companyOpportunityDescription:string=""
}
