import AxiosService from "./AxiosService";
import {ApiBaseUrl} from "../utils/CommonConstants";
import {ApiEndPointService} from "./ApiEndPointService";

export async function getInbox(pageNumber:any=1){
    let url = ApiBaseUrl+ApiEndPointService.INBOX;
    if(pageNumber>1){
        url+='?page='+pageNumber;
    }

    return AxiosService.get(url,[],'secure');
}

export async function saveInbox(userObject:any){
    return AxiosService.post(ApiBaseUrl+ApiEndPointService.INBOX,userObject,{});
}

export async function getChatMessages(userId:any,pageNo:number){
    let url = ApiBaseUrl+ApiEndPointService.MESSAGES +'?user='+userId;
    if(pageNo>1){
        url+="&page="+pageNo;
    }
    return AxiosService.get(url,[],'secure');
}

export async function saveChatMessages(userObject:any){
    return AxiosService.post(ApiBaseUrl+ApiEndPointService.MESSAGES,userObject,{});
}