import React, { useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "./Loader";
import { Link } from "react-router-dom";
import { INBOX_PANEL } from "../../../utils/RouteNamingConfig";
import SingleInboxItem from "./SingleInboxItem";
import {useMessenger} from "../../../context/useMessenger";
import {createGunzip} from "zlib";

const MyChatList = (props:any) => {
  let extraInboxData = props.extraInboxData;
  const [data, setData] = useState(Array.from({ length: 10 }));
  const [hasMore, setHasMore] = useState(true);
  const chats = useMessenger((state:any)=>state.chats);
  const totalChats = useMessenger((state:any)=>state.totalChats);
  const isInboxLoading = useMessenger((state:any)=>state.isInboxLoading);
  const setSelectedChatUser = useMessenger((state:any)=>state.setSelectedChatUser);
  const setSelectedChat = useMessenger((state:any)=>state.setSelectedChat);
  const chatOwner = useMessenger((state:any)=>state.chatOwner);
  const setChatOwner = useMessenger((state:any)=>state.setChatOwner);
  const setSelectedChatUserInfo = useMessenger((state:any)=>state.setSelectedChatUserInfo);

  let showChatListRef = useRef<any>() 

  const fetchMoreData = () => {
      setTimeout(() => {
        let nextPage = extraInboxData.currentChatListPageNo+1;
        if(nextPage> extraInboxData.lastChatListPageNo){

        }else{
          extraInboxData.handleNextPage(nextPage);
        }
      }, 500);
  };

  const handleSelectInbox = (item:any) => {
    showChatListRef.current.className="col-12 col-md-4 col-lg-3 mb-3 px-0 border-end d-none d-md-block"
    let chatId = item.chat_id;
    let opponentUserId = item.opponent.user_id;
    setSelectedChat(chatId);
    setSelectedChatUser(opponentUserId);
    setSelectedChatUserInfo(item);

  }
  return (
    <div ref={showChatListRef} className="col-12 col-md-4 col-lg-3 mb-3 px-0 border-end">
      <div className="card chat chat-panel">
        <div className="px-4 px-md-3 px-lg-4 py-5" style={{ height: "125px" }}>
          <div className=" d-flex justify-content-between pb-3">
            <div className="d-flex gap-2">
              <p
                className="mb-0 fw-bold"
                style={{ fontSize: "18px", color: "#101828" }}
              >
                Total
              </p>
              <span className="badge-coustom ">{totalChats}</span>
            </div>
            <div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                />
                <label
                  className="form-check-label"
                  htmlFor="flexSwitchCheckDefault"
                >
                  Unread only
                </label>
              </div>
            </div>
          </div>
          <form>
            <input
              type="text"
              className="chat-input search form-control"
              id="search"
              autoComplete="off"
              placeholder="Search"
            />
          </form>
        </div>
        <div className="chat-user-height">
          <div className="flex-grow-1">
            {isInboxLoading ?
                (<Loader/>):
            <div id="thisDiv" className="chat-inbox">
              {chats && chats.length>0 ?
              <InfiniteScroll
                dataLength={chats.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<Loader />}
                endMessage={<p>No More data</p>}
                scrollableTarget="thisDiv"
              >
                {chats && chats.map((item:any, index:any) => {
                  return (
                      <div key={index} onClick={()=>handleSelectInbox(item)}>
                        <SingleInboxItem item={item}/>
                        {/*<Link to={INBOX_PANEL}>

                        </Link>*/}
                      </div>

                  );
                })}
              </InfiniteScroll>
                  :''
              }
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyChatList;
