
import { useAnalytics } from '../../utils/useAnalytics'


const TermsAndConditions = () => {
  useAnalytics()
  return (
      <div className="container spacious-section pt-6">
        <div className="terms-conditions-holder">
          <h4 className="h3 fw-semibold mb-4 mb-md-6">
            Terms & conditions for candidates
          </h4>
          <p className="mb-6">REMOTEDEVS.COM TERMS AND CONDITIONS FOR CANDIDATES</p>
          <section className="mb-7 pb-5">
            <h6 className="text-primary mb-6">Main rules:</h6>
            <div className="main-section d-flex mb-4">
              <p className="mb-0">
          <span className="fw-semibold">
            You promise to upload only the correct information
          </span>
                <br/>
                <span>
            By registering your profile and sharing the information you ensure
            that all the information you have provided is correct.
          </span>
              </p>
            </div>
            <div className="main-section d-flex mb-4">
              <div>
                <p className="fw-semibold mb-0">
                  You promise to behave correctly and professionally:
                </p>
                <p className="sub-section mb-0">
                  Only polite and friendly communication. No disrespect, swearing, or
                  threatening.
                </p>
                <p className="sub-section mb-0">
                  No spam. You will not use this platform for uses other than finding
                  yourself a job offer.
                </p>
                <p className="sub-section mb-0">
                  Confidentiality. Things that recruiters tell you might be confidential. Do not share it anywhere.
                </p>
              </div>
            </div>
            <div className="main-section d-flex mb-4">
              <div>
                <p className="fw-semibold mb-0">
                  You agree to be contacted by us and/or recruiters regarding job
                  opportunities.
                </p>
              </div>
            </div>
            <div className="main-section d-flex mb-4">
              <div>
                <p className="mb-0">
            <span className="fw-semibold">
              You promise to report to us if you have been hired via our
              platform.
            </span>
                  <br/>
                  <span>
              Please let us know if you have accepted an offer from the company
              after communicating via RemoteDevs.com no later than 1 day after
              signing the offer or reaching an agreement.
            </span>
                </p>
              </div>
            </div>
            <p className="mb-0">
              If you do not follow these rules, you might be blocked from using our
              services.
            </p>
          </section>
          <h6 className="text-primary mb-4">Long list:</h6>
          <section className="mb-7 pb-5">
            <div>
              <div className="main-section d-flex mb-4">
                <div>
                  <p className="mb-0">
                    Our website provides salary evaluations based on the information
                    you provide. These salary evaluations are for informational
                    purposes only. We do not guarantee the accuracy or completeness of
                    the salary evaluations, and we are not responsible for any
                    decisions made by recruiters based on the salary evaluations.
                  </p>
                </div>
              </div>
              <div className="main-section d-flex mb-4">
                <div>
                  <p className="mb-0">
                    Recruiters may contact you regarding job offers based on the
                    information you provide. We do not guarantee the availability or
                    suitability of any job offers, and we are not responsible for any
                    decisions made by you or recruiters regarding job offers. Users
                    are responsible for verifying the accuracy and completeness of the
                    job offers before accepting any employment agreement.
                  </p>
                </div>
              </div>
              <div className="main-section d-flex mb-4">
                <div>
                  <p className="mb-0">
                    <span className="fw-semibold">Intellectual Property</span>
                    <br/>
                    <span>
                All content on this website, including but not limited to text,
                graphics, logos, images, and software, is the property of UAB
                “Tylas” or its content suppliers and is protected by
                international copyright laws. You are not permitted to use any
                content from this website without our prior written
                consent.</span
                    >
                  </p>
                </div>
              </div>
              <div className="main-section d-flex mb-4">
                <div>
                  <p className="fw-semibold mb-0">User Conduct</p>
                  <p className="sub-section mb-0">
                    You agree not to use this website for any purpose that is unlawful
                    or prohibited by these terms and conditions. You may not use this
                    website in any manner that could damage, disable, overburden, or
                    impair our servers or networks, or interfere with any other
                    party's use and enjoyment of this website.
                  </p>
                  <p className="sub-section mb-0">
                    You agree not to use this website to transmit, distribute, or
                    store material that is defamatory, obscene, threatening, or
                    infringing of any third party's intellectual property rights.
                  </p>
                  <p className="sub-section mb-0">
                    You agree that you will not use any automated means, including
                    bots, spiders, or crawlers, to access or extract data from our
                    website. You are also prohibited from using the data obtained
                    through scraping for any commercial purposes or to create
                    derivative works based on the scraped data. We reserve the right
                    to take legal action against any user who violates this
                    prohibition, including seeking injunctive relief, damages, or
                    other remedies available at law or in equity.
                  </p>
                </div>
              </div>
              <div className="main-section d-flex mb-4">
                <div>
                  <p className="fw-semibold mb-0">
                    Privacy and Internal Communication Monitoring
                  </p>
                  <p className="sub-section mb-0">
                    Our Privacy Policy explains how we collect, use, and protect your
                    personal information. By using this website, you consent to the
                    collection and use of your personal information as described in
                    our Privacy Policy.
                  </p>
                  <p className="sub-section mb-0">
                    You acknowledge and agree that we may monitor and review any
                    communications that take place on our site, including messages
                    between users, exclusively to ensure compliance with our terms and
                    conditions and to protect the security and integrity of our site.
                    By using our site, you consent to such monitoring and review.
                  </p>
                </div>
              </div>
              <div className="main-section d-flex mb-4">
                <div>
                  <p className="fw-semibold mb-0">
                    Dispute Resolution
                  </p>
                  <p className="mb-0">
                    Any disputes arising from your use of this website will be
                    governed by Lithuanian law. Any dispute will be resolved according
                    to Lithuanian Law.
                  </p>
                </div>
              </div>
              <div className="main-section d-flex mb-4">
                <div>
                  <p className="mb-0">
                    <span className="fw-semibold">Liability</span>
                    <br/>
                    <span>
                We will not be liable for any damages of any kind arising from
                the use of this website, including but not limited to direct,
                indirect, incidental, punitive, and consequential damages.
              </span>
                  </p>
                </div>
              </div>
              <div className="main-section d-flex mb-4">
                <div>
                  <p className="mb-0">
                    <span className="fw-semibold">Termination</span>
                    <br/>
                    <span>
                We reserve the right to terminate your access to this website at
                any time, without notice, for any reason whatsoever.
              </span>
                  </p>
                </div>
              </div>
              <div className="main-section d-flex mb-4">
                <div>
                  <p className="mb-0">
              <span className="fw-semibold">
                Changes to these Terms and Conditions
              </span>
                    <br/>
                    <span>
                We reserve the right to update or modify these terms and
                conditions at any time without prior notice. Your continued use
                of this website following any such changes constitutes your
                acceptance of the new terms and conditions.
              </span>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <p className="mb-0">Thank you for using our website.</p>
        </div>
      </div>


  )
}

export default TermsAndConditions
