import { Outlet } from "react-router-dom";
import RecruiterHeader from "./RecruiterHeader";

const RecruiterBaseLayout = () => {
  return (
    <>
      <RecruiterHeader />
      <div className="main">
        <Outlet />
      </div>
    </>
  );
};

export default RecruiterBaseLayout;
