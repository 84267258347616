import { ExperienceList } from '../types/ExperienceList';
import { Skill } from '../types/Skill';
import {User} from "../types/User";
import {EnglishLevel} from "../types/EnglishLevel";

const ALL_SKILLS = "all_skills";
const SKILLS_MAX_YEAR = "skills_max_year";
const ALL_ENGLISH_LEVELS = "all_english_levels";
const ALL_EXTRA_SKILLS = "all_extra_skills";
const ALL_WORK_TYPES = "all_work_types";
const SELECTED_SKILLS = "selected_skills";
const SALARY_DETAILS = "other-details";
const SELECTED_EXPERERINCE = "selected_experience";
const SELECTED_CURRENTLY_WORKING = "currently_working";
const SELECTED_ENGLISH_LEVEL = "selected_english_level"
const SELECTED_WORK_TYPE = "selected_work_type"
const CURRENT_SALARY = "current_salary"
const SELECTED_FILTER_DATA = "selected_filter_data"


export class AppStorageService {

  constructor() { }

 disabledSkillOption(skillsOptions:Skill[]){

    let selectedSkill:any = localStorage.getItem(SELECTED_SKILLS);
    if(selectedSkill && selectedSkill.length>0){
      selectedSkill = JSON.parse(selectedSkill);
    }else{
      return [...skillsOptions];
    }
    for (let index = 0; index < skillsOptions.length; index++) {
      let numberOfSelectedItem = selectedSkill.length;
      let tmp = skillsOptions[index];
      let flag:boolean = true;
      selectedSkill.forEach((element:any, i:any) => {
        if(element.value == skillsOptions[index].value){
          tmp.isDisabled = true;
          skillsOptions[index] = tmp;
          numberOfSelectedItem--;
          flag = false;
        }else if(flag){
          tmp.isDisabled = false;
          skillsOptions[index] = tmp;
        }
      });

    }
    return [...skillsOptions];
  }

  disabledSkillOptionWithSpecificSkills(skillsOptions:Skill[],selectedSkills:Skill[]){

        //let selectedSkill:any = localStorage.getItem(SELECTED_SKILLS);
        if(selectedSkills && selectedSkills.length>0){

        }else{
            return [...skillsOptions];
        }
        for (let index = 0; index < skillsOptions.length; index++) {
            let numberOfSelectedItem = selectedSkills.length;
            let tmp = skillsOptions[index];
            let flag:boolean = true;
            selectedSkills.forEach((element:any, i:any) => {
                if(element!=null){
                    if(element.value == skillsOptions[index].value){
                        tmp.isDisabled = true;
                        skillsOptions[index] = tmp;
                        numberOfSelectedItem--;
                        flag = false;
                    }else if(flag){
                        tmp.isDisabled = false;
                        skillsOptions[index] = tmp;
                    }
                }

            });

        }
        return [...skillsOptions];
    }

    storeAllSkills(skills: Skill[]): void {
        sessionStorage.removeItem(ALL_SKILLS);
        sessionStorage.setItem(ALL_SKILLS, JSON.stringify(skills));
    }

    getAllSkills(): Skill[] {
        let skillTmp:any = sessionStorage.getItem(ALL_SKILLS);
        if(skillTmp == null || skillTmp == "undefined"){
            return [];
        }
        return JSON.parse(skillTmp);
    }



  clearAllSkillsDetailsAll(){
    localStorage.removeItem(ALL_SKILLS);
  }

  storeSelectedSkills(skills: Skill[]): void {
    localStorage.removeItem(SELECTED_SKILLS);
    localStorage.setItem(SELECTED_SKILLS, JSON.stringify(skills));
  }

  clearSelectedSkills(): void {
    localStorage.removeItem(SELECTED_SKILLS);
  }

  getSelectedSkills(): Skill[] {
    let skillTmp:any = localStorage.getItem(SELECTED_SKILLS);
    if(skillTmp == null || skillTmp == "undefined"){
      return [];
    }
    return JSON.parse(skillTmp);
  }

    setSalaryReport(data:any): void {
        localStorage.removeItem('salary-report');
        localStorage.setItem('salary-report', JSON.stringify(data));
    }

    getSalaryReport(): any {
        let tmp:any = localStorage.getItem('salary-report');
        if(tmp == null || tmp == "undefined"){
            return null;
        }
        return JSON.parse(tmp);
    }


    setToken(token:string): void {
        localStorage.removeItem('token');
        localStorage.setItem('token', JSON.stringify(token));
    }

    getToken(): string {
        let tmp:any = localStorage.getItem('token');
        if(tmp == null || tmp == "undefined"){
            return "";
        }
        return JSON.parse(tmp);
    }



  storeSelectedExperience(experineces: ExperienceList[]): void {
    localStorage.removeItem(SELECTED_EXPERERINCE);
    localStorage.setItem(SELECTED_EXPERERINCE, JSON.stringify(experineces));
  }

  clearCurrentlyWorking(){
    localStorage.removeItem(SELECTED_CURRENTLY_WORKING);
  }

  storeCurrentlyWorking(index:any,status:any): void {
    let expTmp: any = localStorage.getItem(SELECTED_CURRENTLY_WORKING);
    expTmp = JSON.parse(expTmp)
    let arr = [];
    if(expTmp){
      arr = expTmp;
    }
    arr[index] = status;
    localStorage.setItem(SELECTED_CURRENTLY_WORKING, JSON.stringify(arr));
  }

  storeWholeCurrentlyWorking(status:any): void {
      localStorage.removeItem(SELECTED_CURRENTLY_WORKING);
      localStorage.setItem(SELECTED_CURRENTLY_WORKING, JSON.stringify(status));
    }

  getCurrentlyWorking(){
    let skillTmp:any = localStorage.getItem(SELECTED_CURRENTLY_WORKING);
    if(skillTmp == null || skillTmp == "undefined"){
      return [];
    }
    return JSON.parse(skillTmp);
  }

  getSelectedExperience(): ExperienceList[] {
    let expTmp:any = localStorage.getItem(SELECTED_EXPERERINCE);
    if(expTmp == null || expTmp == "undefined"){
      return [];
    }
    return JSON.parse(expTmp);
  }

  clearSelectedExperience(): void {
    localStorage.removeItem(SELECTED_EXPERERINCE);
  }

  clearSpecificSelectedExperience(index:any): void {
    let expTmp: any = localStorage.getItem(SELECTED_EXPERERINCE);

    if (expTmp[index]) {
      expTmp[index].experience = null;
    }
  }


  clearSpecificSelectedSkills(index:any): void{
    let expTmp: any = localStorage.getItem(SELECTED_SKILLS);

    if (expTmp[index]) {
      expTmp[index].skill = null;
    }

  }

  clearSpecificSelectedSRow(index:any): void{
    let expTmp: any = localStorage.getItem(SELECTED_EXPERERINCE);
    expTmp = JSON.parse(expTmp)
      if(expTmp && expTmp.length>0){
          expTmp.splice(index,1);
          this.storeSelectedExperience(expTmp)
      }


    let skillTmp: any = localStorage.getItem(SELECTED_SKILLS);
    skillTmp = JSON.parse(skillTmp)
      if(skillTmp && skillTmp.length>0){
          skillTmp.splice(index,1);
          this.storeSelectedSkills(skillTmp);
      }


    let currentlyWorking: any = localStorage.getItem(SELECTED_CURRENTLY_WORKING);
    currentlyWorking = JSON.parse(currentlyWorking);
    if(currentlyWorking && currentlyWorking.length>0){
        currentlyWorking.splice(index,1);
        this.storeWholeCurrentlyWorking(currentlyWorking)
    }

  }

  loginWelcomeMessage(){
    let status:any = localStorage.getItem("loginWelcomeMessage")
    return JSON.parse(status);
  }
  setLoginWelcomeMessage(status:boolean){
    localStorage.setItem("loginWelcomeMessage", JSON.stringify(status));
  }

  setReload(status:boolean): void {
    sessionStorage.removeItem('reload');
    sessionStorage.setItem('reload', JSON.stringify(status));
  }

  reload(): boolean {
    let tmp:any = sessionStorage.getItem('reload');
    if(tmp == null || tmp == "undefined"){
      return false;
    }
    return JSON.parse(tmp);
  }

  setGoogleToken(token:string): void {
    sessionStorage.removeItem('google-token');
    sessionStorage.setItem('google-token', JSON.stringify(token));
  }

  getGoogleToken(): string {
    let tmp:any = sessionStorage.getItem('google-token');
    if(tmp == null || tmp == "undefined"){
      return "";
    }
    return JSON.parse(tmp);
  }

  storeUserDetails(user:User){
    localStorage.removeItem("user-details");
    localStorage.setItem("user-details", JSON.stringify(user));
  }

  getUserDetails(){
    let user:any = localStorage.getItem("user-details");
    if(user == null || user == "undefined"){
      return new User();
    }
    return JSON.parse(user);
  }

  userLoggedIn(){
    let status:any = localStorage.getItem("loggedin-status")
    return JSON.parse(status);
  }

  setUserLoggedIn(status:boolean){
    localStorage.setItem("loggedin-status", JSON.stringify(status));
  }


    storeAllEnglishLevels(values: any): void {
        sessionStorage.removeItem(ALL_ENGLISH_LEVELS);
        sessionStorage.setItem(ALL_ENGLISH_LEVELS, JSON.stringify(values));
    }

    getAllEnglishLevels(): any {
        let tmp:any = sessionStorage.getItem(ALL_ENGLISH_LEVELS);
        if(tmp == null || tmp == "undefined"){
            return null;
        }
        return JSON.parse(tmp);
    }

    storeAllExtraSkills(values: any): void {
        sessionStorage.removeItem(ALL_EXTRA_SKILLS);
        sessionStorage.setItem(ALL_EXTRA_SKILLS, JSON.stringify(values));
    }

    storeAllSelectedFilterData(values: any): void {
        sessionStorage.removeItem(SELECTED_FILTER_DATA);
        sessionStorage.setItem(SELECTED_FILTER_DATA, JSON.stringify(values));
    }

    getAllSelectedFilterData(): any {
        let tmp:any = sessionStorage.getItem(SELECTED_FILTER_DATA);
        if(tmp == null || tmp == "undefined"){
            return null;
        }
        return JSON.parse(tmp);
    }

    clearAllSelectedFilterData(): void {
        sessionStorage.removeItem(SELECTED_FILTER_DATA);
    }

    getAllExtraSkills(): any {
        let tmp:any = sessionStorage.getItem(ALL_EXTRA_SKILLS);
        if(tmp == null || tmp == "undefined"){
            return null;
        }
        return JSON.parse(tmp);
    }

    storeSelectedEnglishLevel(values: any): void {
        localStorage.removeItem(SELECTED_ENGLISH_LEVEL);
        localStorage.setItem(SELECTED_ENGLISH_LEVEL, JSON.stringify(values));
    }

    clearSelectedEnglishLevel(): void {
        localStorage.removeItem(SELECTED_ENGLISH_LEVEL);
    }

    getSelectedEnglishLevel(): any[] {
        let tmp:any = localStorage.getItem(SELECTED_ENGLISH_LEVEL);
        if(tmp == null || tmp == "undefined"){
            return [];
        }
        return JSON.parse(tmp);
    }


    storeAllWorkTypes(values: any): void {
        sessionStorage.removeItem(ALL_WORK_TYPES);
        sessionStorage.setItem(ALL_WORK_TYPES, JSON.stringify(values));
    }

    getAllWorkTypes(): any {
        let tmp:any = sessionStorage.getItem(ALL_WORK_TYPES);
        if(tmp == null || tmp == "undefined"){
            return null;
        }
        return JSON.parse(tmp);
    }

    storeSelectedWorkType(values: any): void {
        localStorage.removeItem(SELECTED_WORK_TYPE);
        localStorage.setItem(SELECTED_WORK_TYPE, JSON.stringify(values));
    }

    clearSelectedWorkType(): void {
        localStorage.removeItem(SELECTED_WORK_TYPE);
    }

    getSelectedWorkType(): any[] {
        let tmp:any = localStorage.getItem(SELECTED_WORK_TYPE);
        if(tmp == null || tmp == "undefined"){
            return [];
        }
        return JSON.parse(tmp);
    }

    storeCurrentSalary(values: any): void {
        localStorage.removeItem(CURRENT_SALARY);
        localStorage.setItem(CURRENT_SALARY, JSON.stringify(values));
    }

    getCurrentSalary():any {
        let tmp:any = localStorage.getItem(CURRENT_SALARY);
        if(tmp == null || tmp == "undefined"){
            return '';
        }
        return JSON.parse(tmp);

    }

    setFeedBackSubmitted(value:boolean): void {
        localStorage.removeItem('feedback-submit-status');
        localStorage.setItem('feedback-submit-status', JSON.stringify(value));
    }

    getFeedBackSubmitted(): boolean {
        let tmp:any = localStorage.getItem('feedback-submit-status');
        if(tmp == null || tmp == "undefined"){
            return false;
        }
        return JSON.parse(tmp);
    }

    storeSkillToMaxYearMapping(skills:any){
      let response:any = [];
        if(skills.length>0){
            skills.map((item:any)=>{
                response[item.id] = item.max_years_to_show;
            })
        }
        sessionStorage.removeItem(SKILLS_MAX_YEAR);
        sessionStorage.setItem(SKILLS_MAX_YEAR, JSON.stringify(response));
    }

    getSkillToMaxYearMapping(): any{
        let skillTmp:any = sessionStorage.getItem(SKILLS_MAX_YEAR);
        if(skillTmp == null || skillTmp == "undefined"){
            return [];
        }
        return JSON.parse(skillTmp);
    }

}
