import { Link } from "react-router-dom";
import backArrow from "../../../assets/images/icons/arrow-left.svg";
import mobileLogo from "../../../assets/images/icons/logo-for-signup.svg";
import {RECRUITER_SIGN_IN} from "../../../utils/RouteNamingConfig";
import {useState} from "react";
import useForgotPassword from "./useForgotPassword";
import InputFieldErrorMessage from "../../../components/errors/InputFieldErrorMessage";
import DisableEnableButton from "../../../components/buttons/DisableEnableButton";


const ForgetPassword = () => {
    const [email,setEmail] = useState('');
    const {error,setError,emailError,setEmailError,isLoading,setIsLoading,validateEmail,requestResetPassword}
    = useForgotPassword();

    const handleSubmit = async (e:any) =>{
        e.preventDefault();
        setError(null);
        setEmailError(null);

        let isValidEmail = validateEmail(email);

        if(isValidEmail)
        {
            await requestResetPassword(email);
        }
    }
    return (
        <div className="col-lg-6">
                        <div className="recruiter-spacious-section position-relative h-100 d-flex align-items-lg-center justify-content-center">
                            <div className="position-absolute start-0 recruiter-sign-up-back-button">
                                <Link to={RECRUITER_SIGN_IN}>
                                    <img src={backArrow} className="img-fluid" alt="back arrow" />
                                    <span className="small text-secondary ps-3"> Go back</span>
                                </Link>
                            </div>
                            <div>
                                <div className="row align-items-center justify-content-center ">
                                    <div className="col-md-7 col-12 px-0">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="text-center">
                                                    <img
                                                        src={mobileLogo}
                                                        className="img-fluid d-md-none py-6 "
                                                        alt=""
                                                    />
                                                    <h2 className="display-5 fw-semibold text-dark pb-2 pb-md-3">
                                                        Forgot password?{" "}
                                                    </h2>
                                                    <p className="text-secondary mb-0">
                                                        Relax, we’ll send you reset instructions
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <form onSubmit={handleSubmit}>
                                                    <div className="row gy-4 gy-md-6 mx-0 pt-7">
                                                        <div className="col-12">
                                                            <label htmlFor="" className="form-label">
                                                                Email
                                                            </label>
                                                            <input
                                                                type="email"
                                                                className={(emailError && emailError?.length>0) ? "form-control  error-input":"form-control"}
                                                                placeholder="Enter your email"
                                                                onChange={(e)=>setEmail(e.target.value)}
                                                                value={email}
                                                            />
                                                            <InputFieldErrorMessage error={emailError}/>
                                                        </div>


                                                        <div className="col-12 " style={{textAlign:"center"}}>
                                                            <DisableEnableButton disable={isLoading} text="Reset password" />
                                                            {(error && error.length>0) ? <InputFieldErrorMessage error={error} /> : ""}
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    );
};

export default ForgetPassword;