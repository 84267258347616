import {useState} from "react";
import {
    InvalidEmailDomainErrorMsg,
    InvalidEmailDomainList,
    InvalidEmailErrorMsg,
    RequiredFieldCommonErrorMsg
} from "../../../utils/CommonConstants";
import validator from "validator";
import {
    passwordResetRequest,
    prepareErrorMsg,
    signUp as recruiterSignUp
} from "../../../services/RecruiterAuthenticationService";
import {RECRUITER_POST_FORGOT_PASSWORD, RECRUITER_POST_SIGN_UP} from "../../../utils/RouteNamingConfig";
import {useNavigate} from "react-router-dom";

type PasswordResetObject = {
    email: string|null
}
const useForgotPassword = () => {
    const [error,setError] = useState<string|null>(null);
    const [emailError,setEmailError] = useState<string|null>(null);
    const [isLoading,setIsLoading] = useState<boolean | null>(null);
    let navigate = useNavigate();

    const validateEmail = (email:string) =>{
        if(!email || email==''){
            setEmailError(RequiredFieldCommonErrorMsg);
            return false;
        }
        if(!validator.isEmail(email)){
            setEmailError(InvalidEmailErrorMsg);
            return false;
        }
        let emailArr = email.toLocaleLowerCase().split('@');
        if(emailArr[1].length>0){
            let res = true;
            let emailArrSecondStep = emailArr[1].split('.');
            emailArrSecondStep.forEach((item)=>{
                if(InvalidEmailDomainList.includes(item)){
                    setEmailError(InvalidEmailDomainErrorMsg);
                    res = false;
                }
            })

            return res;
        }else{
            setEmailError(InvalidEmailErrorMsg);
            return false;
        }

        return true;

    }
    const requestResetPassword = async (email:string) => {
        setIsLoading(true);
        setError(null);

        let resetData:PasswordResetObject = {
            email: email,
        };

        const response = await passwordResetRequest(resetData);
        if(response && response.success){
            sessionStorage.setItem('password_reset_email',email);
            setIsLoading(false);
            navigate(RECRUITER_POST_FORGOT_PASSWORD);
        }else{
            setIsLoading(false);
            let errorText = prepareErrorMsg(response);
            setError(errorText);
        }
    };

    return {error,setError,emailError,setEmailError,isLoading,setIsLoading,validateEmail,requestResetPassword};
}
export default useForgotPassword;