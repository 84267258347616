import Modal from "react-bootstrap/Modal";
import {useEffect, useState} from "react";
import FilterOption from "./FilterOption";
import {AppStorageService} from "../../../../services/AppStorageService";

const FilterDataForMobile = (props:any) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let filterObject = props.filterObject;
  let isFilterDataLoading = filterObject.isFilterDataLoading;

  const resetFilter = () => {
    //let storage = new AppStorageService();
    //storage.clearAllSelectedFilterData();
    //window.location.reload();
    filterObject.resetFilter();
    filterObject.handleNextPage();
  }

  useEffect(()=>{
    if(isFilterDataLoading){
      setShow(false);
    }
  },[isFilterDataLoading])
  return (
    <>
      <div className="d-block d-lg-none pt-6" >
        <div>
          <button onClick={handleShow} className="w-75 btn border">
            <i className="bi bi-funnel" />{" "}
            <span className="small fw-bold">Filters</span>{" "}
          </button>
          <span onClick={resetFilter} className="small fw-bold ps-4">Reset</span>
        </div>
        {/* <div>
        <button className="w-75 btn border">
          <i className="bi bi-funnel-fill" />{" "}
          <span className="small fw-bold">Filters</span>{" "}
          <span className="small fw-bold">(3)</span>
        </button>
        <span className="small fw-bold ps-4">Reset</span>
      </div> */}
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="pb-0" closeButton>
          <div>
          <p
            className="mb-0 fw-semibold text-dark pb-1"
            style={{ fontSize: "20px" }}
          >
            Filters
          </p>
          <p className="mb-0 text-secondary">Apply filters to search results</p>
          </div>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <FilterOption filterObject={filterObject} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FilterDataForMobile;
