import CustomTooltip from "../tooltip/CustomTooltip";

type props= {
    type:string,
}
const RecruiterStatusHeadingInProfilePage = (props:props) => {
    let type = props.type;
    return (
        <>
            {type=='new' ?
                <CustomTooltip
                btnText={"New"}
                tooltipText={
                  "You can search the database and mark favorite candidates. To reach out to them please fill in your profile fully and hit Save and Submit for verification"
                }
                className={"small fw-semibold px-3 py-1 incompleteProfile d-inline-block d-md-none mb-1"}
                placement={"bottom"}
              />
            :''
            }
            {type=='under_review' ?
                <span className="small fw-semibold px-3 py-1 profile-review d-inline-block d-md-none mb-1">
                  Under Review
                </span>
                :''
            }
            {type=='rejected' ?
                <span className="small fw-semibold px-3 py-1 profile-reject d-inline-block d-md-none mb-1">
                  Rejected
                </span>
                :''
            }

        </>
    );
}

export default RecruiterStatusHeadingInProfilePage;