import SavedCandidate from "./SavedCandidate";
import {useEffect} from "react";
import NoSavedCandidateFound from "./NoSavedCandidateFound";

const SavedCandidateWrapper = (props:any) => {
    let candidateObject = props.candidateObject;
    let candidates = candidateObject.savedCandidateList;

    let saveFunctionalityObject = {
        savedCandidateList:candidateObject.savedCandidateList,
        handleSavedCandidateList:candidateObject.handleSavedCandidateList,
        totalCandidate: candidateObject.totalCandidate,
        setTotalCandidate: candidateObject.setTotalCandidate,
    };

    return (
        <>
            {candidates && candidates.map((item:any,index:any) => (
                <SavedCandidate
                    saveFunctionalityObject={saveFunctionalityObject}
                    candidate={item}
                    key={index}/>
                    ))
            }

            {(!candidates || candidates.length<=0) && candidateObject.isResultLoaded ? <NoSavedCandidateFound/>:''}
        </>
    );
}

export default SavedCandidateWrapper;
