import uploadCV from "../../assets/images/icons/upload.svg";

const BeforeUpload = (props:any) => {
    let show = props.show;
    let loading = props.loading;

    return (
    <>
        {(show && !loading) ?
      <div
        className="cursor-pointer d-flex justify-content-center align-items-center text-center"
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        <div className="small px-4">
          <img src={uploadCV} alt="" />
          <p className="mb-0">
            <span className="text-theme fw-semibold">Click to upload</span> or
            drag and drop
          </p>
          <p className="mb-0">File format - pdf (max 5 mb)</p>
        </div>
      </div>
            :''
        }
    </>
  );
};
export default BeforeUpload;
