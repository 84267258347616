import {Outlet, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {AppStorageService} from "../services/AppStorageService";
import {useAuthContext} from "../context/useAuthContext";
import {HOME_PAGE, RECRUITER_PROFILE, RECRUITER_SIGN_IN} from "./RouteNamingConfig";

const RecruiterSemiPublicRoute = () => {
    let navigate = useNavigate();
    let {user}:any = useAuthContext();
    let storage = new AppStorageService;
    let isCandidateLoggedIn = storage.userLoggedIn();

    useEffect(()=>{
        if(user){
            navigate(RECRUITER_PROFILE);
        }
        if(isCandidateLoggedIn){
            navigate(HOME_PAGE)
        }
    },[])


    return(
        <> { !user ?
            <Outlet/>:''}</>
    )
}

export default RecruiterSemiPublicRoute;