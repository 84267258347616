import { ApiEndPointService } from "./ApiEndPointService";
import AxiosService from "./AxiosService";
import {EnglishLevel} from "../types/EnglishLevel";
import {JobType} from "../types/JobType";
import {WorkType} from "../types/WorkType";
import exp from "constants";

let englishLevels:EnglishLevel[] = [];
let wotkTypes:WorkType[] = [];

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export async function getEnglishLevels(){
    return AxiosService.get(apiBaseUrl+ApiEndPointService.GET_ENGLISH_LEVEL,[]);
}

export async function getWorkTypes(){
    return AxiosService.get(apiBaseUrl+ApiEndPointService.GET_WORK_TYPES,[]);
}

export async function getSalaryReport(data:any){
    return AxiosService.post(apiBaseUrl+ApiEndPointService.SSALARY_COMPARISON,data,1)
}