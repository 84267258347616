const RequiredFieldError = (props: any) => {
  let show: boolean = props.show;
  return (
    <>
      {show ? (
        <span className="small pt-1 error-text">This is required</span>
      ) : (
        ""
      )}
    </>
  );
};

export default RequiredFieldError;
