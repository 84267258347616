import { Link } from "react-router-dom";
import backArrow from "../../../assets/images/icons/arrow-left.svg";
import mobileLogo from "../../../assets/images/icons/logo-for-signup.svg";
import {RECRUITER_FORGOT_PASSWORD} from "../../../utils/RouteNamingConfig";
import {useState} from "react";
import {useSignUp} from "../sign-up/useSignUp";
import useResetPassword from "./useResetPassword";
import InputFieldErrorMessage from "../../../components/errors/InputFieldErrorMessage";
import DisableEnableButton from "../../../components/buttons/DisableEnableButton";


const ResetPassword = () => {
    const [password,setPassword] = useState("");
    const [confirmPassword,setConfirmPassword] = useState("");
    const {isLoading,error,setError,passError, setPassError,validatePassword,confirmPassError,
        setConfirmPassError, validateConfirmPassword,resetPassword} = useResetPassword();

    const handleSubmit = async (e:any) =>{
        e.preventDefault();
        setError(null);
        setPassError(null);
        setConfirmPassError(null);

        let isValidPass = validatePassword(password);
        let isPassConfirmed = validateConfirmPassword(password,confirmPassword);

        if(isValidPass && isPassConfirmed)
        {
            await resetPassword(password);
        }


    }
    return(
        <div className="col-lg-6">
            <div className="position-relative h-100 d-flex align-items-lg-center justify-content-center">
                <div className="position-absolute start-0 recruiter-sign-up-back-button">
                    <Link to={RECRUITER_FORGOT_PASSWORD}>
                        <img src={backArrow} className="img-fluid" alt="back arrow" />
                        <span className="small text-secondary ps-3"> Go back</span>
                    </Link>
                </div>
                <div>
                    <div className="recruiter-spacious-section row align-items-center justify-content-center ">
                        <div className="col-md-7 col-12 px-0">
                            <div className="row">
                                <div className="col-12">
                                    <div className="text-center">
                                        <img
                                            src={mobileLogo}
                                            className="img-fluid d-md-none py-6 "
                                            alt=""
                                        />
                                        <h2 className="display-5 fw-semibold text-dark pb-2 pb-md-3">
                                            Set new password
                                        </h2>
                                        <p className="text-secondary mb-0 ">
                                            Use a different password other than previously used
                                            passwords
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row gy-4 gy-md-6 mx-0 pt-7">
                                            <div className="col-12">
                                                <label htmlFor="" className="form-label">
                                                    Password
                                                </label>
                                                <input
                                                    type="password"
                                                    className={(passError && passError?.length>0) ?
                                                        "form-control  error-input":"form-control"}
                                                    placeholder="Enter your password"
                                                    onChange={(e)=>setPassword(e.target.value)}
                                                    value={password}
                                                />
                                                <InputFieldErrorMessage error={passError}/>
                                            </div>
                                            <div className="col-12">
                                                <label htmlFor="" className="form-label">
                                                    Confirm Password
                                                </label>
                                                <input
                                                    type="password"
                                                    className={(confirmPassError && confirmPassError?.length>0) ?
                                                        "form-control  error-input":"form-control"}
                                                    placeholder="Enter your confirm password"
                                                    onChange={(e)=>setConfirmPassword(e.target.value)}
                                                    value={confirmPassword}
                                                />
                                                <InputFieldErrorMessage error={confirmPassError}/>
                                            </div>
                                            {(error && error.length>0) ? <InputFieldErrorMessage error={error} /> : ""}
                                            <div className="col-12 ">
                                                <DisableEnableButton disable={isLoading} text="Reset Password" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;