import WhiteButton from "../../../../components/buttons/WhiteButton";
import announcement from "../../../../assets/images/icons/announcement.svg";
import {useEffect, useState} from "react";
import {getCookie, setCookie} from "../../../../services/RecruiterAuthenticationService";
const Announcement = () => {
  const [show, setShow] = useState(false);
  let activeClass = "tab-pane fade show pt-0 active";
  useEffect(() => {
    if (getCookie("details_announce")) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, []);

  const handleClick = () => {
    //props.setClassForAnnouncement(activeClass);
    setCookie("details_announce", "set", 365);
    setShow(false);
  };
  return (
      <>
        {show ?
    <div className="bg-white p-3 rounded-3 border mb-6 mb-md-7">
                    <div className="row">
                      <div className="col-12 col-lg-9">
                        <div className="d-block d-md-flex">
                          <img
                            src={announcement}
                            alt=""
                            className="img-fluid pe-4"
                          />
                          <p className="mb-0 py-3 py-lg-0">
                            By contacting candidate you agree to pay{" "}
                            <span className="text-primary fw-semibold">
                              500 $ (approx. 53,047 ৳)
                            </span>{" "}
                            for each candidate hired. We need this to keep the
                            site running
                          </p>
                        </div>
                      </div>

                      <div className="col-12 col-lg-3">
                        <WhiteButton
                          text="I understand"
                          type="submit"
                          className="w-100"
                          onclick={handleClick}
                        />
                      </div>
                    </div>
                  </div>
        :""}
      </>
  )
}

export default Announcement
