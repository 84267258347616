import {Link, useNavigate, useSearchParams} from "react-router-dom";
import backArrow from "../../../assets/images/icons/arrow-left.svg";
import mobileLogo from "../../../assets/images/icons/logo-for-signup.svg";
import AuthPageSideBar from "../../../components/recruiter/auth/AuthPageSideBar";
import {RECRUITER_SIGN_UP} from "../../../utils/RouteNamingConfig";
import {useEffect} from "react";
import {usePostSignUp} from "./usePostSignUp";
import InputFieldErrorMessage from "../../../components/errors/InputFieldErrorMessage";
import SweetAlert2 from 'react-sweetalert2';



const PostSignUp = () => {
  let navigate = useNavigate();
  const email = sessionStorage.getItem('signup_email');
  const {handleResend,error,setError,loading,swalProps,setSwalProps} = usePostSignUp();

  useEffect(()=>{
    if(!email){
      navigate(RECRUITER_SIGN_UP);
    }
  })

  const emailObject = {"email":email}

  return (
    <div>
      <div className="row min-vh-100">
        <AuthPageSideBar/>
        <div className="col-md-6 px-0">
          <div className="row pt-7">
            <div className="col-12 ps-7 ms-3">
              <Link to={RECRUITER_SIGN_UP}>
                <img src={backArrow} className="img-fluid" alt="back arrow" />
                <span className="small text-secondary ps-3"> Go back</span>
              </Link>
            </div>
          </div>
          <div className="text-center h-75 d-flex align-items-center justify-content-center ">
            <div className="">
              <img
                src={mobileLogo}
                className="img-fluid d-inline-block d-md-none pb-6"
                alt=""
              />
              <h2 className="display-5 fw-medium text-dark pb-3">
                Please verify your email
              </h2>
              <p className="mb-0">We’ve sent a special link to</p>
              <p className="mb-0 pb-7">
                <span className="fw-semibold text-secondary">
                  {email}
                </span>{" "}
                to verify your email
              </p>
              <p className="mb-0 small">
                Please don’t forget to check spam folder as well if you
              </p>
              <p className="mb-0 pb-7 small">
                don’t receive the mail in your primary inbox
              </p>
              <p className="mb-0 text-secondary small">
                Didn’t get the mail?{" "}
                {
                  loading ?
                    <a className="fw-semibold">Please Wait...</a>
                :
                    <a onClick={event => handleResend(emailObject)} className="fw-semibold cursor-pointer">Resend</a>
                }

              </p>
              <InputFieldErrorMessage error={error}/>
            </div>
          </div>
        </div>
      </div>
      <SweetAlert2 {...swalProps}
                   didClose={() => {
                     setSwalProps({})
                   }}
      />
    </div>
  );
};

export default PostSignUp;
