import country from "../../../../assets/images/icons/globe-2.svg";
import flag from "../../../../assets/images/icons/flag.svg";
import user from "../../../../assets/images/icons/users-2.svg";
import companyType from "../../../../assets/images/icons/company-type.svg";
import React from "react";

const CompanyDetails = (props:any) => {
    let extraDetails = props.extraDetails;
    return (
        <>
            <div className="col-12">
                <div className="d-flex">
                    <div>
                        <img src={country} alt="" className="img-fluid" />
                    </div>
                    <div className="align-self-center ps-2">
                        <p className="mb-0 text-semidark">Country</p>
                        <p className="mb-0 small pt-1">{extraDetails?.company_country ?? ''}</p>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="d-flex">
                    <div>
                        <img src={flag} alt="" className="img-fluid" />
                    </div>
                    <div className="align-self-center ps-2">
                        <p className="mb-0 text-semidark">Founding year</p>
                        <p className="mb-0 small pt-1">{extraDetails?.company_founding_year ?? ''}</p>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="d-flex">
                    <div>
                        <img src={user} alt="" className="img-fluid" />
                    </div>
                    <div className="align-self-center ps-2">
                        <p className="mb-0 text-semidark">Number of employees</p>
                        <p className="mb-0 small text-gray">
                            <span>{extraDetails?.company_employees_number ?? ''}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="d-flex">
                    <div>
                        <img src={companyType} alt="" className="img-fluid" />
                    </div>
                    <div className="align-self-center ps-2">
                        <p className="mb-0 text-semidark">Company type</p>
                        <p className="mb-0 small pt-1">{extraDetails?.company_type ?? ''}</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CompanyDetails;