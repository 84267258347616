import {signUpResend} from "../../../services/RecruiterAuthenticationService";
import {CommonErrorMsg} from "../../../utils/CommonConstants";
import {useState} from "react";


export const usePostSignUp = () => {
    const [error,setError] = useState<string|null>('');
    const [loading,setLoading] = useState<boolean>(false);
    const [swalProps, setSwalProps] = useState({});

    type EmailObject ={
      email:string|null
    };

    const handleResend = async (emailObject:EmailObject) => {
        setLoading(true);
        setError('');
        let  response = await signUpResend(emailObject);
        if(response && response.success){
            setLoading(false);
            setSwalProps({
                show: true,
                icon: 'info',
                title: 'Success',
                text: 'Please check your inbox',
            });
        }else{

            setLoading(false);
            setError(response.message ?? CommonErrorMsg)

        }

    }
    return {handleResend,error,setError,loading,swalProps,setSwalProps};
}
