import ProgressUploadBar from "./ProgressUploadBar";
import fileComplete from "../../assets/images/icons/file-compelet-tick.svg";
const StartUpload = (props: any) => {
  let show = props.show;
  const name = props.name;
  const size = props.size;
  const completed = props.completed;
  return (
    <>
      {show ? (
        <div
          className="d-flex align-items-center justify-content-center align-items-center "
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <div className="w-100 px-4">
            <div className="row">
              <div className="col-10">
                <p className="mb-0 small text-semidark">{name}</p>
                <p className="mb-0 small text-secondary">{size}</p>
              </div>
              <div className="col-2">
                {completed >= 100 ? (
                  <img
                    src={fileComplete}
                    alt=""
                    className="img-fluid"
                    height="15px"
                    width="15px"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            <ProgressUploadBar completed={completed} />
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default StartUpload;

// <ProgressUploadBar completed={completed} />
