import checkedIcon from "../../../../assets/images/icons/check-icon.svg";

const ExtraSkillIgnoreMessage = (props:any) => {
    let show = props.show;

    return (
      <>
          {!show ? "":
              <>
                  <div className="py-6">
                      <div className="bg-light p-4 rounded-3">
                          <div className="d-flex pb-2">
                              <img
                                  src={checkedIcon}
                                  alt=""
                                  className="img-fluid align-self-start"
                              />
                              <p className="mb-0 text-secondary ps-2">
                                  We couldn't find many candidates. Therefore, we're also showing candidates who are likely to have the required skill set, even though they haven't indicated it in their profile.
                              </p>
                          </div>
                      </div>
                  </div>
                  <hr className="m-0"/>
              </>
          }
      </>

  )
}

export default ExtraSkillIgnoreMessage
