import {
  CategoryScale,
  Chart,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  Filler
);
const ExperienceEarningsChart = (props: any) => {
  const processedData = props.processedData;
  const processedUserData = props.processedUserData;
  const experienceLabel = processedData.processedExpericenPoint;
  const salaryLabel = processedData.processedSalaryPoint;

  const data = {
    labels: experienceLabel,
    datasets: [
      {
        label: "You",
        data: processedUserData,
        backgroundColor: "#3e4784",
        borderColor: "#282c3400",
        borderWidth: 2,
        fill: true,
        tension: 0.3,
        pointRadius: 9,
        pointBackgroundColor: "#3e4784",
        pointBorderWidth: 1,
        pointBorderColor: "#282c3400",
        pointHoverBackgroundColor: "#7f56d9",
        pointHoverRadius: 11,
        pointHoverBorderColor: "#6941c6",
      },
      {
        label: "Others",
        data: salaryLabel,
        backgroundColor: "#b692f659",
        borderColor: "#9e76ed",
        borderWidth: 2,
        fill: true,
        tension: 0.3,
        pointRadius: 9,
        pointBackgroundColor: [
          "#b692f659",
          "#b692f659",
          "#b692f659",
          "#b692f659",
          "#b692f659",
          "#b692f659",
        ],
        pointBorderWidth: 1,
        pointBorderColor: "#9e76ed",
        pointHoverBackgroundColor: "#7f56d9",
        pointHoverRadius: 11,
        pointHoverBorderColor: "#6941c6",
      },
    ],
  };

  const options = {
    fill: true,
    responsive: true,
    legend: {
      display: true,
      position: "top",
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value: any) {
            if (value >= 1000) {
              return value / 1000 + "k";
            }
            return value;
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: (xDatapoint: any) => {
            return xDatapoint.raw;
          },
          label: (yDatapoint: any) => {
            return yDatapoint.raw.toLocaleString();
          },
        },
      },
    },
  };

  // @ts-ignore
  return (
    // <div className="container position-relative z-2">
    //   <div className="row justify-content-center">
    //     <div className="col-9">
    //       <div className="justify-content-center align-items-center py-4 shadow-sm rounded-4 bg-white p-7">
    //         <div className="col-12 col-md-12">
    //           <h2>Average salary for this skill set</h2>
    //           <p>Chart shows how average salary for this skill set depends on years of experience.</p>
    //         </div>
    //         <div className="row">
    //           <div className="col-12 col-md-12 ">
    //             <Line options={options} data={data} />
    //           </div>
    //         </div>
    //         <p className="text-center mt-6 small">
    //           <span className="fw-semibold">Note:</span> Y-axis indicates salary
    //           amount &amp; X-axis indicates years of experiences
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="container position-relative z-2">
      <div className="row justify-content-center pb-2 mb-7 pb-md-0 mb-md-0">
        <div className="col-12 col-md-9">
          <div className="rounded-4" style={{ backgroundColor: "#eaecf0" }}>
            <div className="shadow rounded-3 bg-white p-7">
              <div className="row text-center text-md-start">
                <h3 className="card-title-5">
                  Engineers with different years of experiences earn this
                </h3>
                <p className="card-text small mb-6">
                  Track how your rating compares to your industry average.
                </p>

                {/* Chart will go here */}
                <div className="row">
                  {" "}
                  <div className="col-12 col-md-12 ">
                    <Line options={options} data={data} />{" "}
                  </div>{" "}
                </div>
                <p
                  className="text-center mt-6 small "
                  style={{ color: "#667085" }}
                >
                  <span className="fw-semibold">Note:</span> Y-axis indicates
                  salary amount &amp; X-axis indicates years of experiences{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExperienceEarningsChart;
