import {useState} from "react";
import {passwordResetRequest, prepareErrorMsg, signUpResend} from "../../../services/RecruiterAuthenticationService";


const usePostForgotPassword = () => {
    const [error,setError] = useState<string|null>('');
    const [loading,setLoading] = useState<boolean>(false);
    const [swalProps, setSwalProps] = useState({});

    type EmailObject ={
        email:string|null
    };

    const handleResend = async (emailObject:EmailObject) => {
        setLoading(true);
        setError('');
        const response = await passwordResetRequest(emailObject);
        if(response && response.success){
            setLoading(false);
            setSwalProps({
                show: true,
                icon: 'info',
                title: 'Success',
                text: 'Please check your inbox',
            });
        }else{

            setLoading(false);
            let errorText = prepareErrorMsg(response);
            setError(errorText);

        }

    }
    return {handleResend,error,setError,loading,swalProps,setSwalProps};
}
export default usePostForgotPassword;