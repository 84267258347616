import React from "react";


const ProgressBar = (props:any) => {

    let step = props.step;

    return(
        <>
            {
                step=='first' ?
                    <div className="progress-steps d-flex justify-content-between align-items-center mb-5">
                        <div className="steps steps-current rounded-circle">
                            <span className="steps-inner bg-white rounded-circle"></span>
                            <span className="steps-check"><i className="bi bi-check2"></i></span>
                        </div>

                        <span
                            className="progress-line progress-line-1"
                            style={{backgroundColor: '#f2f4f7'}}
                        ></span>

                        <div className="steps steps-incomplete rounded-circle">
                            <span className="steps-inner bg-white rounded-circle"></span>
                            <span className="steps-check"><i className="bi bi-check2"></i></span>
                        </div>

                        <span className="progress-line" style={{backgroundColor: '#f2f4f7'}}></span>

                        <div className="steps steps-incomplete rounded-circle">
                            <span className="steps-inner bg-white rounded-circle"></span>
                            <span className="steps-check"><i className="bi bi-check2"></i></span>
                        </div>
                    </div>
                    :''
            }

            {
                step=='second' ?
            <div className="progress-steps d-flex justify-content-between align-items-center">
                <div className="steps steps-complete rounded-circle">
                    <span className="steps-inner bg-white rounded-circle"></span>
                    <span className="steps-check">
                <img
                    src={require('../../assets/images/icons/tick.svg').default}
                    className="img-fluid"
                    width="16"
                    alt=""
                />
              </span>
                </div>

                <span className="progress-line progress-line-1"></span>

                <div className="steps steps-current rounded-circle">
                    <span className="steps-inner bg-white rounded-circle"></span>
                    <span className="steps-check">
                <img
                    src={require('../../assets/images/icons/tick.svg').default}
                    className="img-fluid"
                    width="16"
                    alt=""
                />
              </span>
                </div>

                <span
                    className="progress-line"
                    style={{backgroundColor: '#f2f4f7'}}
                ></span>

                <div className="steps steps-incomplete rounded-circle">
                    <span className="steps-inner bg-white rounded-circle"></span>
                    <span className="steps-check">
                <img
                    src={require('../../assets/images/icons/tick.svg').default}
                    className="img-fluid"
                    width="16"
                    alt=""
                />
              </span>
                </div>
            </div>
                :''
            }

            {
                step=='third' ?

            <div className="progress-steps d-flex justify-content-between align-items-center">
                <div className="steps steps-complete rounded-circle">
                    <span className="steps-inner bg-white rounded-circle"></span>
                    <span className="steps-check">
                <img
                    src={require('../../assets/images/icons/tick.svg').default}
                    className="img-fluid"
                    width="16"
                    alt=""
                />
              </span>
                </div>

                <span className="progress-line progress-line-1"></span>

                <div className="steps steps-complete rounded-circle">
                    <span className="steps-inner bg-white rounded-circle"></span>
                    <span className="steps-check">
                <img
                    src={require('../../assets/images/icons/tick.svg').default}
                    className="img-fluid"
                    width="16"
                    alt=""
                />
              </span>
                </div>

                <span className="progress-line progress-line-1"></span>

                <div className="steps steps-current rounded-circle">
                    <span className="steps-inner bg-white rounded-circle"></span>
                    <span className="steps-check">
                <img
                    src={require('../../assets/images/icons/tick.svg').default}
                    className="img-fluid"
                    width="16"
                    alt=""
                />
              </span>
                </div>
            </div>
                    :''
            }
        </>

    );

}

export default ProgressBar;