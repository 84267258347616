import React, {useState} from "react";
import billing from "../../../assets/images/icons/billing.svg";
import useRecruiterProfile from "../../../views/recruiter/profile/useRecruiterProfile";

const NavTabs = () => {
  let activeClass = "nav-link rounded-0 p-3 fw-semibold text-start me-4 me-md-0 active";
  let inActiveClass = "nav-link rounded-0 p-3 fw-semibold text-start me-4 me-md-0";
  return (
    <nav>
      <div
        className="nav nav-tabs flex-row flex-md-column user-nav"
        id="nav-tab"
        role="tablist"
      >
        <button
          className="nav-link rounded-0 p-3 fw-semibold text-start me-4 me-md-0 active"
          id="nav-profile-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-profile"
          type="button"
          role="tab"
          aria-controls="nav-profile"
          aria-selected="true"
          //onClick={props.props.showAccountTab}
        >
          <i className="bi bi-person me-1 d-none d-md-inline-block"></i>
          Profile
        </button>
      </div>
    </nav>
  );
};

export default NavTabs;
