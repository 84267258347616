import PrivateRoute from "../utils/PrivateRoute";
import SalaryDetails from "../views/candidate/other-details/OtherDetails";
import {
    BASE_URL,
    CANDIDATE_PROFILE_PAGE,
    CANDIDATE_RECRUITER_PROFILE_PAGE_WITH_ID,
    SALARY_REPORT_PAGE
} from "../utils/RouteNamingConfig";
import BaseLayout from "../layouts/app/candidate/Base/BaseLayout";
import SalaryReport from "../views/candidate/salary-report/SalaryReport";
import CandidateProfile from "../views/candidate/profile/CandidateProfile";
import RecruiterProfilePublicViewWrapper from "../views/candidate/recruiter-profile/RecruiterProfilePublicViewWrapper";
import React from "react";
import RecruiterHeader from "../layouts/app/recruiter/RecruiterHeader";
import RecruiterBaseLayout from "../layouts/app/recruiter/RecruiterBaseLayout";

export const salaryDetailRoutes = {
    path: '/other-details',
    element:<PrivateRoute /> ,
    children: [
        {
            path: '/other-details/',
            element: <SalaryDetails />,
        }
    ]
}

export const salaryReportRoutes = {
    path: SALARY_REPORT_PAGE,
    element: <BaseLayout />,
    children: [
        {
            path: SALARY_REPORT_PAGE+'/',
            element: <PrivateRoute/>,
            children: [
                {

                    path: SALARY_REPORT_PAGE + '/',
                    element: <SalaryReport/>
                }
            ]
        }
    ]
}

export const candidateProfileRoutes = {
    path: CANDIDATE_PROFILE_PAGE,
    element: <BaseLayout />,
    children: [
        {
            path: CANDIDATE_PROFILE_PAGE+'/',
            element: <PrivateRoute/>,
            children: [
                {

                    path: CANDIDATE_PROFILE_PAGE + '/',
                    element: <CandidateProfile/>
                }
            ]
        }
    ]
}

export const candidateRecruiterPrivateRoutes = {
    path: BASE_URL,
    element: <RecruiterBaseLayout />,
    children: [
        {
            path: CANDIDATE_RECRUITER_PROFILE_PAGE_WITH_ID,
            element: <PrivateRoute/>,
            children: [
                {
                    path: CANDIDATE_RECRUITER_PROFILE_PAGE_WITH_ID ,
                    element: <RecruiterProfilePublicViewWrapper/>
                }
            ]
        }
    ]
}