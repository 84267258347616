import { Outlet } from "react-router-dom";
import RecruiterHeader from "./RecruiterHeader";
import AuthPageSideBar from "../../../components/recruiter/auth/AuthPageSideBar";

const RecruiterAuthenticationLayout = () => {
  return (
    <>
      <RecruiterHeader />
        <div className="position-relative">
        <div className="w-50 position-absolute top-0 start-0 bg-light z-1 h-100 d-none d-lg-block"></div>
          <div className="container position-relative z-3">
            <div className="row min-vh-100">
                <AuthPageSideBar/>
                <Outlet />
            </div>
        </div>
        </div>
    </>
  );
};

export default RecruiterAuthenticationLayout;
