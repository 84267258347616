import AxiosService from "./AxiosService";
import {ApiEndPointService} from "./ApiEndPointService";
import {
    ApiBaseUrl,
    RecruiterNewProfileAnnouncementCookieKey,
    RecruiterProfileAnnouncementCookieKey, RecruiterProfileAnnouncementsActiveClass
} from "../utils/CommonConstants";
import {Recruiter} from "../types/Recruiter";
import {getCookie} from "./RecruiterAuthenticationService";
import exp from "constants";

export async function getCountries(){
    return AxiosService.get(ApiBaseUrl+ApiEndPointService.GET_ALL_COUNTRIES,{})
}

export async function getEmployeeRanges(){
    return AxiosService.get(ApiBaseUrl+ApiEndPointService.GET_ALL_EMPLOYEE_RANGE,{})
}

export async function getRecruiterProfile(){
    return AxiosService.get(ApiBaseUrl+ApiEndPointService.RECRUITER_PROFILE,{})
}

export async function saveRecruiterProfile(data:Recruiter){
    return AxiosService.post(ApiBaseUrl+ApiEndPointService.RECRUITER_PROFILE,data,{});
}

export async function submitRecruiterProfile(data:Recruiter){
    return AxiosService.post(ApiBaseUrl+ApiEndPointService.RECRUITER_PROFILE_SAVE_AND_SUBMIT,data,{});
}

export async function searchCandidateProfile(data:any){
    return AxiosService.post(ApiBaseUrl+ApiEndPointService.RECRUITER_SEARCH_CANDIDATE,data,{});
}

export const scrollTo = (ref:any) => {
    if (ref && ref.current /* + other conditions */) {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
}

export const checkIfBangladeshiNumber = (number:string) => {
    return !(number.length != 11 || number.substring(0, 2) != '01');
}

export const fetchClassForProfilePart = () => {
    let score = 0;
    let responseClass = RecruiterProfileAnnouncementsActiveClass;
    if (!getCookie(RecruiterNewProfileAnnouncementCookieKey)) {
        score++;
    }
    if (!getCookie(RecruiterProfileAnnouncementCookieKey)) {
        score++;
    }

    if(score==1) {
        responseClass += ' nav-profile'
    }else if(score==2) {
        responseClass += ' nav-profile-2'
    }

    return responseClass;

}