const NegotiableSalary = () => {

    return (
        <>
            <div className="d-flex gap-2 justify-content-md-end pt-4 pt-md-0">
                <p className="mb-0 candidate-common-text align-self-center">
                   Salary
                </p>
                <p
                    className="mb-0 text-dark fw-semibold"
                    style={{ fontSize: "20px" }}
                >
                    Negotiable
                </p>

            </div>
        </>
    );
}

export default NegotiableSalary;