import Modal from "react-bootstrap/Modal";
import {Link} from "react-router-dom";
import {RECRUITER_PROFILE} from "../../../../utils/RouteNamingConfig";
import React from "react";

type propsType = {
    showModal: boolean,
    handleClose: (() => void)
}
const UnverifiedNoticeModal = (props:propsType) => {
    let showModal = props.showModal;
    let handleClose = props.handleClose;
    return (
        <>
            <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header className="p-0">
                    <Modal.Title className="common-text common-text-size px-6 pt-6 pb-4">Your profile has to be verified before you can contact candidates</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0 pb-6 px-6">
                    <p className="mb-0 pb-5">To ensure quality experience for candidates we are reviewing and verifying each new profile. We do our best to do do this in no more than 48 hours after profile submission.</p>
                    <p className="mb-0 pb-6">To help us, please provide all the required correct data to your profile, so we can check and confirm it quickly and easily.</p>
                    <div className="d-flex flex-column flex-md-row justify-content-md-end gap-3">
                        <button className="btn btn-secondary-gray" onClick={handleClose}>Cancel</button>
                        <Link to={RECRUITER_PROFILE}>
                            <button className="btn btn-primary w-100">Visit profile to verify</button>
                        </Link>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default UnverifiedNoticeModal;