import React, {useRef} from "react";
import Moment from 'react-moment';
import {useMessenger} from "../../../context/useMessenger";

const SingleInboxItem = ({item}:any) => {
    let singleInboxItem = item;
    let content = singleInboxItem.last_message ?? '';
    let createdAt = singleInboxItem.created_at ?? '';
    let overview = singleInboxItem?.opponent?.overview ?? '';
    const selectedChat = useMessenger((state:any)=>state.selectedChat);

    return (
        <>
            <div className={selectedChat==singleInboxItem.chat_id ? 'selected-text chat-item p-4' :  "chat-item p-4"}>
                <div className="w-100">
                    <div className="row gy-4">
                        <div className="col-12">
                            <p className="mb-0 small fw-bold text-semidark">
                                {singleInboxItem?.opponent?.inbox_name ?? ''}
                            </p>
                            <p className="mb-0 fs-12 text-gray">
                                {overview}
                            </p>
                        </div>
                        <div className="col-12">
                            <p className="mb-0 small text-gray">
                                {content}{" "}
                            </p>
                        </div>
                        <div className="col-12">
                            <p className="mb-0 fs-12 text-gray-light">
                                <Moment fromNow>{createdAt}</Moment>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SingleInboxItem;