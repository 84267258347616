import { ApiEndPointService } from "./ApiEndPointService";
import AxiosService from "./AxiosService";
import {Candidate} from "../types/Candidate";
import {ApiBaseUrl} from "../utils/CommonConstants";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export function formatBytes(bytes:any, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PiB', 'EiB', 'ZiB', 'YiB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export async function  updateCandidateProfile(candidate:Candidate){
    return AxiosService.post(apiBaseUrl+ApiEndPointService.UPDATE_USER_PROFILE,candidate,{});
}


export async function getCandidateProfile(){
    return AxiosService.get(apiBaseUrl+ApiEndPointService.GET_USER_PROFILE, [],'secured');
}

export async function  updateCandidatePreference(candidate:Candidate){
    return AxiosService.post(apiBaseUrl+ApiEndPointService.SAVE_PREFERENCES,candidate,{});
}

export async function uploadCv(file:any){
    return AxiosService.post(apiBaseUrl+ApiEndPointService.UPLOAD_USER_CV,file,{},'file')
}

export async function deleteCv(){
    return AxiosService.delete(apiBaseUrl+ApiEndPointService.DELETE_USER_CV,{})
}

export async function reportBeingHired(userObject:any){
    return AxiosService.post(ApiBaseUrl+ApiEndPointService.REPORT_BEING_HIRED,userObject,{});
}