import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {
    InvalidConfirmPasswordErrorMsg,
    InvalidPasswordErrorMsg,
    RequiredFieldCommonErrorMsg
} from "../../../utils/CommonConstants";
import {
    passwordResetRequest,
    prepareErrorMsg, resetPassword as resetPasswordNow,
    resetPasswordVerify
} from "../../../services/RecruiterAuthenticationService";
import {
    RECRUITER_POST_FORGOT_PASSWORD,
    RECRUITER_RESET_PASSWORD_CONFIRM,
    RECRUITER_SIGN_IN
} from "../../../utils/RouteNamingConfig";

type ResetPasswordObject = {
    xde:string|null,
    udev:string|null,
    password: string
};

const useResetPassword = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [error,setError] = useState<string|null>(null);
    const [xde,setXde] = useState('');
    const [udev,setUdev] = useState('');
    // Retrieve the object from storage
    let verificationObject = sessionStorage.getItem('verificationObject');
    const [passError,setPassError] = useState<string|null>(null);
    const [confirmPassError,setConfirmPassError] = useState<string|null>(null);
    const [isLoading,setIsLoading] = useState<boolean | null>(null);
    //let pesetPasswordObject:ResetPasswordObject = {xde:xde,udev:udev};

    useEffect(()=>{
        if(!verificationObject){
            navigate(RECRUITER_SIGN_IN)
        }
        let dataFromStorage:any = JSON.parse(verificationObject ?? '{}');


        setUdev(dataFromStorage.udev ?? '');
        setXde(dataFromStorage.xde ?? '');
    },[])

    const validatePassword = (password:string) =>{
        if(!password || password==''){
            setPassError(RequiredFieldCommonErrorMsg);
            return false;
        }
        let re = /^(?=.*[a-zA-Z0-9])(?=.*[!@#$%^&*]).{6,32}$/;
        if(!re.test(password)){
            setPassError(InvalidPasswordErrorMsg);
            return false;
        }
        return true;
    }

    const validateConfirmPassword = (password:string,confirmPassword:string) =>{
        if(!confirmPassword || confirmPassword==''){
            setConfirmPassError(RequiredFieldCommonErrorMsg);
            return false;
        }

        if(password!==confirmPassword){
            setConfirmPassError(InvalidConfirmPasswordErrorMsg);
            return false;
        }
        return true;
    }

    const resetPassword = async (password:string) => {
        setIsLoading(true);
        setError(null);

        let resetPasswordObject:ResetPasswordObject = {
            xde: xde,
            udev: udev,
            password: password
        }

        const response = await resetPasswordNow(resetPasswordObject);
        if(response && response.success && response.data){
            sessionStorage.clear();

            sessionStorage.setItem('temp_user_object',JSON.stringify(response.data));
            setIsLoading(false);
            navigate(RECRUITER_RESET_PASSWORD_CONFIRM);
        }else{
            setIsLoading(false);
            let errorText = prepareErrorMsg(response);
            setError(errorText);
        }
    }

    return {isLoading,error,setError,passError, setPassError,validatePassword,confirmPassError,
        setConfirmPassError, validateConfirmPassword, resetPassword};
}

export default useResetPassword;