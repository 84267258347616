import React from "react";
import {useNavigate} from "react-router-dom";

const BackButton = (props:any) => {
    let navigate = useNavigate();
    let url = props.goto;
    let text = props.text;
    const handleClick=()=>{
        navigate(url);
    }
    return (
        <button  className="btn btn-secondary-gray w-100" onClick={handleClick}>
            {text}
        </button>
    );
}

export default BackButton;