import { components } from "react-select";

const Option = (props:any) => {
    const { title, subTitle } = props.data;

    return (
        <components.Option {...props}>
            <div className="line">{title}</div>
            <div className="line" style={{fontSize: 11.5}}>
                    <span>
                      {subTitle}
                    </span>
            </div>
        </components.Option>
    );
};

export default Option;