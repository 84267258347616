const RequiredFieldError = (props:any) => {
    let show = true;
    if(props){
        if(props.show){
            show= true;
        }else{
            show = false;
        }
    }
  return (
    <>
        {show ?
        <span className="small pt-1 error-text error-msg-text">This is required</span>
            :""
        }
    </>
  );
};

export default RequiredFieldError;
