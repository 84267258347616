import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import tick from "../../../assets/images/icons/check-green.svg";

const HiredModal = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div onClick={handleShow}>
        <button className="btn d-flex green-badge px-4 py-3 gap-3">
          <img
            src={tick}
            alt=""
            className="img-fluid"
            width={"20px"}
            height={"20px"}
          />
          <p className="mb-0 small">Mark as hired</p>
        </button>
      </div>

      <Modal show={show} onHide={handleClose} centered>
      <Modal.Header className="px-6 pt-6 pb-0">
          <Modal.Title id="contained-modal-title-vcenter">
            <p
              className="mb-0"
              style={{ fontWeight: "600", fontSize: "20px", color: "#101828" }}
            >
              Do you want to mark this candidate as hired?
            </p>
            
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-5 px-6 pb-6">
          <form>
            <div className="row">
              <div className="col-12">
                <p className="mb-0">For a successful hire of each candidate, you need to pay 500 $ (approx. 53,047 ৳). We need this to keep the site running</p>
              </div>
              <div className="col-12">
                <div className="d-flex justify-content-end gap-3 pt-6">
                  <button

                      type="button"
                      onClick={handleClose}
                    className="btn whiteBtn"
                  >
                    Cancel
                  </button>
                  <button
                      type="button"
                      onClick={handleClose}
                    className="btn btn-primary text-white px-4 py-2"
                    
                  >
                    Yes, mark as hired
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
       
      </Modal>
    </>
  );
};

export default HiredModal;
