import React, { useState } from 'react';

import message from "../../../../assets/images/icons/message-text-square-02.svg";
import job from "../../../../assets/images/icons/job.svg";
import sadFace from "../../../../assets/images/icons/sad-face.svg";
import check from "../../../../assets/images/icons/check-white.svg";

import WhiteButton from "../../../../components/buttons/WhiteButton";

import PaymentModal from "../../../../components/recruiter/modals/PaymentModal"
import CandidateExtraDetails from "./CandidateExtraDetails";
import MarkAsHired from "./MarkAsHired";
import {useCandidateDetails} from "../../../../context/useCandidateDetails";
import ContactCandidate from "./ContactCandidate";

const CandidateDetailsAfterContacted = (props:any) => {
  let extraDetails = props.extraDetails;
  let detailsFunctionalities = props.detailsFunctionalities;

  return (
    <div
      className="p-6 rounded-2"
      style={{backgroundColor:"#F9F5FF",border:"1px solid #7F56D9"}}
    >
      <div className="row gy-4 pb-3">
        <div>
            <p className="mb-0 fw-semibold" style={{fontSize:"20px" , color:"#42307D"}}>You’ve contacted this candidate</p>
        </div>
        <CandidateExtraDetails extraDetails={extraDetails}/>
      </div>
      <div className="pt-4">
        <div className="pb-3">
          <MarkAsHired extraDetails={extraDetails} />
        </div>
        <ContactCandidate isContacted={true} extraDetails={extraDetails} detailsFunctionalities={detailsFunctionalities}/>

        {/*<button className="w-100 btn whiteBtn">Go to the conversation</button>*/}
        {/*<WhiteButton text="Go to the conversation" className="w-100" type="button"/>*/}
      </div>
    </div>
  )
}

export default CandidateDetailsAfterContacted
