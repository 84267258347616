import Modal from "react-bootstrap/Modal";
import paymentIcon from "../../../assets/images/icons/payment-icon.svg";
import PaymentNavTabs from "./PaymentNavTabs";
import { useState } from "react";
import PaymentContent from "./PaymentContent";
import {useCandidateDetails} from "../../../context/useCandidateDetails";
const PaymentModal = (props: any) => {
  const [showPaymentTab, setShowPaymentTab] = useState(true);
  const showPaymentModal = useCandidateDetails((state:any)=>state.showPaymentModal);
  const setShowPaymentModal = useCandidateDetails((state:any)=>state.setShowPaymentModal);
  const show = showPaymentModal;
  let extraDetails = props.extraDetails;



  const handleShowPaymentTab = () => {
    setShowPaymentTab(true);
  }
  const handleHidePaymentTab = () => {
    setShowPaymentTab(false);
  }

  const handleClose = () => {
    setShowPaymentModal(false);
  }

  const tabObject = {
    showPaymentTab: showPaymentTab,
    showTab: handleShowPaymentTab,
    hideTab: handleHidePaymentTab,
  }


  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
    >
      <Modal.Header className="px-6 pt-6 pb-0" closeButton >
        <Modal.Title id="contained-modal-title-vcenter">
          <img src={paymentIcon} className="img-fluid" alt="" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-6 pb-0">
        <div className="pt-4">
          <h5 className="common-text common-text-size">
            INVOICE <span>#141329</span>
          </h5>
          <p
            className="mb-0 pt-1"
            style={{ color: "#475467", fontSize: "14px", fontWeight: "400" }}
          >
            Unpaid
          </p>
        </div>
        <PaymentNavTabs tabObject={tabObject} />
        <PaymentContent extraDetails={extraDetails} handleClose={handleClose} tabObject={tabObject} />
      </Modal.Body>
    </Modal>
  );
};

export default PaymentModal;
