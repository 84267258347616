import React from "react";

const PaymentStatus = (props:any) => {
    let paymentStatus = props.paymentStatus;

  return (
    <>
        {paymentStatus == 'paid' ? (<>
              <span className="mb-0 small text-semidark border border-semidark rounded-pill px-3 py-1 fw-medium">
        Paid
      </span>
        </>):""}

        {paymentStatus == 'unpaid' ? (<>
            <span className="mb-0 small text-semidark border border-semidark rounded-pill px-3 py-1 fw-medium">
        Unpaid
      </span>
        </>) : ""}

        {paymentStatus == 'processing' ? (<>
            <span className="mb-0 small text-semidark border border-semidark rounded-pill px-3 py-1 fw-medium">
        Unconfirmed payment
      </span>
        </>):''}
    </>
  );


};

export default PaymentStatus;
