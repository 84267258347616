import { ApiEndPointService } from "./ApiEndPointService";
import AxiosService from "./AxiosService";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;



export class LoginService {


  parseJwt (token:any) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
   var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
     return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
   }).join(''));

   return JSON.parse(jsonPayload);
  };




  validateGoogleToken (token:string){
    let googleValidation: GoogleValidation = new GoogleValidation();
    googleValidation.token = token;
    googleValidation.provider = 'google';

    return AxiosService.post(apiBaseUrl+ApiEndPointService.GOOGLE_LOGIN,googleValidation,[]);


    //this.network.postRequest(ApiEndPoint.GOOGLE_LOGIN, ApiEndPoint.GOOGLE_LOGIN, "", googleValidation);
  }


}
class GoogleValidation{
  token!:string;
  provider!:string;
}