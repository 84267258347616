import { ApiEndPointService } from "./ApiEndPointService";
import AxiosService from "./AxiosService";
import {ApiBaseUrl, CommonErrorMsg} from "../utils/CommonConstants";
import {forEach} from "react-bootstrap/ElementChildren";


export const prepareErrorMsg =(response:any)=> {
    let errorText = "";

    if(response.data){
        let errorMsgFromApi = response.data;
        if(errorMsgFromApi.email){
            errorMsgFromApi.email.forEach((item:any)=>{
                errorText+=item+"\n";
            })
        }
        if(errorMsgFromApi.password){
            errorMsgFromApi.password.forEach((item:any)=>{
                errorText+=item+"\n";
            })
        }
    }

    if (!errorText){
        errorText = response.message ?? CommonErrorMsg;
    }

    return errorText;
}


export const prepareAllErrorMsg =(response:any)=> {
    let errorText = "";

    if(response.data){
        let errorMsgFromApi = response.data;
        if(errorMsgFromApi.length>0){
            errorMsgFromApi.forEach((item:string)=>{
                errorText+=item+"\n";
            })
        }

    }

    if (!errorText){
        errorText = response.message ?? CommonErrorMsg;
    }

    return errorText;
}

export async function  signUp(signUpObject:any){
    return AxiosService.post(ApiBaseUrl+ApiEndPointService.RECRUITER_SIGN_UP,signUpObject,{});
}

export async function  signIn(signInObject:any){
    return AxiosService.post(ApiBaseUrl+ApiEndPointService.RECRUITER_SIGN_IN,signInObject,{});
}

export async function  verify(verificationObject:any){
    return AxiosService.post(ApiBaseUrl+ApiEndPointService.RECRUITER_VERIFY,verificationObject,{});
}


export async function  signUpResend(object:any){
    return AxiosService.post(ApiBaseUrl+ApiEndPointService.RECRUITER_SIGNUP_RESEND,object,{});
}

export async function  passwordResetRequest(object:any){
    return AxiosService.post(ApiBaseUrl+ApiEndPointService.RECRUITER_PASSWORD_RESET_REQUEST,object,{});
}

export async function  resetPassword(passwordResetObject:any){
    return AxiosService.post(ApiBaseUrl+ApiEndPointService.RECRUITER_PASSWORD_RESET,passwordResetObject,{});
}

export async function  resetPasswordVerify(passwordResetObject:any){
    return AxiosService.post(ApiBaseUrl+ApiEndPointService.RECRUITER_PASSWORD_RESET_VERIFY,passwordResetObject,{});
}

export function setCookie(name:string,value:string,days:number) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

export function getCookie(name:string) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

export function eraseCookie(name:string) {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}