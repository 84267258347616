import HomeLayout from "../layouts/app/candidate/Home/HomeLayout";
import MultipleSkills from "../views/candidate/multiple-skills/MultipleSkills";
import {salaryDetailRoutes} from "./CandidateRoutes";
import BaseLayout from "../layouts/app/candidate/Base/BaseLayout";
import TermsAndConditions from "../views/terms-and-conditions/TermsAndCondtions";
import PrivacyPolicy from "../views/privacy-policy/PrivacyPolicy";
import NotFound from "../views/404-not-found/NotFound";
import React from "react";
import RecruiterBaseLayout from "../layouts/app/recruiter/RecruiterBaseLayout";
import {INBOX, INBOX_PANEL} from "../utils/RouteNamingConfig";
import Inbox from "../views/inbox/Inbox";
import SingleChat from "../views/inbox/components/SingleChat";

const Home = React.lazy(() => import('../views/home/Home'));

export const homeRoutes = {
    path: '/',
    element: <HomeLayout/>,
    children: [
        {
            path: '/',
            element: <Home/>
        },
        {
            path: '/multiple-skills',
            element: <MultipleSkills/>,
        },
        salaryDetailRoutes
    ]
};

export const termsRoutes = {
    path: '/terms',
    element: <BaseLayout/>,
    children: [
        {
            path: '/terms',
            element: <TermsAndConditions/>
        },

    ]
};

export const privacyPolicyRoutes = {
    path: '/privacy-policy',
    element: <BaseLayout/>,
    children: [
        {
            path: '/privacy-policy',
            element: <PrivacyPolicy />
        },

    ]
};

export const notFound = {
    path: '*',
    element: <BaseLayout/>,
    children:[
        {
            path: '*',
            element: <NotFound/>,
        }

    ]
}


export const inbox = {
    path: INBOX,
    element: <RecruiterBaseLayout />,
    children: [
        {
            path: INBOX,
            element: <Inbox/>
        },
        {
            path: INBOX_PANEL,
            element: <SingleChat/>
        },

    ]
};