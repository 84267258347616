import './App.scss';
import { useRoutes } from 'react-router-dom';
import { allRoutes} from './routes';
import {Suspense, useEffect} from 'react';
import {hotjar} from "react-hotjar";
import {GTM_ID, HJID, HJSV} from "./utils/CommonConstants";
import TagManager from 'react-gtm-module'

const App: React.FC = (): JSX.Element => {
  const tagManagerArgs = {
    gtmId: GTM_ID,
    dataLayerName: 'PageDataLayer'
  }
  
  TagManager.initialize(tagManagerArgs)
  useEffect(() => {
    hotjar.initialize(HJID, HJSV);
  },[])
  const routing = useRoutes(allRoutes);
  return <>
    <Suspense>
      {routing}
    </Suspense>
  </>;
}

export default App;
