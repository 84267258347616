import {useEffect, useRef, useState} from "react";
import {useSignUpMsg} from "../../../context/useSignUpMsg";
import {getCookie, prepareAllErrorMsg, setCookie} from "../../../services/RecruiterAuthenticationService";
import {
    checkIfBangladeshiNumber, fetchClassForProfilePart,
    getCountries,
    getEmployeeRanges,
    getRecruiterProfile,
    saveRecruiterProfile, submitRecruiterProfile
} from "../../../services/RecruiterProfileService";
import {ReactSelect} from "../../../types/ReactSelect";
import {Country} from "../../../types/Country";
import {Recruiter} from "../../../types/Recruiter";
import {getWorkTypes} from "../../../services/SalaryDetailsService";
import {WorkType} from "../../../types/WorkType";
import {useAuthContext} from "../../../context/useAuthContext";
import {
    InvalidYearErrorMsg,
    NonBdNumberErrorMsg, ProfileMessageShowDuration,
    RecruiterNewProfileAnnouncementCookieKey,
    RecruiterProfileAnnouncementCookieKey,
    RecruiterProfileAnnouncementsActiveClass,
    RequiredFieldCommonErrorMsg
} from "../../../utils/CommonConstants";
import {useRecruiter} from "../../../context/useRecruiter";

const useRecruiterProfile = () => {
    let activeClass = RecruiterProfileAnnouncementsActiveClass;
    let inActiveClass = "tab-pane fade show pt-0 ";
    let recruiterObject = new Recruiter();
    const {dispatch}:any= useAuthContext();
    const d = new Date();
    let year = d.getFullYear();

    const showSignUpMsg = useSignUpMsg((state:any) => state.show);
    const clearSignUpMsg = useSignUpMsg((state:any) => state.clearMsg);
    const setShowNewRecruiterAnnouncement = useRecruiter((state:any) => state.setShowNewRecruiterAnnouncement);
    const [error,setError] = useState('');
    const [successMsg,setSuccessMsg] = useState('');
    const [isLoading,setIsLoading] = useState(false);
    const [isSubmitLoading,setIsSubmitLoading] = useState(false);
    const [recruiter,setRecruiter] = useState(recruiterObject);
    const [showSubmissionModal,setShowSubmissionModal] = useState(false);
    const [hideSubmitBtn,setHideSubmitBtn] = useState(true);

    const [classForAnnouncement,setClassForAnnouncement] = useState(activeClass);
    const [classForNewProfileAnnouncement,setClassForNewProfileAnnouncement] = useState(activeClass);
    const [name, setName] = useState("");
    const [nameInHeading, setNameInHeading] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [countries, setCountries] = useState<Country[]>();
    const [companyTypes, setCompanyTypes] = useState<WorkType[]>([]);
    const [employeeRanges, setEmployeeRanges] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState<any>(null);
    const [linkedinProfile, setLinkedinProfile] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [whatsappNumber, setWhatsappNumber] = useState<any>("");
    const [sameAsMobileNumber, setSameAsMobileNumber] = useState(false);
    const [companyName, setCompanyName] = useState("");
    const [companySite, setCompanySite] = useState("");
    const [companyCountry, setCompanyCountry] = useState("");
    const [selectedCompanyCountry, setSelectedCompanyCountry] = useState(null);
    const [companyFoundingYear, setCompanyFoundingYear] = useState("");
    const [companyNumberOfEmployees, setCompanyNumberOfEmployees] = useState("");
    const [companyType, setCompanyType] = useState("");
    const [companyDescription, setCompanyDescription] = useState("");
    const [companyOpportunityDescription, setCompanyOpportunityDescription] = useState("");
    const [selectedCompanyType, setSelectedCompanyType] = useState(null);
    const [selectedEmployeeRange, setSelectedEmployeeRange] = useState(null);

    const [nameError,setNameError] = useState<string|null>(null);
    const nameInputRef = useRef<any>(null);

    const [jobTitleError,setJobTitleError] = useState<string|null>(null);
    const jobTitleInputRef = useRef<any>(null);

    const [linkedInpProfileError,setLinkedInpProfileError] = useState<string|null>(null);
    const linkedInpProfileInputRef = useRef<any>(null);

    const [mobileNumberError,setMobileNumberError] = useState<string|null>(null);
    const mobileNumberInputRef = useRef<any>(null);

    const [whatsAppNumberError,setWhatsAppNumberError] = useState<string|null>(null);
    const whatsAppNumberInputRef = useRef<any>(null);

    const [companyNameError,setCompanyNameError] = useState<string|null>(null);
    const companyNameInputRef = useRef<any>(null);

    const [companySiteError,setCompanySiteError] = useState<string|null>(null);
    const companySiteInputRef = useRef<any>(null);

    const [companyFoundingYearError,setCompanyFoundingYearError] = useState<string|null>(null);
    const companyFoundingYearInputRef = useRef<any>(null);

    const [companyDescriptionError,setCompanyDescriptionError] = useState<string|null>(null);
    const companyDescriptionInputRef = useRef<any>(null);


    const [countryError,setCountryError] = useState<string|null>(null);
    const countryInputRef = useRef<any>(null);

    const [companyCountryError,setCompanyCountryError] = useState<string|null>(null);
    const companyCountryInputRef = useRef<any>(null);

    const [companyNumberOfEmployeeError,setCompanyNumberOfEmployeeError] = useState<string|null>(null);
    const companyNumberOfEmployeeInputRef = useRef<any>(null);

    const [companyTypeError,setCompanyTypeError] = useState<string|null>(null);
    const companyTypeInputRef = useRef<any>(null);


    const scrollTo = (ref:any) => {
        if (ref && ref.current /* + other conditions */) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }


    const getAllCountries = async () => {
        let dataFromAPI = await getCountries();
        if (dataFromAPI && dataFromAPI.data) {
            let options: any = [];
            dataFromAPI.data.map((item: any) => {
                let itemArr = new ReactSelect();
                itemArr["value"] = item.id;
                itemArr["label"] = item.name;
                options.push(itemArr);
            });
            setCountries(options);
        }
    }

    const getAllEmployeeRanges = async () => {
        let dataFromAPI = await getEmployeeRanges();
        if (dataFromAPI && dataFromAPI.data) {
            let options: any = [];
            dataFromAPI.data.map((item: any) => {
                let itemArr = new ReactSelect();
                itemArr["value"] = item.id;
                itemArr["label"] = item.name;
                options.push(itemArr);
            });
            setEmployeeRanges(options);
        }
    }

    const getAllCompanyTypes = async () => {
        const dataFromAPI = await getWorkTypes();
        let companyTypesOptions: WorkType[] = [];
        if (dataFromAPI && dataFromAPI.data) {
            dataFromAPI.data.map((item: any) => {
                let temp: any = {};
                temp.value = item.id;
                temp.label = item.name;
                companyTypesOptions.push(temp);
            });
            setCompanyTypes(companyTypesOptions);
        } else {
            console.log("Something went wrong");
        }
    };

    const getProfileData = async () => {
        const apiResult = await getRecruiterProfile();
        if (apiResult && apiResult.data && apiResult.data.profile) {
            let rejectionReason = apiResult.data.rejection_reason ?? '';
            let rejectionId = apiResult.data.rejection_id ?? '';
            let res = apiResult.data.profile;
            let userData = apiResult.data.user;
            setRecruiter(res);
            setName(res.name??'')
            setNameInHeading(res.name ?  res.name:'Recruiter');
            setJobTitle(res.jobTitle??'')
            setSelectedCountry(res.recruiterCountry);
            setLinkedinProfile(res.linkedinProfile??'')
            setMobileNumber(res.mobileNumber??'')
            if(res.sameAsMobile == 1) {
                setSameAsMobileNumber(true);
                setWhatsappNumber(res.mobileNumber??'');
            }else{
                setWhatsappNumber(res.whatsappNumber??'')
            }
            setCompanyName(res.companyName ?? '');
            setCompanySite(res.companySite ?? '');
            setSelectedCompanyCountry(res.recruiterCompanyCountry);
            setCompanyFoundingYear(res.companyFoundingYear ?? '');
            setSelectedEmployeeRange(res.recruiterCompanyEmployeeRange);
            setSelectedCompanyType(res.recruiterCompanyType);
            setCompanyDescription(res.companyDescription ?? '');
            setCompanyOpportunityDescription(res.companyOpportunityDescription ?? '');

            if(userData.status!='verified') {
                setHideSubmitBtn(false);
            }
            //updating the latest status after submission
            let userObjectFromStorage = localStorage.getItem('user') ?? '';
            if(userObjectFromStorage){
                let useInJson =  JSON.parse(userObjectFromStorage);
                useInJson = {...useInJson,
                    status:userData.status,
                    name:res.name,
                    first_letter: (res.name == '--' || !res.name) ? 'R':res.name.toUpperCase().charAt(0),
                    rejectionReason: rejectionReason,
                    rejectionId: rejectionId
                };
                localStorage.setItem('user',JSON.stringify(useInJson));
                dispatch({type:'LOGIN',payload:useInJson});
            }

        }
    }

    useEffect(()=>{

        getAllCountries();
        getAllEmployeeRanges();
        getAllCompanyTypes();
        getProfileData();
    },[])

    const handleName = (event:any) => {
        setNameError('');
        recruiter.name = event.target.value;
        setRecruiter(recruiter);
        setName(event.target.value);
    }

    const handleJobTitle = (event:any) => {
        setJobTitleError('');
        setJobTitle(event.target.value);
        recruiter.jobTitle = event.target.value;
        setRecruiter(recruiter);
    }

    const handleCountry = (value:any) => {
        setCountryError('');
        setSelectedCountry(value);
        recruiter.countryId = value?.value ?? '';
        setRecruiter(recruiter)
    }

    const handleLinedInProfile = (event:any) => {
        setLinkedInpProfileError('');
        setLinkedinProfile(event.target.value);
        recruiter.linkedinProfile = event.target.value;
        setRecruiter(recruiter);
    }

    const handleMobileNumber = (event:any) => {
        let value = event.target.value;
        setMobileNumberError('');

        if(selectedCountry?.label=='Bangladesh' && value.length>=11){
            if(!checkIfBangladeshiNumber(value)){
                setMobileNumberError(NonBdNumberErrorMsg);
                setMobileNumber(value);
                return false;
            }
        }
        setMobileNumber(event.target.value);
        recruiter.mobileNumber = event.target.value;
        setRecruiter(recruiter);
    }

    const handleWhatsAppNumber = (event:any) => {
        let value = event.target.value;
        setWhatsAppNumberError('');
        if(selectedCountry?.label=='Bangladesh' && value.length>=11){
            if(!checkIfBangladeshiNumber(value)){
                setWhatsAppNumberError(NonBdNumberErrorMsg);
                setWhatsappNumber(event.target.value);
                return false;
            }
        }
        setWhatsappNumber(event.target.value);
        recruiter.whatsappNumber = event.target.value;
        setRecruiter(recruiter);
    }

    const handleSameAsMobileNumber = (event: any) => {
        setWhatsAppNumberError('');
        if (event.target.checked) {
            setWhatsappNumber(mobileNumber);
            setSameAsMobileNumber(true);
            setError("");
        } else {
            setWhatsappNumber("");
            setSameAsMobileNumber(false);
            recruiter.whatsappNumber = "";

        }
        recruiter.sameAsMobile = event.target.checked;
        setRecruiter(recruiter);
    };

    const handleCompanyName = (event:any) => {
        setCompanyNameError('');
        setCompanyName(event.target.value);
        recruiter.companyName = event.target.value;
        setRecruiter(recruiter);
    }

    const handleCompanySite = (event:any) => {
        setCompanySiteError('');
        setCompanySite(event.target.value);
        recruiter.companySite = event.target.value;
        setRecruiter(recruiter);
    }

    const handleCompanyCountry = (value:any) => {
        setCompanyCountryError('');
        setSelectedCompanyCountry(value);
        recruiter.companyCountry = value?.value ?? '';
        setRecruiter(recruiter)
    }

    const handleCompanyFoundingYear = (event:any) => {
        let value = event.target.value;
        setCompanyFoundingYearError('');
        //For checking if entered value is greater than current year or not
        if(value>0 ) {
            if(value.length>4 || value>year) {
                setCompanyFoundingYearError('Can not be greater than current year');
                return false;
            }
        }
        setCompanyFoundingYear(value);
        recruiter.companyFoundingYear = value;
        setRecruiter(recruiter);
    }

    const handleNumberOfEmployee = (value:any) => {
        setCompanyNumberOfEmployeeError('');
        setSelectedEmployeeRange(value);
        recruiter.companyNumberOfEmployees = value?.value ?? '';
        setRecruiter(recruiter)
    }

    const handleCompanyType = (value:any) => {
        setCompanyTypeError('');
        setSelectedCompanyType(value);
        recruiter.companyType = value?.value ?? '';
        setRecruiter(recruiter)
    }

    const handleCompanyDescription = (event:any) => {
        setCompanyDescriptionError('');
        setCompanyDescription(event.target.value);
        recruiter.companyDescription = event.target.value;
        setRecruiter(recruiter);
    }


    const handleCompanyOpportunityDescription= (event:any) => {
        setCompanyOpportunityDescription(event.target.value);
        recruiter.companyOpportunityDescription = event.target.value;
        setRecruiter(recruiter);
    }

    const saveProfile = async () => {
        setError('');
        setSuccessMsg('');
        resetErrorMessages();
        //check if country bd , if yeas must be 11 digigt
        if(selectedCountry?.label=='Bangladesh'){
            if(recruiter.mobileNumber.length>0 && !checkIfBangladeshiNumber(mobileNumber)){
                scrollTo(mobileNumberInputRef);
                setMobileNumberError(NonBdNumberErrorMsg);
                return false;
            }
            if(whatsappNumber.length>0 && !checkIfBangladeshiNumber(whatsappNumber)){
                setWhatsAppNumberError(NonBdNumberErrorMsg);
                scrollTo(whatsAppNumberInputRef);
                return false;
            }

        }

        //check if founding year entered or not
        //If entered , must be valid year
        if(companyFoundingYear.length>0) {
            if(!validateYear(parseInt(companyFoundingYear),year)) {
                setCompanyFoundingYearError(InvalidYearErrorMsg);
                scrollTo(companyFoundingYearInputRef);
                return false;
            }
        }
        setIsLoading(true);
        let response = await saveRecruiterProfile(recruiter);
        if(response && response.success){
            setIsLoading(false);
            setSuccessMsg('Profile Saved Successfully');
        }else{
            setIsLoading(false);
            let errorText = prepareAllErrorMsg(response);
            setError(errorText);
        }
        clearMessages();
    }

    const saveAndSubmitProfile = async () => {
        setIsSubmitLoading(true);
        setError('');
        setSuccessMsg('');
        setShowSubmissionModal(false);
        let response = await submitRecruiterProfile(recruiter);
        if(response && response.success && response.data){
            setIsSubmitLoading(false);
            setSuccessMsg('Profile Saved Successfully');
            //updating the latest status after submission
            let userObjectFromStorage = localStorage.getItem('user') ?? '';
            if(userObjectFromStorage){
                let userInJson =  JSON.parse(userObjectFromStorage);
                userInJson = {...userInJson,status:response.data.status};
                localStorage.setItem('user',JSON.stringify(userInJson));
                dispatch({type:'LOGIN',payload:userInJson});
            }
            setCookie(RecruiterNewProfileAnnouncementCookieKey, "set", 1365);
            setClassForAnnouncement(fetchClassForProfilePart());
            setShowNewRecruiterAnnouncement(false);
        }else{
            setIsSubmitLoading(false);
            let errorText = prepareAllErrorMsg(response);
            setError(errorText);
        }
        clearMessages();
    }

    const resetErrorMessages = () => {
        setError('');
        setSuccessMsg('');
        setNameError('');
        setJobTitleError('');
        setLinkedInpProfileError('');
        setMobileNumberError('');
        setWhatsAppNumberError('');
        setCompanyNameError('');
        setCompanySiteError('');
        setCompanyFoundingYearError('');
        setCompanyDescriptionError('');
        setCountryError('');
        setCompanyCountryError('');
        setCompanyNumberOfEmployeeError('');
        setCompanyTypeError('');
    }

    const validateFields = (e:any) => {
        e.preventDefault();
        let proceed = true;
        resetErrorMessages();
        if(!name || name=='' || !nameInputRef.current.value){
            setNameError(RequiredFieldCommonErrorMsg);
            scrollTo(nameInputRef);
            proceed= false;
        }

        if(!recruiter.jobTitle || recruiter.jobTitle=='' || !jobTitleInputRef.current.value){
            setJobTitleError(RequiredFieldCommonErrorMsg);
            scrollTo(jobTitleInputRef);
            proceed= false;
        }

        if(!recruiter.countryId){
            setCountryError(RequiredFieldCommonErrorMsg);
            scrollTo(countryInputRef);
            proceed= false;
        }

        if(selectedCountry?.label=='Bangladesh'){
            if(recruiter.mobileNumber.length>0){
                if(!checkIfBangladeshiNumber(mobileNumber)){
                    setMobileNumberError(NonBdNumberErrorMsg);
                    scrollTo(mobileNumberInputRef);
                    proceed= false;
                }
            }

            if(whatsappNumber.length>0){
                if(!checkIfBangladeshiNumber(whatsappNumber)){
                    setWhatsAppNumberError(NonBdNumberErrorMsg);
                    scrollTo(whatsAppNumberInputRef);
                    proceed= false;
                }
            }

        }


        if(!companyName || companyName=='' || !companyNameInputRef.current.value){
            setCompanyNameError(RequiredFieldCommonErrorMsg);
            scrollTo(companyNameInputRef);
            proceed= false;
        }

        if(!companySite || companySite =='' || !companySiteInputRef.current.value){
            setCompanySiteError(RequiredFieldCommonErrorMsg);
            scrollTo(companySiteInputRef);
            proceed= false;
        }

        if(!recruiter.companyCountry){
            setCompanyCountryError(RequiredFieldCommonErrorMsg);
            scrollTo(companyCountryInputRef);
            proceed= false;
        }

        if(!companyFoundingYear || companyFoundingYear=='' || !companyFoundingYearInputRef.current.value){
            setCompanyFoundingYearError(RequiredFieldCommonErrorMsg);
            scrollTo(companyFoundingYearInputRef);
            proceed= false;
        }else{
            if(!validateYear(parseInt(companyFoundingYear),year)) {
                setCompanyFoundingYearError(InvalidYearErrorMsg);
                scrollTo(companyFoundingYearInputRef);
                proceed= false;
            }
        }


        if(!recruiter.companyNumberOfEmployees){
            setCompanyNumberOfEmployeeError(RequiredFieldCommonErrorMsg);
            scrollTo(companyNumberOfEmployeeInputRef);
            proceed= false;
        }


        if(!recruiter.companyType){
            setCompanyTypeError(RequiredFieldCommonErrorMsg);
            scrollTo(companyTypeInputRef);
            proceed= false;
        }

        if(!companyDescription || companyDescription=='' || !companyDescriptionInputRef.current.value){
            setCompanyDescriptionError(RequiredFieldCommonErrorMsg);
            scrollTo(companyDescriptionInputRef);
            proceed= false;
        }

        if(!proceed){
            return false;
        }
       setShowSubmissionModal(true);
    }

    const validateYear = (inputYear:number,maxYear:number) => {
        if(inputYear < 1900 || inputYear > maxYear) {
            return false;
        }
        return true;
    }

    const clearMessages = () => {
        setTimeout(()=>{
            setError('');
            setSuccessMsg('');
        },ProfileMessageShowDuration);
    }

    return {activeClass,showSignUpMsg,clearSignUpMsg,classForAnnouncement,setClassForAnnouncement,setClassForNewProfileAnnouncement,
        name,handleName,countries,jobTitle,handleJobTitle,linkedinProfile,handleLinedInProfile,mobileNumber,handleMobileNumber,
        whatsappNumber,handleWhatsAppNumber,sameAsMobileNumber,handleSameAsMobileNumber,recruiter,handleCountry,
        saveProfile,error,successMsg,isLoading,companyName,handleCompanyName,companySite,handleCompanySite,
        companyFoundingYear,handleCompanyFoundingYear, companyDescription,handleCompanyDescription,
        companyOpportunityDescription,handleCompanyOpportunityDescription,handleCompanyCountry,companyTypes,handleCompanyType,
        companyNumberOfEmployees,employeeRanges,handleNumberOfEmployee,selectedCountry,selectedCompanyCountry,
        selectedEmployeeRange,selectedCompanyType,saveAndSubmitProfile, nameError,validateFields,
        nameInputRef, jobTitleError,jobTitleInputRef, linkedInpProfileError,linkedInpProfileInputRef,
        mobileNumberError,mobileNumberInputRef,whatsAppNumberError,whatsAppNumberInputRef,
        companyNameError,companyNameInputRef,companySiteError,companySiteInputRef,companyFoundingYearError,
        companyFoundingYearInputRef,companyDescriptionError,companyDescriptionInputRef,
        countryError,countryInputRef,companyCountryError,companyCountryInputRef,companyNumberOfEmployeeError,
        companyNumberOfEmployeeInputRef,companyTypeError,companyTypeInputRef,showSubmissionModal,setShowSubmissionModal,
        isSubmitLoading,hideSubmitBtn,nameInHeading
    };
}

export default useRecruiterProfile;