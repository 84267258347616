import React from "react";

const ExpectedSalary = (props:any) => {
    let expectedSalary = props.expectedSalary;
    return (
        <>
            <p className="mb-0 ">
                    <span className="text-white pe-2 fs-3">
                      {" "}
                        {expectedSalary}{" "}
                    </span>
                <span style={{ color: "#F9FAFB" }}>BDT/month</span>
            </p>
        </>
    );
}

export default ExpectedSalary;