import avater from "../../../assets/images/user-avater.png";
import React, {useEffect, useRef} from "react";
import {useMessenger} from "../../../context/useMessenger";
import Moment from "react-moment";
import Linkify from 'linkify-react';

const SingleMessageItem = ({messages}:any) => {
    const chatOwner = useMessenger((state:any)=>state.chatOwner);
    const selectedChatUserInfo = useMessenger((state:any)=>state.selectedChatUserInfo);
    let headerName = selectedChatUserInfo?.opponent?.chat_box_name ?? '';
    let singleMsgRef = useRef(null);
    let isOwnerMessage = false;
    let createdAt = messages.created_at;


    if(messages.sender==chatOwner){
        isOwnerMessage= true;
    }

    const scrollTo = (ref:any) => {
        if (ref && ref.current /* + other conditions */) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }

    const calendarStrings = {
        lastDay : '[Yesterday] LT',
        sameDay : '[Today] LT',
        nextDay : '[Tomorrow] LT',
        lastWeek : '[Last] dddd LT',
        nextWeek : 'dddd LT',
        sameElse : 'L'
    };

    return (
        <>
            <div ref={singleMsgRef} className= {isOwnerMessage ? "d-flex flex-row-reverse mb-2":"d-flex"}>
                {!isOwnerMessage ?
                <img
                    src={avater}
                    alt=""
                    style={{ height: "40px", width: "40px" }}
                />
                    :''
                }
                <div className="chat-box">
                    <div className={isOwnerMessage ? "d-flex justify-content-between" :"d-flex justify-content-between ps-2"}>
                        <p className="mb-0 small">
                            {isOwnerMessage ? "You": headerName }
                        </p>
                        <p className="mb-0 mb-0 fs-12 text-gray align-self-end">
                            <Moment calendar={calendarStrings}>{createdAt}</Moment>
                        </p>
                    </div>
                    <div className={isOwnerMessage ? "right-chat-message fs-13 mb-2":"left-chat-message fs-13 mb-2"}>
                        <p className="mb-0">
                            <Linkify>
                                {messages.content ?? ''}
                            </Linkify>

                        </p>
                    </div>
                </div>
            </div>






            {/*{ isOwnerMessage?
                (<div className="d-flex flex-row-reverse mb-2">
                <div className="chat-box">
                    <div className="d-flex justify-content-between">
                        <p className="mb-0 small">
                            Candidate #1920
                        </p>
                        <p className="mb-0 fs-12 text-gray align-self-end">
                            Today 2:20pm
                        </p>
                    </div>
                    <div className="right-chat-message fs-13 mb-2">
                        <div className="mb-0">
                            <div className="d-flex flex-row">
                                <p className="mb-0">
                                    Hey, Beate Lorem iconsectetur Lorem
                                    ipsum dolor sit amet consectetur
                                    adipisicing elit. Fugiat, aliquam!
                                    Eaque, corporis.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>):
                (<div className="d-flex">
                    <img
                        src={avater}
                        alt=""
                        style={{ height: "40px", width: "40px" }}
                    />
                    <div className="chat-box">
                        <div className="d-flex justify-content-between ps-2">
                            <p className="mb-0 small">
                                Candidate #1920
                            </p>
                            <p className="mb-0 mb-0 fs-12 text-gray align-self-end">
                                Today 2:20pm
                            </p>
                        </div>
                        <div className="left-chat-message fs-13 mb-2">
                            <p className="mb-0">
                                Hi, Quan Lorem ipsum dolor sit amet
                                consectetur adipisicing elit. Nihil, vero?
                            </p>
                        </div>
                    </div>
                </div>)
            }*/}


        </>
    );
}
export default SingleMessageItem;