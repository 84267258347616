import React from "react";

type props = {
    text: string,
    disable: boolean|null
}
function DisableEnableButton(props: props) {
    const text = props.text;
    const isDisabled = props.disable;

    return (
        <>
            { isDisabled     ?

                <button
                    type="button"
                    className="btn btn-primary w-100"
                    style={{
                        background: '#e9d7fe',
                        border: '1px solid #e9d7fe',
                        cursor: 'not-allowed'
                    }}
                    disabled
                >
                    Processing..
                </button>

                :

                <button className="btn btn-primary w-100" type="submit">
                    {text}
                </button>
            }

        </>

    );
}

export default DisableEnableButton;