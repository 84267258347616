const PassiveJobSeekerBadge = () => {

    // let style={
    //     color: '#B93815',
    //     textAlign: 'center',
    //     fontFamily: 'Inter',
    //     fontSize: '14px',
    //     fontStyle: 'normal',
    //     fontWeight: '500',
    //     lineHeight: '20px' /* 142.857% */
    // }
    return (
        <>
            <span className="align-self-center small fw-semibold px-3 py-1 profile-review">
                Passive job seekers
            </span>
        </>
    );
}

export default PassiveJobSeekerBadge;