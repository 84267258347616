import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AppStorageService} from "../../services/AppStorageService";
import {getAllSkills, getExperiences} from "../../services/SkillService";

export default function useHome() {
    const [isClearable, setIsClearable] = useState(true);
    const [isSearchable, setIsSearchable] = useState(true);

    return [isClearable,isSearchable];
}