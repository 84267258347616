const PrivacyPolicy = () => {
    return (
        <>
            <div className="container spacious-section pt-6">
                <h4 className="h3 fw-semibold mb-6 mb-md-6">Privacy Policy</h4>
                <div className="terms-conditions-privacy-holder">
                    <section className="mb-7 pb-5">
                        <div className="main-section d-flex mb-4">
                            <div>
                                <p className="fw-semibold mb-0">INTRODUCTION</p>
                                <p className="sub-section mb-0">
                                    This privacy policy describes how we collect, use, and protect your
                                    personal information when you use RemoteDevs.com ("the Website").
                                </p>
                                <p className="sub-section mb-0">
                                    By using the Website, you agree to be bound by this privacy policy.
                                    If you do not agree with this privacy policy, you should not use the
                                    Website.
                                </p>
                            </div>
                        </div>
                        <div className="main-section d-flex mb-4">
                            <div>
                                <p className="fw-semibold mb-0">COLLECTION OF PERSONAL INFORMATION</p>
                                <p className="sub-section mb-0">
                                    We collect personal information when you use the Website, including
                                    but not limited to your name, email address, work experience,
                                    education, and contact information.
                                </p>
                                <p className="sub-section mb-0">
                                    We may also collect information about your use of the Website,
                                    including but not limited to your IP address, browser type, device
                                    type, and pages visited.
                                </p>
                                <p className="sub-section mb-0">
                                    We may monitor and review any communications that take place on our
                                    site, including messages between users, exclusively to ensure
                                    compliance with our terms and conditions and to protect the security
                                    and integrity of our site. By using our site, you consent to such
                                    monitoring and review.
                                </p>
                            </div>
                        </div>
                        <div className="main-section d-flex mb-4">
                            <div>
                                <p className="fw-semibold mb-0">USE OF PERSONAL INFORMATION</p>
                                <p className="sub-section mb-0">
                                    We use your personal information to provide you with the services
                                    offered by the Website, including but not limited to salary
                                    evaluation and job offers.
                                </p>
                                <p className="sub-section mb-0">
                                    We may also use your personal information to communicate with you
                                    about the Website, including but not limited to updates, promotions,
                                    and other marketing communications.
                                </p>
                                <p className="sub-section mb-0">
                                    We may use your personal information for internal purposes,
                                    including but not limited to improving the Website and analyzing
                                    user behavior.
                                </p>
                            </div>
                        </div>
                        <div className="main-section d-flex mb-4">
                            <div>
                                <p className="fw-semibold mb-0">DISCLOSURE OF PERSONAL INFORMATION</p>
                                <p className="sub-section mb-0">
                                    We may disclose your personal information to recruiters and
                                    employers who post job offers on the Website.
                                </p>
                                <p className="sub-section mb-0">
                                    We may disclose your personal information to third-party service
                                    providers who help us provide the services offered by the Website,
                                    including but not limited to hosting and data analysis.
                                </p>
                                <p className="sub-section mb-0">
                                    We may disclose your personal information as required by law or to
                                    protect our rights, property, or safety, or the rights, property, or
                                    safety of others.
                                </p>
                            </div>
                        </div>
                        <div className="main-section d-flex mb-4">
                            <div>
                                <p className="fw-semibold mb-0">SECURITY OF PERSONAL INFORMATION</p>
                                <p className="sub-section mb-0">
                                    We take reasonable measures to protect your personal information
                                    from unauthorized access, use, or disclosure.
                                </p>
                                <p className="sub-section mb-0">
                                    However, no method of transmission over the Internet or electronic
                                    storage is 100% secure. Therefore, we cannot guarantee the absolute
                                    security of your personal information.
                                </p>
                            </div>
                        </div>
                        <div className="main-section d-flex mb-4">
                            <div>
                                <p className="fw-semibold mb-0">COOKIES</p>
                                <p className="sub-section mb-0">
                                    We use cookies to enhance your experience on the Website.
                                </p>
                                <p className="sub-section mb-0">
                                    Cookies are small text files that are stored on your device when you
                                    use the Website. We use cookies to identify your device, personalize
                                    your experience, and improve the Website.
                                </p>
                                <p className="sub-section mb-0">
                                    You may disable cookies by changing your browser settings, but this
                                    may limit your use of the Website.
                                </p>
                            </div>
                        </div>
                        <div className="main-section d-flex mb-4">
                            <div>
                                <p className="fw-semibold mb-0">THIRD-PARTY WEBSITES</p>
                                <p className="sub-section mb-0">
                                    The Website may contain links to third-party websites.
                                </p>
                                <p className="sub-section mb-0">
                                    We are not responsible for the privacy practices or content of
                                    third-party websites.
                                </p>
                                <p className="sub-section mb-0">
                                    We encourage you to review the privacy policies of third-party
                                    websites before using them.
                                </p>
                            </div>
                        </div>
                        <div className="main-section d-flex mb-4">
                            <div>
                                <p className="fw-semibold mb-0">CHANGES TO THIS PRIVACY POLICY</p>
                                <p className="sub-section mb-0">
                                    We may update this privacy policy from time to time by posting the
                                    updated policy on the Website.
                                </p>
                                <p className="sub-section mb-0">
                                    You are responsible for reviewing the updated privacy policy.
                                </p>
                            </div>
                        </div>
                        <div className="main-section d-flex mb-4">
                            <div>
                                <p className="fw-semibold mb-0">CONTACT US</p>
                                <p className="sub-section mb-0">
                                    If you have any questions or concerns about this privacy policy,
                                    please contact us.
                                </p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}
export default PrivacyPolicy;