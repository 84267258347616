import {useEffect, useRef, useState} from "react";
import {scrollTo, searchCandidateProfile} from "../../../services/RecruiterProfileService";
import {CandidateSearch} from "../../../types/CandidateSearch";
import {getEnglishLevels} from "../../../services/SalaryDetailsService";
import {AppStorageService} from "../../../services/AppStorageService";
import {getAllSkills, getExtraSkill} from "../../../services/SkillService";
import {ReactSelect} from "../../../types/ReactSelect";

import {useSearchTab} from "../../../context/useSearchTab";

const useSearchCandidate = () => {
    let storage = new AppStorageService();
    const shouldShowSavedTabFromContext = useSearchTab((state:any) => state.isSavedTabActive);
    const showSavedTab = useSearchTab((state:any) => state.showSavedTab);
    const hideSavedTab = useSearchTab((state:any) => state.hideSavedTab);

    const [showAllCandidateTab,setShowAllCandidateTab ] = useState(!shouldShowSavedTabFromContext);
    const [candidates,setCandidates ] = useState<any>(null);
    const [totalCandidate,setTotalCandidate ] = useState(0);
    const [allDataFromApi,setAllDataFromApi ] = useState<any>(0);
    const [searchObject,setSearchObject ] = useState(new CandidateSearch());
    const [paginationCurrentPage,setPaginationCurrentPage ] = useState<any>(null);
    const [englishLevels, setEnglishLevels] = useState([]);
    const [extraSkill, setExtraSkill] = useState([]);
    const [skills, setSkills] = useState<any[]>([]);
    const [isFilterDataLoading, setIsFilterDataLoading] = useState(false);
    const [isResultLoaded, setIsResultLoaded] = useState(false);
    const [savedCandidateList, setSavedCandidateList] = useState<any>([]);
    const [showExtraSkillIgnoreMessage, setShowExtraSkillIgnoreMessage] = useState<any>(false);

    let headingRef = useRef<any>();
    let englishLevelsFromStorage = storage.getAllEnglishLevels();
    let extraSkillsFromStorage = storage.getAllExtraSkills();
    let allSkillsFromStorage = storage.getAllSkills();
    let filterData = storage.getAllSelectedFilterData();

    useEffect(()=>{
        if(shouldShowSavedTabFromContext) {
            if(filterData){
                searchSavedCandidate(filterData);
            }else {
                searchSavedCandidate({});
            }
        }else{
            if(filterData){
                searchCandidate(filterData);
            }else {
                searchCandidate({});
            }
        }

        if (englishLevelsFromStorage != null) {
            setEnglishLevels(englishLevelsFromStorage);
        } else {
            fetchEnglishLevels();
        }
        if (extraSkillsFromStorage != null) {
            setExtraSkill(extraSkillsFromStorage);
        } else {
            getExtraSkillFromServer();
        }

        if (allSkillsFromStorage && allSkillsFromStorage.length > 0) {
            setSkills(allSkillsFromStorage);
        } else {
            getSkills();
        }
    },[])

    useEffect(()=>{
        if(!showAllCandidateTab) {

        }
    },[showAllCandidateTab])




    const fetchEnglishLevels = async () => {
        const englishLevelsFromServer = await getEnglishLevels();
        let englishLevelOptions: any = [];
        if (englishLevelsFromServer && englishLevelsFromServer.data) {
            englishLevelsFromServer.data.map((item: any) => {
                let temp: any = {};
                temp.value = item.id;
                temp.label = item.name;
                temp.title = item.name;
                temp.subTitle = item.display;
                englishLevelOptions.push(temp);
            });
            storage.storeAllEnglishLevels(englishLevelOptions);
            setEnglishLevels(englishLevelOptions);
        } else {
            console.log("Something went wrong");
        }
    };

    let getExtraSkillFromServer = async () => {
        let dataFromAPI = await getExtraSkill("");
        if (dataFromAPI && dataFromAPI.data) {
            let options: any = [];
            dataFromAPI.data.map((item: any) => {
                let itemArr = new ReactSelect();
                itemArr["value"] = item.id;
                itemArr["label"] = item.name;
                options.push(itemArr);
            });
            storage.storeAllExtraSkills(options);
            setExtraSkill(options);
        }
    };

    const getSkills = async () => {
        const skillsFromServer = await getAllSkills();
        let skillOptions: any = [];
        if (skillsFromServer && skillsFromServer.data) {
            skillsFromServer.data.map((item: any) => {
                let temp: any = {};
                temp.value = item.id;
                temp.label = item.name;
                skillOptions.push(temp);
            });
            storage.storeAllSkills(skillOptions);
            setSkills(skillOptions);
            storage.storeSkillToMaxYearMapping(skillsFromServer.data);
        } else {
            console.log("No skills found from server");
        }
    };

    const searchSavedCandidate = async (pageObject:any) => {
        let newPageObject = {...pageObject};
        newPageObject.search_type = 'saved_list';
        await searchCandidate(newPageObject);
    }

    const searchCandidate = async (pageObject:any) => {
        let data = [];
        setAllDataFromApi([]);
        setCandidates(null);
        setTotalCandidate( 0);
        setIsResultLoaded(false);
        setSavedCandidateList([]);
        setShowExtraSkillIgnoreMessage(false);
        let currentPageFromStorage = sessionStorage.getItem('current_page') ?? '0';
        currentPageFromStorage = JSON.parse(currentPageFromStorage);
        let newPageObject = {...pageObject};
        newPageObject.page = currentPageFromStorage+1;

        const response = await searchCandidateProfile(newPageObject);
        if(response && response.data && response.data.length>0){
            let currentPageFromServer = response.current_page ? response.current_page-1: 0;
            setPaginationCurrentPage(currentPageFromServer);
            sessionStorage.setItem('current_page',JSON.stringify(currentPageFromServer));
            data = response.data;
            setAllDataFromApi(response);
            setCandidates(data);
            setTotalCandidate(response.total ?? 0);
            setShowExtraSkillIgnoreMessage(response.show_extra_skill_msg ?? false);

            //assign the saved candidate list to our state
            let newCandidateSavedList:any = [];
            data.forEach((item:any,index:any)=>{
                if(item.save_list && item.save_list.length>0) {
                    newCandidateSavedList.push(item);
                }
            })
            setSavedCandidateList(newCandidateSavedList);
        }else{
            console.log("no data found");
        }
        setIsFilterDataLoading(false);
        scrollTo(headingRef);
        setIsResultLoaded(true);
    }


    return {searchCandidate,showAllCandidateTab,setShowAllCandidateTab,candidates,totalCandidate,setTotalCandidate,
        allDataFromApi, searchObject,setSearchObject,paginationCurrentPage,setPaginationCurrentPage,englishLevels,
        extraSkill, filterData,headingRef,isFilterDataLoading,setIsFilterDataLoading,skills,isResultLoaded,
        savedCandidateList,setSavedCandidateList,searchSavedCandidate,showSavedTab,hideSavedTab,showExtraSkillIgnoreMessage};
}

export default useSearchCandidate;