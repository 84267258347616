import React from "react";
import { Link } from "react-router-dom";
import WhiteButton from "../../../../components/buttons/WhiteButton";
import message from "../../../../assets/images/icons/message-text-square-02.svg";
import job from "../../../../assets/images/icons/job.svg";
import sadFace from "../../../../assets/images/icons/sad-face.svg";
import messageBtn from "../../../../assets/images/icons/message-white.svg";
import CandidateReport from "../../../recruiter/candidate-profile/component/CandidateReport";
import ContactCandidate from "../../../recruiter/candidate-profile/component/ContactCandidate";
import country from "../../../../assets/images/icons/globe-2.svg";
import flag from "../../../../assets/images/icons/flag.svg";
import user from "../../../../assets/images/icons/users-2.svg";
import companyType from "../../../../assets/images/icons/company-type.svg";
import CompanyDetails from "./CompanyDetails";


const RecruiterDetails = (props:any) => {
  let extraDetails = props.extraDetails;
  let detailsFunctionalities = props.detailsFunctionalities;

  return (
      <>
        {extraDetails ?
    <div
      className="p-6  border border-primary  rounded-2"
      style={{ backgroundColor: "#F8FAFC" }}
    >
      <div className="row gy-4">
        <div className="col-12">
        </div>
        <CompanyDetails extraDetails={extraDetails}/>
      </div>
      <div className="pt-4">
        {/*<ContactCandidate detailsFunctionalities={detailsFunctionalities}/>*/}
        <CandidateReport detailsFunctionalities={detailsFunctionalities}/>
      </div>
    </div>
        : ''}
      </>
  );
};

export default RecruiterDetails;
