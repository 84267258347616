import {Link, matchPath, useLocation} from "react-router-dom";
import mainLogo from "../../../assets/images/logos/logo_w_title_purple.svg";
import RecruiterLoggedInMenu from "../../../components/header/RecruiterLoggedInMenu";
import {useAuthContext} from "../../../context/useAuthContext";
import {
    RECRUITER_CANDIDATE_PROFILE_WITH_USER_ID,
    RECRUITER_SEARCH_CANDIDATE,
    RECRUITER_SIGN_IN
} from "../../../utils/RouteNamingConfig";
import {useEffect, useState} from "react";
import {useRecruiterHeader} from "./useRecruiterHeader";
import {AppStorageService} from "../../../services/AppStorageService";
import CandidateLoggedInMenu from "../../../components/header/CandidateLoggedInMenu";
import {RecruiterSignInButtonHideArray} from "../../../utils/CommonConstants";
import mainLogoSm from "../../../assets/images/logos/logo_w_title_purple.svg";
import close from "../../../assets/images/icons/x-close-gray.svg";
import hamburger from "../../../assets/images/icons/hamburger-purple.svg";
import SweetAlert2 from "react-sweetalert2";
import {useSignUpMsg} from "../../../context/useSignUpMsg";
import {useGlobalErrorMsg} from "../../../context/useGlobalErrorMsg";

const RecruiterHeader = () => {
    const [isShown, setIsShown] = useState(false);
    let {user}:any = useAuthContext();
    let recruiter = user;
    const {loginErrorMsg,setLoginErrorMsg,renderGoogleLoginButtonAuto,renderGoogleLoginButtonOnClick,swalProps,setSwalProps} = useRecruiterHeader();
    let storage = new AppStorageService();
    const location = useLocation();
    const currentRoute = location.pathname;
    let hideSignInButton = false;
    let isCandidateLoggedIn = storage.userLoggedIn();
    let candidateDetails = {};

    useEffect(() => {
        const script = document.createElement("script");

        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        script.defer = true;

        document.body.appendChild(script);
        renderGoogleLoginButtonAuto('googleLoginBtnFromRecruiterHeader');


    }, []);

    if (isCandidateLoggedIn) {
        candidateDetails = storage.getUserDetails();
    }

    if(RecruiterSignInButtonHideArray.includes(currentRoute.toLowerCase())){
        hideSignInButton = true;
    }

    if(currentRoute!==RECRUITER_SEARCH_CANDIDATE &&
        !matchPath(RECRUITER_CANDIDATE_PROFILE_WITH_USER_ID,currentRoute.toLowerCase()) ){
        storage.clearAllSelectedFilterData();
        sessionStorage.removeItem('current_page');
    }

    const handleBurgerMenu = (e:any) => {
        setIsShown((e) => !e);
        setTimeout(()=>{
            renderGoogleLoginButtonOnClick('burger_menu_google_login_btn_recruiter')
        },30)
    }

    let mainLogoLink = "/";

    if(recruiter) {
        mainLogoLink = RECRUITER_SEARCH_CANDIDATE;
    }


  return (
    <>
        <nav className="bg-white navbar navbar-expand-lg py-6 border-bottom">
        <div className="container px-md-0">
          <Link to={mainLogoLink} className="navbar-brand py-0">
            <img src={mainLogo} className="img-fluid" alt="Remote Devs" />
          </Link>

            {hideSignInButton || isCandidateLoggedIn || recruiter? (
                    " "
                ) :(
                    <>
                <button
                    className="btn d-inline-block d-lg-none"
                    onClick={(e) => handleBurgerMenu(e)}
                >
                    <img src={hamburger} alt="" />
                </button>
                <div className="collapse navbar-collapse" id="navbarScroll">
                <div className="navbar-nav ms-auto my-2 my-lg-0">
                    {/*<Link
                        to={RECRUITER_SIGN_IN}
                        className="btn"
                        style={{ color: "#7f54df", backgroundColor: "#F9F5FF" }}
                    >
                        Sign in as recruiter
                    </Link>*/}
                    <li className="nav-item ms-2" style={{ listStyle:'none',position: "relative" }}>
                        <a
                            className="btn"
                            style={{ color: "white", backgroundColor: "#7f54df" }}
                        >
                            Sign in as candidate
                        </a>
                        <button
                            className="btn btn-secondary w-100"
                            id="googleLoginBtnFromRecruiterHeader"
                            style={{
                                padding: 0,
                                border: "1px solid #d0d5dd",
                                textAlign: "center",
                                margin: "0 auto",
                                background: "#ffffff",
                                position: "absolute",
                                left: 0,
                                opacity: 0,
                            }}
                        >
                            Sign up with Google
                        </button>
                    </li>


                </div>
                </div>
                    </>
            )
            }

            { recruiter ? <RecruiterLoggedInMenu user={recruiter}/> : ''}
            { isCandidateLoggedIn ? <CandidateLoggedInMenu user={candidateDetails} /> : '' }

        </div>
      </nav>
        {isShown && (
            <div className="position-fixed z-3 bg-white w-100 top-0">
                <div className="container">
                    <div className="py-4 d-flex justify-content-between">
                        <img src={mainLogoSm} alt="" />
                        <img className="cursor-pointer" src={close} alt="" onClick={() => setIsShown((e) => !e)} />
                    </div>
                    <div className="py-6 ">
                        <a
                            id="div_tag_id"
                            className="btn w-100"
                            style={{ color: "white", backgroundColor: "#7f54df" }}
                        >
                            Sign in as candidate
                        </a>
                        <button
                            className="btn w-100"
                            id="burger_menu_google_login_btn_recruiter"
                            style={{
                                padding: 0,
                                border: "1px solid #d0d5dd",
                                textAlign: "center",
                                margin: "0 auto",
                                background: "#ffffff",
                                position: "absolute",
                                left: 0,
                                opacity: 0,
                            }}
                        >
                            Sign up with Google
                        </button>
                        {/*<Link
                            to={RECRUITER_SIGN_IN}
                            className="btn w-100 mt-3"
                            style={{ color: "#7f54df", backgroundColor: "#F9F5FF" }}
                        >
                            Sign in as recruiter
                        </Link>*/}
                    </div>
                </div>
            </div>
        )}

        <SweetAlert2 {...swalProps}
                     didClose={() => {
                         setSwalProps({})
                     }}
        />
    </>
  );
};
// d-none d-md-block
export default RecruiterHeader;
