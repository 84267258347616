import {getInbox} from "../../services/ChatService";
import {useEffect, useState} from "react";
import {useMessenger} from "../../context/useMessenger";

const MyChatList = () => {
    const [myChatList,setMyChatList] = useState([]);
    const selectedChat = useMessenger((state:any)=>state.selectedChat);
    const selectedChatUser = useMessenger((state:any)=>state.selectedChatUser);
    const setSelectedChatUser = useMessenger((state:any)=>state.setSelectedChatUser);
    const setSelectedChat = useMessenger((state:any)=>state.setSelectedChat);
    const chatOwner = useMessenger((state:any)=>state.chatOwner);
    const setChatOwner = useMessenger((state:any)=>state.setChatOwner);
    const getMyChatList = async () => {
        let response = await getInbox();
        console.log(response);
        if(response && response.success && response.data) {
            setMyChatList(response.data);
        }
    }
    useEffect(()=>{
        getMyChatList();
    },[])

    const handleSelectInbox = (chatId:string,opponentUserId:any) => {
        setSelectedChat(chatId);
        setSelectedChatUser(opponentUserId);
        let tempChatIdArray = chatId.split("_");
        if(tempChatIdArray.length>0){
            tempChatIdArray.forEach((item:any)=>{
                if(item!=opponentUserId){
                    setChatOwner(item);
                }
            })
        }

    }
    return (
        <>
            <div style={{marginTop:20,paddingBottom:20}}>
                {myChatList && myChatList.map((item:any,index:any)=>{
                    return (<li onClick={()=>handleSelectInbox(item.chat_id,item.opponent?.user_id)} className="cursor-pointer" style={{left:0,border:"1px solid grey",listStyle:'none',marginBottom:5}} key={index}>
                        <h5>{item.chat_id} <span style={{float:"right",fontSize:12,padding:3}}>5m ago</span></h5>
                        <p>{item.last_message && item.last_message.content ? item.last_message.content:''}</p>
                    </li>)
                })}
            </div>
        </>
    );
}

export default MyChatList;